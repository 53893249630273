/** @format */

import LiveEdithTextHighLighter from '../../../liveEditHighlighterstyles/liveEdithTextHighLighter.component';
import LiveEditImageHighLighter from '../../../liveEditHighlighterstyles/liveEditImageHighLighter.component';
import {
  FooterContainer,
  FooterLinks,
  FooterSocialMediaLinksContainer,
  NavigationContainer,
} from './footer.style';

export default function FooterT3({ data }) {
  return (
    <FooterContainer id="footer">
      <div className="upper_section">
        <div className="leftSection">
          <div>
            <LiveEditImageHighLighter section={'footer'} type="img">
              <img
                src={'/public/' + data?.footer?.image}
                alt="logo"
                style={{ width: '50px', height: '50px' }}
              />
            </LiveEditImageHighLighter>
          </div>


          <p className="description" style={{ fontSize: '16px', marginBottom: '4px' }}>
            <LiveEdithTextHighLighter section={'footer'} type={'description'}>
              {data?.footer?.text2}
            </LiveEdithTextHighLighter>
          </p>

          <p className="description" style={{ fontSize: '9px', marginTop: '2px' }}>
            <LiveEdithTextHighLighter section={'footer'} type={'description'}>
              {data?.footer?.text3}
            </LiveEdithTextHighLighter>
          </p>


          <h6 style={{ color: 'white', marginBottom: '4px' }}>Phone</h6>
          <p className="description" style={{ fontSize: '9px', marginTop: '2px' }}>
            <LiveEdithTextHighLighter section={'footer'} type={'description'}>
              {data?.footer?.text4}
            </LiveEdithTextHighLighter>
          </p>

          <h6 style={{ color: 'white', marginBottom: '4px' }}>Email</h6>
          <p className="description" style={{ fontSize: '9px', marginTop: '2px' }}>
            <LiveEdithTextHighLighter section={'footer'} type={'description'}>
              {data?.footer?.text5}
            </LiveEdithTextHighLighter>
          </p>

          <h6 style={{ color: 'white', marginBottom: '4px' }}>Address</h6>
          <p className="description" style={{ fontSize: '9px', marginTop: '2px' }}>
            <LiveEdithTextHighLighter section={'footer'} type={'description'}>
              {data?.footer?.text6}
            </LiveEdithTextHighLighter>
          </p>

          <FooterSocialMediaLinksContainer>
            {data?.footer?.socialmedialinks.map((data) => (
              <a
                href={data.link}
                className="image_wrapper">
                <img
                  src={`/public/${data.image}`}
                  alt="socialMediaLinks"
                />
              </a>
            ))}
          </FooterSocialMediaLinksContainer>
          <p className="description" style={{ fontSize: '10px' }}>
            <LiveEdithTextHighLighter
              section={'footer'}
              type={'description'}>
              {data?.footer?.description}
            </LiveEdithTextHighLighter>
          </p>
        </div>
        <div className="rightSection">
          {data?.footer?.columns.map((data) => (
            <NavigationContainer key={data.text}>
              <p className="footer_link_header">{data.text} </p>
              {data.items.map((item) => (
                <FooterLinks
                  key={item.text}
                  to={item.link}
                  target="_blank">
                  {item.text}
                </FooterLinks>
              ))}
            </NavigationContainer>
          ))}
        </div>
      </div>
      <div className="down_section">
        <p className="footer_mark">
          Powered by{' '}
          <a
            target="_blank"
            href="https://agentshades.com"
            rel="noreferrer">
            AgentShades.com
          </a>
        </p>
        <p className="footer_mark">Copyright ©2022 Agentshades.com</p>
      </div>
      {/* <div className="agentshades_trademark">
        <p>
          Powered by{' '}
          <a
            target="_blank"
            href="https://agentshades.com"
            rel="noreferrer">
            AgentShades.com
          </a>
        </p>
      </div> */}
    </FooterContainer>
  );
}
