/** @format */

import { useNavigate } from 'react-router-dom';
import ImageNotFound from '../../../../../images/image-not-found-icon.svg';
import { BlogContainer } from './Blog_card.style';
type BlogCardProps = {
  data?: Blog;
};
type Blog = {
  coverImage: string;
  createdAt: string;
  description: string;
  keywords: string;
  metaDescription: string;
  metaTitle: string;
  slug: string;
  title: string;
  updatedAt: string;
  userId: string;
  _id: string;
};
export default function BlogCard({ data }: BlogCardProps) {
  const navigate = useNavigate();
  const Redirect = (id) => {
    if (id) {
      navigate(`../blog_list/blogview/?id=${id}`);
    }
  };
  return (
    <BlogContainer onClick={() => Redirect(data?._id)}>
      <div className="blog_image_container">
        <img
          src={
            data?.coverImage?.length > 0
              ? `/public/${data?.coverImage}`
              : ImageNotFound
          }
          alt="presentation"
        />
      </div>
      <p className="heading">{data?.title}</p>
      <p
        className="description"
        dangerouslySetInnerHTML={{ __html: data?.description }}></p>
    </BlogContainer>
  );
}
