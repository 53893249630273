/** @format */

import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import LiveEdithTextHighLighter from '../../../../liveEditHighlighterstyles/liveEdithTextHighLighter.component';
import DropDownBox from '../../../utilComponent/CitySearch/components/Dropdown/dropdown.component';
import useSearchDropDown from '../../../utilComponent/CitySearch/useSearchDropDown.customhook';
import { Container, SearchBox } from './FindProperty.style';

type mode = 'Sale' | 'Lease';
export default function FindProperty({ Data }) {
  const { data, search, onChange, filterCity, filterCommunity, loader } =
    useSearchDropDown();
  const [showDropdown, setShowDropdown] = useState<boolean>(true);
  const [sale_or_lease, setSaleOrLease] = useState<mode>('Sale');
  const navigate = useNavigate();
  const handleNavigation = (e: any, item: string) => {
    navigate(`../idxlisting/?city=${item}&sale_or_lease=${sale_or_lease}`);
  };
  const handleInputFocus = () => {
    setShowDropdown(true);
  };
  const handleInputBlur = () => {
    setShowDropdown(false);
  };
  const handleOnChange = (e) => {
    onChange(e.target.value);
  };
  return (
    <Container
      $bg={`/public/${Data.background}`}
      id="sec2">
      <svg
        width="100"
        height="85"
        viewBox="0 0 100 85"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className="dot_grid">
        <rect
          x="100"
          width="8"
          height="8"
          rx="4"
          transform="rotate(90 100 0)"
          fill="#EEF0F4"
        />
        <rect
          x="100"
          y="23"
          width="8"
          height="8"
          rx="4"
          transform="rotate(90 100 23)"
          fill="#EEF0F4"
        />
        <rect
          x="100"
          y="46"
          width="8"
          height="8"
          rx="4"
          transform="rotate(90 100 46)"
          fill="#EEF0F4"
        />
        <rect
          x="100"
          y="69"
          width="8"
          height="8"
          rx="4"
          transform="rotate(90 100 69)"
          fill="#EEF0F4"
        />
        <rect
          x="77"
          width="8"
          height="8"
          rx="4"
          transform="rotate(90 77 0)"
          fill="#EEF0F4"
        />
        <rect
          x="77"
          y="23"
          width="8"
          height="8"
          rx="4"
          transform="rotate(90 77 23)"
          fill="#EEF0F4"
        />
        <rect
          x="77"
          y="46"
          width="8"
          height="8"
          rx="4"
          transform="rotate(90 77 46)"
          fill="#EEF0F4"
        />
        <rect
          x="77"
          y="69"
          width="8"
          height="8"
          rx="4"
          transform="rotate(90 77 69)"
          fill="#EEF0F4"
        />
        <rect
          x="54"
          width="8"
          height="8"
          rx="4"
          transform="rotate(90 54 0)"
          fill="#EEF0F4"
        />
        <rect
          x="54"
          y="23"
          width="8"
          height="8"
          rx="4"
          transform="rotate(90 54 23)"
          fill="#EEF0F4"
        />
        <rect
          x="54"
          y="46"
          width="8"
          height="8"
          rx="4"
          transform="rotate(90 54 46)"
          fill="#EEF0F4"
        />
        <rect
          x="54"
          y="69"
          width="8"
          height="8"
          rx="4"
          transform="rotate(90 54 69)"
          fill="#EEF0F4"
        />
        <rect
          x="31"
          width="8"
          height="8"
          rx="4"
          transform="rotate(90 31 0)"
          fill="#EEF0F4"
        />
        <rect
          x="31"
          y="23"
          width="8"
          height="8"
          rx="4"
          transform="rotate(90 31 23)"
          fill="#EEF0F4"
        />
        <rect
          x="31"
          y="46"
          width="8"
          height="8"
          rx="4"
          transform="rotate(90 31 46)"
          fill="#EEF0F4"
        />
        <rect
          x="31"
          y="69"
          width="8"
          height="8"
          rx="4"
          transform="rotate(90 31 69)"
          fill="#EEF0F4"
        />
        <rect
          x="8"
          width="8"
          height="8"
          rx="4"
          transform="rotate(90 8 0)"
          fill="#EEF0F4"
        />
        <rect
          x="8"
          y="23"
          width="8"
          height="8"
          rx="4"
          transform="rotate(90 8 23)"
          fill="#EEF0F4"
        />
        <rect
          x="8"
          y="46"
          width="8"
          height="8"
          rx="4"
          transform="rotate(90 8 46)"
          fill="#EEF0F4"
        />
        <rect
          x="8"
          y="69"
          width="8"
          height="8"
          rx="4"
          transform="rotate(90 8 69)"
          fill="#EEF0F4"
        />
      </svg>
      <svg
        width="210"
        height="210"
        viewBox="0 0 210 210"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className="circle_svg">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M105 0C47.0101 0 0 47.0101 0 105C0 162.99 47.0101 210 105 210C162.99 210 210 162.99 210 105C210 47.0101 162.99 0 105 0ZM105 30.0001C63.5787 30.0001 30 63.5787 30 105C30 146.421 63.5787 180 105 180C146.421 180 180 146.421 180 105C180 63.5787 146.421 30.0001 105 30.0001Z"
          fill="#2C3647"
        />
      </svg>
      <div className="header_container">
        <p className="header">
          <LiveEdithTextHighLighter
            section="sec2"
            type="text">
            {Data.text}
          </LiveEdithTextHighLighter>
        </p>
        <p className="sub_header">
          <LiveEdithTextHighLighter
            section="sec2"
            type="description">
            {Data.description}
          </LiveEdithTextHighLighter>
        </p>
      </div>
      <div
        className="city_search_container"
        onMouseEnter={() => handleInputFocus()}
        onMouseLeave={() => handleInputBlur()}>
        {/* {!window.location.pathname.includes('designer') ?
          <Citysearch types="template3" /> : <p className='sub_header'>input box will be not accessible on editor</p> } */}
        <SearchBox>
          <svg
            width="25"
            height="26"
            viewBox="0 0 25 26"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="location">
            <path
              d="M12.4974 2.58398C8.1224 2.58398 4.16406 5.93815 4.16406 11.1257C4.16406 14.4382 6.71615 18.334 11.8099 22.8236C12.2057 23.1673 12.7995 23.1673 13.1953 22.8236C18.2786 18.334 20.8307 14.4382 20.8307 11.1257C20.8307 5.93815 16.8724 2.58398 12.4974 2.58398ZM12.4974 13.0007C11.3516 13.0007 10.4141 12.0632 10.4141 10.9173C10.4141 9.77148 11.3516 8.83398 12.4974 8.83398C13.6432 8.83398 14.5807 9.77148 14.5807 10.9173C14.5807 12.0632 13.6432 13.0007 12.4974 13.0007Z"
              fill="#6E6E73"
            />
          </svg>
          <input
            type="text"
            className="search_box"
            value={search}
            onChange={(e) => handleOnChange(e)}
            placeholder="Enter city,  or community"
          />
          <select
            className="select_option"
            value={sale_or_lease}
            onChange={(e) => setSaleOrLease(e.target.value as mode)}>
            <option value="Sale">Buy</option>
            <option value="Lease">Rent</option>
          </select>
          <button className="search_button">
            <svg
              width="25"
              height="26"
              viewBox="0 0 25 26"
              fill="none"
              xmlns="http://www.w3.org/2000/svg">
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M17.9201 16.9425C19.2191 15.2679 19.8312 13.1613 19.6321 11.0513C19.433 8.94133 18.4375 6.98647 16.8482 5.58442C15.2589 4.18236 13.1952 3.43844 11.0768 3.50399C8.95849 3.56954 6.94469 4.43964 5.44512 5.93728C3.94424 7.43595 3.07129 9.45052 3.00418 11.5705C2.93706 13.6904 3.68082 15.7562 5.0839 17.3468C6.48697 18.9374 8.44376 19.9331 10.5555 20.1311C12.6672 20.3291 14.775 19.7144 16.4493 18.4123L16.4941 18.4592L20.9128 22.8789C21.0096 22.9757 21.1245 23.0525 21.251 23.1049C21.3774 23.1573 21.5129 23.1842 21.6498 23.1842C21.7867 23.1842 21.9222 23.1573 22.0487 23.1049C22.1751 23.0525 22.29 22.9757 22.3868 22.8789C22.4836 22.7822 22.5603 22.6673 22.6127 22.5408C22.6651 22.4144 22.6921 22.2788 22.6921 22.142C22.6921 22.0051 22.6651 21.8696 22.6127 21.7431C22.5603 21.6167 22.4836 21.5018 22.3868 21.405L17.967 16.9862C17.9518 16.9712 17.9361 16.9566 17.9201 16.9425ZM15.7576 7.41123C16.3457 7.98986 16.8135 8.6792 17.1339 9.4395C17.4542 10.1998 17.6209 11.016 17.6243 11.841C17.6276 12.6661 17.4676 13.4836 17.1534 14.2465C16.8393 15.0094 16.3771 15.7025 15.7938 16.2859C15.2104 16.8693 14.5172 17.3314 13.7543 17.6456C12.9915 17.9598 12.1739 18.1198 11.3489 18.1164C10.5238 18.1131 9.70764 17.9464 8.94734 17.626C8.18704 17.3056 7.4977 16.8379 6.91908 16.2498C5.76274 15.0745 5.11767 13.4898 5.12439 11.841C5.1311 10.1923 5.78905 8.61295 6.95492 7.44708C8.12079 6.28121 9.70011 5.62326 11.3489 5.61655C12.9977 5.60983 14.5823 6.2549 15.7576 7.41123Z"
                fill="#FDFDFE"
              />
            </svg>
            Search
          </button>
        </SearchBox>
        {(filterCity?.length > 0 ||
          filterCommunity?.length > 0 ||
          data?.listing?.length > 0) &&
          showDropdown && (
            <DropDownBox
              data={data}
              filterCity={filterCity}
              filterCommunity={filterCommunity}
              handleNavigation={handleNavigation}
              onBlur={handleInputBlur}
              onFocus={handleInputFocus}
              loader={loader}
            />
          )}
      </div>
    </Container>
  );
}
