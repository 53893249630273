/** @format */

import styled from 'styled-components';

import { Container } from '../../Style/CommonStyle.t3';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';

export const NavbarContainer = styled(Container)`
  // background: #FDFDFEE5;
  background: ${({ theme: { colors } }) => colors.primary};
  display: flex;
  justify-content: end;
  align-items: center;
  position:sticky;
  height: 3.5rem;
   z-index: 1001;
  .links_container {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 2.5rem;
    margin-right: 3rem;
    @media screen and (max-width: 475px) {
      display: none;
    }
  }
`;

export const NavLink = styled(Link)`
  // color:${({ theme: { colors } }) => colors.InactiveGrey} ;
  color:white;
  font-family: Garamond;
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.1719rem;
  text-decoration: none;
  padding:12px;
  cursor: pointer;
  &:hover {
    color:${({ theme: { colors } }) => colors.InactiveGrey};
  }
`;

export const Sidebar = styled(motion.div)`
 position: fixed;  
z-index: 1000;
 top: 0;
  right: 0;
  bottom: 0;
  width: 200px;
  height:500px;
  display: none;
  .background {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    width: 300px;
    background: ${({ theme: { colors } }) => colors.primary} ;
    z-index: 1000;
    .custom_button {
      outline: none;
      border: none;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      cursor: pointer;
      position: absolute;
      top: 18px;
      right: 20px;
      width: 50px;
      height: 50px;
      border-radius: 50%;
      background: transparent;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    ul,
    li {
      margin: 0;
      padding: 0;
    }

    ul {
      padding: 25px;
      position: absolute;
      top: 100px;
      right: 20px;
      width: 230px;
      li {
        list-style: none;
        margin-bottom: 20px;
        display: flex;
        align-items: center;
        cursor: pointer;
        justify-content: flex-end;
      }
    }
  }
  @media screen and (max-width: 475px) {
    display: block;
  }
`;
