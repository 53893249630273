/** @format */

import styled from "styled-components";

export const Container = styled.div`
  width: min(105rem, 95%);
  margin: 4rem auto;
  min-height: 45rem;
  max-height: 36.0625rem;
  background-color: ${({ theme: { colors } }) => colors.text};
  padding: clamp(0, 4vw, 4rem);
  border-radius: 1.25rem;
  position: relative;
  .slider_container {
  }
  .Dots {
    position: absolute;
    top: 0;
    left: 40%;
  }
  .Circle {
    position: absolute;
    top: 28%;
    left: 60%;
  }
  .Scroll {
    position: absolute;
    top: 25%;
    top: 10rem;
    left: 3rem;
    right: 0;
    padding: 2rem 0;
    .custom-next-arrow,
    .custom-prev-arrow {
      background-color: ${({ theme: { colors } }) => colors.primary};
      padding: 1rem;
      border-radius: 100%;
      cursor: pointer;
    }
    .custom-next-arrow {
      position: absolute;
      top: 50%;
      right: 0;
      z-index: 100;
    }
    .custom-prev-arrow {
      position: absolute;
      top: 50%;
      left: -2rem;
      z-index: 100;
    }
  }

  @media screen and (max-width: 702px) {
    width: 100%;
    .Scroll {
      position: static;
      margin-bottom: 3rem;
    }
  }
  @media screen and (max-width: 554px) {
    .Dots {
      left: 75%;
    }
    .Scroll {
      .custom-next-arrow {
        right: -20px;
        right: 0;
        padding: 7px;
      }
      .custom-prev-arrow {
        left: -20px;
        left: 0;
        padding: 7px;
      }
    }
  }
`;

export const Content = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  .left {
    h1 {
      color: var(--text-color);
      font-family: Syncopate;
      font-size: 3rem;
      font-size: clamp(1.5rem, 1rem + 2vw, 3rem);
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      text-transform: uppercase;
    }
    p {
      color: var(--text-color);
      font-family: 'Cormorant Garamond';
      font-size: 1.125rem;
      font-size: clamp(0.75rem, 1rem + 1vw, 1.125rem);
      font-style: normal;
      font-weight: 300;
      line-height: normal;
      text-transform: capitalize;
    }
  }
  .button {
    background-color: ${({ theme: { colors } }) => colors.primary};
    padding: 1rem 2rem;
    border-radius: 0.625rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 5px;
    cursor: pointer;
    p {
      color: ${({ theme: { colors } }) => colors.white};
      font-family: Syncopate;
      font-size: 1.125rem;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      text-transform: capitalize;
      margin: 0;
    }
    &:hover {
      border: 1px solid ${({ theme: { colors } }) => colors.primary};
      p {
        color: ${({ theme: { colors } }) => colors.primary};
      }
      background-color: white;
      svg {
        path {
          fill: ${({ theme: { colors } }) => colors.primary};
        }
      }
    }
  }
  @media screen and (max-width: 702px) {
    .button {
      position: absolute;
      bottom: 1rem;
      left: 50%;
      transform: translateX(-50%);
    }
  }
`;
