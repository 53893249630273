/** @format */

import React from 'react';
import { ConnectConstruction } from './ConnectInstruction.style';

export default function ConnectInstruction() {
  return (
    <ConnectConstruction
      initial={{ opacity: 0, y: 100 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: -1000 }}
      transition={{ easeIn: 'easeOut', duration: 0.5, delay: 1}}
      >
      <div className="Instructions">
        <p className="instruction_heading">Connect your Domain</p>
        <p className="heading">
          Our dedicated team will get in touch with you soon with future
          instructions{' '}
        </p>
      </div>
    </ConnectConstruction>
  );
}
