/** @format */

import styled from 'styled-components';



export const HeaderContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-block: 0.9375rem;
  padding-inline: clamp(1rem, 5vw, 5rem);
  position: sticky;
  top: 0;
  backdrop-filter: blur(10px);
  .logo_container {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    img {
      height: 2.5rem;
      object-fit: contain;
    }
    .heading {
      font-family: Montserrat;
      font-size: 1.875rem;
      font-size: clamp(0.875rem , 1.875vw , 1.875rem);
      font-weight: 600;
      line-height: 2.2856rem;
      text-align: center;
      color:#fafafc;
      margin: 0;
    }
  }
  .navigation_buttons {
    display: flex;
    align-items: center;
    gap: 1rem;
  }
`;
type LinkButtonProps = {
  $cursor?: boolean
}

export const LinkButton = styled.button<LinkButtonProps>`
padding: 0.9375rem 1.25rem 0.9375rem 1.25rem;
border-radius: 0.5rem ;
font-family: Roboto;
font-size: 1.125rem;
font-weight: 500;
line-height: 1.3181rem;
color: #AAAAAA;
background-color: #1f1f20;
border: none;
outline: none;
cursor:${props => props.$cursor ? 'not-allowed' : 'pointer'} ;
&:hover{
    background-color: #343434;
}
`
export const NextButton = styled(LinkButton)`
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    background: linear-gradient(94.98deg, #D9D9D9 0%, #FFFFFF 100%);
    color: #1E1E1E;
    .svg_wrapper{
       svg{
        path{
            fill:#1E1E1E;
        }
       }
    }
   &:hover{
    outline: 1px solid  #FAFAFC;
    color: #AAAAAA;
    background: #1f1f20;
    .svg_wrapper{
       svg{
        path{
            fill:#AAAAAA;
        }
       }
    }
   }
;
`

export const NavigationButtons = styled.div`
display: flex;
gap: 1rem;
@media screen and (max-width:1059px) {
   display: none;
}
`;
