import { Link } from 'react-router-dom';
import "./Header.css";
interface NavbarProps {
  text: string;
  logo: string; // Assuming Logo is an image URL
  color: string;
  nav: { text: string; link: string; color: string }[];
}
export default function Header({ text, logo, nav, color }: NavbarProps) {
  return (
    <div className='template1'>
      <nav className="navbar navbar-expand-lg navbar-light bg-light">
        <div className="container">
          {/* <a className="navbar-brand" href="home" style={{color: color}}>
          <img src={"/public/"+logo} alt={""} width="30" height="30" />
        </a> */}
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarNav"
            aria-controls="navbarNav"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarNav">
            <ul className="navbar-nav ml-auto">
              <li className='nav-item'>
                <Link className='nav-link' style={{ fontFamily: "Garamond", margin: "0rem 2rem", textTransform: "uppercase" }} to={'../home'} >Home</Link>
              </li>
              <li className="nav-item" >
                <Link className="nav-link" to={'../about'} style={{ color: 'white', fontFamily: "Garamond", margin: "0rem 2rem", textTransform: "uppercase" }}>
                  About us
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to={'../idxlisting'} style={{ color: 'white', fontFamily: "Garamond", margin: "0rem 2rem", textTransform: "uppercase" }}>
                  Browse Homes
                </Link>
              </li>
              {/* <li className="nav-item">
                <Link className="nav-link" to={'../tools'} style={{ color: 'white', fontFamily: "Garamond", margin: "0rem 2rem", textTransform: "uppercase" }}>
                  Tools
                </Link>
              </li> */}
                <li className="nav-item">
                <Link className="nav-link" to={'../map'} style={{ color: 'white', fontFamily: "Garamond", margin: "0rem 2rem", textTransform: "uppercase" }}>
                  Map
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to={'../contact'} style={{ color: 'white', fontFamily: "Garamond", margin: "0rem 2rem", textTransform: "uppercase" }}>
                  Contact us
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </nav >
    </div >
  )
}
