import { Route, Routes } from 'react-router-dom';


import Blogs from '../pages/DashBoard/Components/Blogs/Blogs';
import BuyersForm from '../pages/DashBoard/Components/BuyersLeads/BuyersLeads';
import CrmIntigration from '../pages/DashBoard/Components/CrmIntigration/CrmIntigration';
import CrmListing from '../pages/DashBoard/Components/CrmIntigration/CrmListting/MlsListing';
import Crmsetting from '../pages/DashBoard/Components/CrmIntigration/crmsetting/crmsetting';
import DomainLinking from '../pages/DashBoard/Components/DomainLinking/DomainLinking';
import PricingBiling from '../pages/DashBoard/Components/Pricing&Biling/PricingBiling';
import SellerForm from '../pages/DashBoard/Components/SellersLeades/SellerLeaders';
import Seomanager from '../pages/DashBoard/Components/Seomanager/Seomanager';
import DashBoard from '../pages/DashBoard/DashBoard';

//context
import DashboardBlogsContextProvider from '../context/DashboardBlogs.context';
import DashboardMlsContextProvider from '../context/DashboardMlsListing.context';
export default function DashBoardRoute() {
  return (
   <Routes>
    <Route path='/' element={<DashBoard/>}>
          <Route
              path="blogs"
              element={
                <DashboardBlogsContextProvider>
                      <Blogs />
                </DashboardBlogsContextProvider>
             }
          />
          <Route
              path="pricing-blilling"
              element={<PricingBiling />}
          />
          <Route
              path="buyers-lead"
              element={<BuyersForm />}
          />
          <Route
              path="seller-lead"
              element={<SellerForm />}
          />
          <Route
              path="crmintigration"
              element={<CrmIntigration />}>
              <Route
                  path=":crm/settings"
                  element={<Crmsetting />}
              />
              <Route
                  path=":crm/listings"
                  element={<CrmListing />}
              />
          </Route>
          <Route path='mls_listing' element={
                  <DashboardMlsContextProvider>
                      <CrmListing />
                  </DashboardMlsContextProvider>
            } />
          <Route
              path="seomanager"
              element={<Seomanager />}
          />
          <Route path='domain_linking' element={<DomainLinking/>} />
          </Route>
   </Routes>
  )
}
