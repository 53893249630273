import axios from 'axios';
import Cookies from 'js-cookie';
import { useEffect, useState } from 'react';
import "../../Template1/Template1.css";
import "./BlogView.css";

export default function BlogView() {

    const [data, setData] = useState(null);
    const [blogData, setBlogData] = useState(null);

    useEffect(() => {
        const blogId = new URLSearchParams(window.location.search).get('id')
        const token = Cookies.get('token');

        axios.get(`/admin/blog/${blogId}`, {
            headers: {
                'x-access-token': token
            }
        
        })
            .then((res) => {
                setBlogData(res.data.data)
            })
            .catch((err) => {
                console.log(err)
            })

            axios.get("/api/get-template?n=1&p=1", {
                headers: {
                    'x-access-token': token
                }
            }).then((res) => {
                res.data && setData(res.data)
            })
    },[])

    const customStyles = data ? `
        :root {
            --primary: ${data.styles[0].css.primary};
            --secondary: ${data.styles[0].css.secondary};
        }
        .footer {
            background: ${data.styles[0].css.primary};
            color: ${data.styles[0].css.secondary};
            
        }
        body {
            min-height: 100vh;
        }
        #root{
            min-height: 100vh;
        }

        .footer{
            display: flex;
            width: 100%;
            padding-top: 3rem;
            padding-bottom: 3rem;
        }

        .card-img-top{
            margin: auto;
            margin-bottom: 2rem;
        }
    `:"";
  return (
    data ? (<div style={{minHeight: "100vh"}}>
        <style>{customStyles}</style>
       {/* {data && <Header {...data.header}></Header>} */}
        <div className="container" style={{marginTop:"3rem", display: "grid"}}>
              <h1 style={{
                  marginBottom: "2rem", fontFamily: 'Syncopate' }} className='text-center'>{blogData?.title}</h1>
              <img src={"/public/" + blogData?.coverImage} className="card-img-top blog_img" alt="..." />
            <div className="row">
                <div className="col-md-12"> 
                      <div style={{ fontFamily:'Garamond',color:'black'}} dangerouslySetInnerHTML={{__html: blogData?.description}}></div>
                </div>
            </div>
        </div>
        {/* {data && <Footer {
                ...{
                    footerData: data.footer
                }
            }></Footer>
        } */}
    </div>) : <div></div>
  )
}
