import React, { useEffect, useState } from 'react'

import {Container} from './requestForm.style'
import useBuyerLead from '../../../../../CustomHooks/useBuyerLead.customHook';
import Spinner from '../../../../../../utils/spinner/spinner.component';
export default function RequestForm({Data}) {
    const [formData, setFormData] = useState({
        domain_name: '',
        name: '',
        email: '',
        phone: '',
        message: `I would like an appointment scheduled on the above mentioned date and time to know more about this property at ${Data?.street_address},${Data?.property_name}`,
    });
    const { loader, handleBuyerLead } = useBuyerLead(formData)
    useEffect(() => {
        const agentUrl = window.location.href.split('/')[2];
        setFormData({ ...formData, domain_name: agentUrl });
    }, []);
  return (
      <Container onClick={(event) => event.stopPropagation()}>
        <div className='heading_container'>
              <h1 className='heading'>Request a tour as early as</h1>
              <h2 className='sub_heading'>Tomorrow at 10:00AM</h2>
        </div>
          <form className="form_container" onSubmit={handleBuyerLead}>
            <input type="text" className="input_box" placeholder='Name' required value={formData.name} />
            <input type="email" className="input_box" placeholder='Email' required value={formData.email} />
            <input type="phone" className="input_box" placeholder='Phone Number' required value={formData.phone}/>
            <textarea className="input_box" placeholder='Enter Message' rows={5} value={formData.message}></textarea>
            <button type='submit' className='submit_button'>
                { loader ? <Spinner/> :
                <>
                  <svg width="31" height="30" viewBox="0 0 31 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <g clip-path="url(#clip0_4995_16201)">
                          <g filter="url(#filter0_d_4995_16201)">
                              <path d="M7.8311 25.7142C7.22872 25.7142 6.71286 25.5042 6.28353 25.0842C5.8542 24.6642 5.6399 24.1599 5.64063 23.5714V8.57136C5.64063 7.98208 5.85529 7.47743 6.28463 7.05743C6.71396 6.63743 7.22945 6.42779 7.8311 6.4285H8.92634V5.35708C8.92634 5.05351 9.03148 4.79886 9.24177 4.59315C9.45205 4.38743 9.71199 4.28493 10.0216 4.28565C10.3319 4.28565 10.5922 4.38851 10.8025 4.59422C11.0128 4.79993 11.1175 5.05422 11.1168 5.35708V6.4285H19.8787V5.35708C19.8787 5.05351 19.9839 4.79886 20.1941 4.59315C20.4044 4.38743 20.6644 4.28493 20.974 4.28565C21.2843 4.28565 21.5446 4.38851 21.7549 4.59422C21.9651 4.79993 22.0699 5.05422 22.0692 5.35708V6.4285H23.1644C23.7668 6.4285 24.2827 6.6385 24.712 7.0585C25.1413 7.4785 25.3556 7.98279 25.3549 8.57136V23.5714C25.3549 24.1606 25.1402 24.6653 24.7109 25.0853C24.2816 25.5053 23.7661 25.7149 23.1644 25.7142H7.8311ZM7.8311 23.5714H23.1644V12.8571H7.8311V23.5714ZM15.0323 21.5356L14.1287 19.5535L12.1025 18.6696C11.9017 18.5803 11.8013 18.4285 11.8013 18.2142C11.8013 17.9999 11.9017 17.8481 12.1025 17.7589L14.1287 16.8749L15.0323 14.8928C15.1236 14.6964 15.2787 14.5981 15.4978 14.5981C15.7168 14.5981 15.872 14.6964 15.9632 14.8928L16.8668 16.8749L18.893 17.7589C19.0938 17.8481 19.1942 17.9999 19.1942 18.2142C19.1942 18.4285 19.0938 18.5803 18.893 18.6696L16.8668 19.5535L15.9632 21.5356C15.872 21.7321 15.7168 21.8303 15.4978 21.8303C15.2787 21.8303 15.1236 21.7321 15.0323 21.5356Z" fill="white" />
                          </g>
                      </g>
                      <defs>
                          <filter id="filter0_d_4995_16201" x="3.64062" y="2.28564" width="27.7109" height="29.4285" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                              <feFlood flood-opacity="0" result="BackgroundImageFix" />
                              <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                              <feOffset dx="2" dy="2" />
                              <feGaussianBlur stdDeviation="2" />
                              <feComposite in2="hardAlpha" operator="out" />
                              <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0.380392 0 0 0 0 0.87451 0 0 0 0.1 0" />
                              <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_4995_16201" />
                              <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_4995_16201" result="shape" />
                          </filter>
                          <clipPath id="clip0_4995_16201">
                              <rect width="30" height="30" fill="white" transform="translate(0.5)" />
                          </clipPath>
                      </defs>
                  </svg>
                  Schedule Tour
                  </>
                  }
            </button>
            <p className='agreement'>By clicking on “Schedule Tour” you agree to be contacted by RedBuyers</p>
        </form>
      </Container>
  )
}
