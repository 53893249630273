/** @format */

import { useEffect, useState } from 'react';
import { Outlet, useParams } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import { useMessageContext } from '../../../context/Message.context';
import GetTemplateData from '../../../utils/apis/GetTemplateData.api';
import Footer from '../components/footer/footer.t2';
import Navbar from '../components/navbar/navbar.t2';
export default function IDXWrappertemplatetwo() {
  const [data, setData] = useState(null);
  const { setTheme } = useMessageContext();
  const { preview } = useParams();
  useEffect(() => {
    const FetchTemplateData = async () => {
      const data = await GetTemplateData(preview, 2, 1);
      if (data) {
        setData(data);
        setTheme({
          primary_color: data.styles[0].css.primary,
          secondary_color: data.styles[0].css.secondary,
          warning_message: {
            fontFamily: 'Tenor Sans',
            fontSize: '20px',
            fontWeight: '400',
            lineHeight: '23.4px',
          },
          warning_text: {
            fontFamily: 'Libre Baskerville',
            fontSize: '18px',
            fontWeight: '400',
            lineHeight: '22.32px',
            textAlign: 'center',
          },
          button: {
            fontFamily: 'Tenor Sans',
            fontSize: '18px',
            fontWeight: '400',
            lineHeight: '23.4px',
          },
        });
      }
    };
    FetchTemplateData();
  }, []);

  const theme = {
    colors: {
      primary: data?.styles[0].css.primary,
      text: data?.styles[0].css.secondary,
      text2: '#000000',
      background: '#f8f9fa',
    },
    fonts: {
      primary_font: 'Tenor Sans',
      secondary_font: 'Libre Baskerville',
    },
    not_found_page: {
      warning_number: {
        first_letter: '0',
        second_letter: -25,
        third_letter: -20,
        stroke: 2,
        weight: 400,
      },
      button: {
        border_radius: 0,
      },
    },
  };

  return (
    <ThemeProvider theme={theme}>
      <Navbar data={data?.header} />
      <Outlet />
      <Footer data={data} />
    </ThemeProvider>
  );
}
