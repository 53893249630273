/** @format */

import { useEffect, useState } from 'react';
import moment from 'moment';
//component
import Citysearch from '../CitySearch/citysearch.component';
import FilterProperty from '../FilterBox/filter.component';

import {
  CardsContainer,
  LoadingContainer,
  SearchFilterContainer,
} from '../idxListing/idxListing.style';

import { useLocation } from 'react-router-dom';
import { useFilterContext } from '../../../context/filter.context';
import { SoldListingContainer } from './soldListing.style';

//images
import axios from 'axios';
import ReactPaginate from 'react-paginate';
import NotFound from '../../../images/No result.gif';
import ConvertToCanadianNumber from '../../../utils/New_construction_details/ConvertNumberToDollar.util';
import PropertyCard, {
  PROPERTY_TYPE_CLASSES,
} from '../PropertyCard/PropertyCard.utilComponent';
type propertyProps = {
  id: number;
  photos: any[];
  list_price: number;
  address: string;
  county: string;
  zip: string;
  realtor: string;
  bedrooms: string;
  bathrooms: string;
  sale_or_lease: string;
  sold_price?: number;
  city: string;
  property_details?: {
    County: string;
    Unavail_dt?: string;
    Input_date?: string;
  };
  sold_date?: string;
  garage: number;
};
type SOldListingProps = {
  cardType?: PROPERTY_TYPE_CLASSES;
};
export default function SoldListing({ cardType }: SOldListingProps) {
  const [property, setProperty] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [lastPage, setLastPage] = useState();
  const [loader, setloader] = useState('loading');
  const location = useLocation();
  const getQueryParams = () => {
    return new URLSearchParams(location.search);
  };

  const params = getQueryParams();
  const { state } = useFilterContext();

  const handlePageChange = (pageIndex: { selected: number }) => {
    const UpdatedValue = pageIndex.selected + 1;
    setCurrentPage(UpdatedValue);
    window.scrollTo(0, 0);
  };
  const handleScrollUp = () => {
    window.scrollTo(0, 0);
  };

  const SoldInDays = (listedDay, soldDay): number => {
    if (!listedDay || !soldDay) {
      return null;
    }
    let start = moment(listedDay);
    let end = moment(soldDay);

    return end.diff(start, 'days');
  };

  const formatDollars = (amount: number) => {
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
    }).format(amount);
  };
  const {
    city,
    page,
    bathroom,
    bedroom,
    building_status,
    home_type,
    min_price,
    max_price,
    search,
    sortBy,
    sortDir,
    property_style,
  } = state;
  useEffect(() => {
    const source = axios.CancelToken.source();
    async function fetchData() {
      setloader('loading');
      setProperty([]);
      try {
        const resp = await axios.get(
          `https://valuation.santhoshmathew.com/api/v1/sold/properties?page=1&sortDir=desc&sortBy=sold_date`,
          {
            cancelToken: source.token,
            params: {
              ...(min_price && { min_price }),
              ...(max_price && { max_price }),
              ...(home_type && { property_type: home_type }),
              ...(building_status && { building_status }),
              ...(bedroom && { bedrooms: bedroom }),
              ...(bathroom && { bathrooms: bathroom }),
              ...(sortBy && { sortBy }),
              ...(sortDir && { sortDir }),
              ...(search && { city: search }),
              ...(currentPage && { page: currentPage }),
              ...(property_style && { property_style }),
            },
          }
        );

        setLastPage(resp.data.meta.last_page);
        setProperty(
          resp.data.data.map((item: propertyProps) => ({
            id: item.id,
            img: item.photos,
            price: ConvertToCanadianNumber(item.sold_price),
            listed_price: ConvertToCanadianNumber(
              item.property_details?.['Lp_dol']
            ),
            sold_on: item?.sold_date?.split(' ')?.[0],
            sold_in: SoldInDays(
              item?.property_details?.Input_date,
              item?.property_details?.Unavail_dt
            ),
            title: `${item.address} ${item.city} ${item.property_details.County} ${item.zip}`,
            text: item.realtor,
            bedrooms: item.bedrooms,
            bathrooms: item.bathrooms,
            garage: item.garage,
            sale_or_lease: 'sold',
            redirect: 'sold/soldView',
          }))
        );
        setloader('completed');
        handleScrollUp();
      } catch (error) {
        console.error('Error fetching data: ', error);
      }
    }
    fetchData();
    return () => {
      source.cancel('Api call got canceled by cleanup.');
    };
  }, [
    currentPage,
    city,
    page,
    bathroom,
    bedroom,
    building_status,
    home_type,
    min_price,
    max_price,
    search,
    sortBy,
    sortDir,
    property_style,
  ]);
  return (
    <>
      <SearchFilterContainer>
        <Citysearch
          lease_sale={false}
          types="sold"
        />
        <FilterProperty types="sold" />
      </SearchFilterContainer>
      <SoldListingContainer>
        {loader === 'loading' ? (
          <LoadingContainer>
            <div className="loader"></div>
          </LoadingContainer>
        ) : loader === 'completed' && property.length !== 0 ? (
          <CardsContainer>
            {property.map((card) => (
              // <FeatureCard key={card.id}>
              //     <div className="for_sale">
              //         <p> {card.sale_or_lease ? `For ${card.sale_or_lease}` :
              //             'Sold'
              //         }</p>
              //     </div>
              //     <div className="view_button">
              //         <Link to={`sold/soldView/${card.id}`}>View property</Link>
              //     </div>
              //     <div className="image_wrapper">
              //         {card.img ? (
              //             <img
              //                 className="image_box"
              //                 src={card.img}
              //                 alt="PropertyImage"
              //             />
              //         ) : (
              //             <img
              //                 className="svg_box"
              //                 src={ImageNotFound}
              //                 alt="image_not_found"
              //             />
              //         )}
              //     </div>
              //     <p>{card.title}</p>
              //     <p>{card.text}</p>
              //     <p>{`${card.bedrooms} BD | ${card.bathrooms} BA`}</p>
              //     <p>{card.price}</p>
              // </FeatureCard>
              <PropertyCard
                propertyData={card}
                propertyType={'sold'}
                propertyCardType={cardType}
              />
            ))}
          </CardsContainer>
        ) : (
          <LoadingContainer>
            <img
              src={NotFound}
              alt="Not Found"
            />
          </LoadingContainer>
        )}

        <ReactPaginate
          containerClassName="peginate_class"
          pageClassName="page"
          onPageChange={handlePageChange}
          forcePage={currentPage - 1}
          pageCount={lastPage}
        />
      </SoldListingContainer>
    </>
  );
}
