/** @format */

import styled from 'styled-components';

export const SearchCityContainer = styled.form`
  position: relative;
  /* background-color: white; */
  background-color: transparent;
  width:min(30rem , 95%) ;
  min-height:5.5rem;
  .input_box_city {
    color: white;
    text-transform: capitalize;
  }
  .dropdown-list {
    position: absolute;
    top: 110%;
    left: 0;
    z-index: 1;
    background-color: white;
    border: 1px solid #ccc;
    list-style-type: none;
    margin: 0;
    padding: 0;
    min-width: 100%;
    max-height: 10rem;
    overflow-y: scroll;
    border-radius: 0.45rem;
  }
  .dropdown-list {
    li {
      padding: 8px;
      cursor: pointer;
      color: #474747;
      text-transform: capitalize;
      &:hover {
        background-color:  ${(props) => props?.theme?.colors?.primary ? props?.theme?.colors?.primary : 'var(--primary)'};
        /* color:  ${(props) => props?.theme?.colors?.text ? props?.theme?.colors?.text : 'var(--secondary)'}; */
        color: white;
      }
    }
  }
`;

export const InputBoxContainer = styled.div`
  align-items: center;
  display: flex;
  box-shadow: 0px 0px 2px 0px #00000040;
  /* box-shadow: 1px 1px 1px 1px rgba(0, 0, 0, 0.5); */
  border-radius: 0.3125rem;
  padding: 0.25rem 0.3125rem;
  padding-left: 1rem;
  background-color: white;
  gap: 1rem;
  .input_box {
    border: none;
    outline: none;
    background-color: transparent;
    color:  ${(props) => props?.theme?.colors?.primary ? props?.theme?.colors?.primary : 'var(--primary)'};
    width: 100%;
  }
  .window_button {
    display: flex;
    gap: 0.25rem;
    .search_city {
      background-color: ${(props) => props?.theme?.colors?.primary ? props?.theme?.colors?.primary : 'var(--primary)'};
      /* color:  ${(props) => props?.theme?.colors?.primary ? props?.theme?.colors?.text : 'var(--secondary)'}; */
      box-shadow: 1px 1px 4px 0px ${(props) => props?.theme?.colors?.primary ? props?.theme?.colors?.primary : 'var(--primary)'};
      color: white;
      border: none;
      outline: none;
      border-radius:0.1563rem;
      padding: 1rem 1.25rem;
    }
    .select_option {
      border: none;
      outline: none;
      border-radius: 0.1563rem;
      /* padding: 1rem 1.25rem; */
      color:  ${(props) => props?.theme?.colors?.primary ? props?.theme?.colors?.primary : 'var(--primary)'};
      font-size: 0.875rem;
      font-weight: 600;
      option {
        color:  ${(props) => props?.theme?.colors?.primary ? props?.theme?.colors?.primary : 'var(--primary)'};
        /* &:hover {
          background-color: red;
        } */
      }
    }
    .search_icon {
      display: none;
      background-color:  ${(props) => props?.theme?.colors?.primary ? props?.theme?.colors?.primary : 'var(--primary)'};
      svg {
        path {
          fill:white;
          stroke:white;
        }
      }
    }
  }
  @media screen and (max-width: 692px) {
    width: 100%;
    button {
      display: none;
    }
    .window_button {
      .search_icon {
        display: block;
        /* box-shadow: 1px 1px 4px 0px rgba(0, 97, 223, 0.3); */
        padding: 0.94rem;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
`;
