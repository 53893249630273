/** @format */

import axios from 'axios';
import Cookies from 'js-cookie';
import { useState } from 'react';
import Editors from '../Editor/Editor';
import Preview from '../Preview/Preview';
import './blog.css';

export default function Blog({ Data, fetchData }) {
  const [editor, setEditor] = useState('idle');
  const [preview, setPreview] = useState('idle');

  const handleDelete = async () => {
    const token = Cookies.get('token');
    try {
      await axios.get(`/admin/blog-delete/${Data._id}`, {
        headers: {
          'x-access-token': token,
        },
      });
      fetchData();
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <>
      <div className="blog_container d-flex gap-3">
        <div className="information">
          <h1>{Data.title}</h1>
          <div
            style={{
              color: 'white',
            }}
            dangerouslySetInnerHTML={{
              __html:
                Data.description.length > 350
                  ? Data.description.slice(0, 350) + '  ....'
                  : Data.description,
            }}></div>
        </div>
        <div className="action">
          <div
            className="view"
            onClick={() => setPreview('preview')}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="19"
              viewBox="0 0 18 19"
              fill="none">
              <path
                d="M9 11.75C10.2426 11.75 11.25 10.7426 11.25 9.5C11.25 8.25736 10.2426 7.25 9 7.25C7.75736 7.25 6.75 8.25736 6.75 9.5C6.75 10.7426 7.75736 11.75 9 11.75Z"
                fill="#474747"
              />
              <path
                d="M17.4035 9.30875C16.7419 7.59743 15.5934 6.11747 14.0998 5.0518C12.6063 3.98612 10.8332 3.38145 8.99976 3.3125C7.1663 3.38145 5.39323 3.98612 3.89969 5.0518C2.40614 6.11747 1.2576 7.59743 0.596011 9.30875C0.55133 9.43234 0.55133 9.56766 0.596011 9.69125C1.2576 11.4026 2.40614 12.8825 3.89969 13.9482C5.39323 15.0139 7.1663 15.6186 8.99976 15.6875C10.8332 15.6186 12.6063 15.0139 14.0998 13.9482C15.5934 12.8825 16.7419 11.4026 17.4035 9.69125C17.4482 9.56766 17.4482 9.43234 17.4035 9.30875ZM8.99976 13.1562C8.27662 13.1562 7.56972 12.9418 6.96846 12.5401C6.36719 12.1383 5.89856 11.5673 5.62183 10.8992C5.34509 10.2311 5.27269 9.49594 5.41376 8.7867C5.55484 8.07746 5.90307 7.42598 6.4144 6.91464C6.92574 6.40331 7.57722 6.05508 8.28646 5.914C8.9957 5.77293 9.73085 5.84533 10.3989 6.12207C11.067 6.3988 11.6381 6.86743 12.0398 7.4687C12.4416 8.06996 12.656 8.77686 12.656 9.5C12.6545 10.4692 12.2688 11.3984 11.5835 12.0837C10.8981 12.7691 9.969 13.1548 8.99976 13.1562Z"
                fill="#474747"
              />
            </svg>
          </div>
          <div
            className="edit"
            onClick={() => setEditor('edit')}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="19"
              viewBox="0 0 18 19"
              fill="none">
              <path
                d="M2.25 13.4374V16.2499H5.0625L13.3575 7.95488L10.545 5.14238L2.25 13.4374ZM15.5325 5.77988C15.602 5.71049 15.6572 5.62808 15.6948 5.53735C15.7325 5.44662 15.7518 5.34936 15.7518 5.25113C15.7518 5.1529 15.7325 5.05564 15.6948 4.96491C15.6572 4.87418 15.602 4.79177 15.5325 4.72238L13.7775 2.96738C13.7081 2.89785 13.6257 2.84269 13.535 2.80506C13.4442 2.76742 13.347 2.74805 13.2488 2.74805C13.1505 2.74805 13.0533 2.76742 12.9625 2.80506C12.8718 2.84269 12.7894 2.89785 12.72 2.96738L11.3475 4.33988L14.16 7.15238L15.5325 5.77988Z"
                fill="#474747"
              />
            </svg>
          </div>
          <div
            className="delete"
            onClick={handleDelete}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="19"
              viewBox="0 0 18 19"
              fill="none">
              <path
                d="M4.5 14.75C4.5 15.575 5.175 16.25 6 16.25H12C12.825 16.25 13.5 15.575 13.5 14.75V5.75H4.5V14.75ZM6.8775 9.9425C6.73727 9.80227 6.65848 9.61207 6.65848 9.41375C6.65848 9.21543 6.73727 9.02523 6.8775 8.885C7.01773 8.74477 7.20793 8.66598 7.40625 8.66598C7.60457 8.66598 7.79477 8.74477 7.935 8.885L9 9.9425L10.0575 8.885C10.1977 8.74477 10.3879 8.66598 10.5862 8.66598C10.7846 8.66598 10.9748 8.74477 11.115 8.885C11.2552 9.02523 11.334 9.21543 11.334 9.41375C11.334 9.61207 11.2552 9.80227 11.115 9.9425L10.0575 11L11.115 12.0575C11.1844 12.1269 11.2395 12.2094 11.2771 12.3001C11.3147 12.3908 11.334 12.4881 11.334 12.5863C11.334 12.6844 11.3147 12.7817 11.2771 12.8724C11.2395 12.9631 11.1844 13.0456 11.115 13.115C11.0456 13.1844 10.9631 13.2395 10.8724 13.2771C10.7817 13.3147 10.6844 13.334 10.5862 13.334C10.4881 13.334 10.3908 13.3147 10.3001 13.2771C10.2094 13.2395 10.1269 13.1844 10.0575 13.115L9 12.0575L7.9425 13.115C7.87306 13.1844 7.79063 13.2395 7.69991 13.2771C7.60918 13.3147 7.51195 13.334 7.41375 13.334C7.31555 13.334 7.21832 13.3147 7.12759 13.2771C7.03687 13.2395 6.95444 13.1844 6.885 13.115C6.81556 13.0456 6.76048 12.9631 6.7229 12.8724C6.68533 12.7817 6.66598 12.6844 6.66598 12.5863C6.66598 12.4881 6.68533 12.3908 6.7229 12.3001C6.76048 12.2094 6.81556 12.1269 6.885 12.0575L7.9425 11L6.8775 9.9425ZM13.5 3.5H11.625L11.0925 2.9675C10.9575 2.8325 10.7625 2.75 10.5675 2.75H7.4325C7.2375 2.75 7.0425 2.8325 6.9075 2.9675L6.375 3.5H4.5C4.0875 3.5 3.75 3.8375 3.75 4.25C3.75 4.6625 4.0875 5 4.5 5H13.5C13.9125 5 14.25 4.6625 14.25 4.25C14.25 3.8375 13.9125 3.5 13.5 3.5Z"
                fill="#474747"
              />
            </svg>
          </div>
        </div>
      </div>
      {editor === 'edit' && (
        <div className="editor">
          <Editors
            setEditor={setEditor}
            header="Edit"
            _id={Data._id}
            fetchData={fetchData}
          />
        </div>
      )}
      {preview === 'preview' && (
        <div
          className="preview"
          onClick={() => setPreview('idle')}>
          <Preview
            _id={Data._id}
            setPreview={setPreview}
          />
        </div>
      )}
    </>
  );
}
