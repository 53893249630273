/** @format */

import axios from 'axios';
import Cookies from 'js-cookie';
import { useEffect, useState } from 'react';
import { ConvertTime } from '../../../../utils/timezoneconvertor';
import useConvertIntoExcel from '../../customhooks/useConvertIntoExcel.customhook';
import './SellerLeaders.css';
import SellerLeadsHeader from './SellerLeadesHeader';
import SellerLeadInfo from './SellerLeadInfo';
export default function SellerForm() {
  const [data, setData] = useState([]);
  const [sort, setSort] = useState('dsc');
  const [searchTerm, setSearchTerm] = useState('');
  const COlumnsLength = [
    { wpx: 150 },
    { wpx: 400 },
    { wpx: 200 },
    { wpx: 600 },
    { wpx: 600 },
    { wpx: 150 },
    { wpx: 150 },
    { wpx: 150 },
    { wpx: 150 },
    { wpx: 150 },
  ];
  const { exportToExcel } = useConvertIntoExcel(COlumnsLength);

  const handleAllData = () => {
    const filterData = data.map((data) => ({
      name: data.name,
      email: data.email,
      phone: data.phone ? data.phone : 'N/A',
      message: data.message,
      address: data.address,
      status: data.status,
      createdAtTime: ConvertTime(data.createdAt).split('-')[0],
      createdAtDate: ConvertTime(data.createdAt).split('-')[1],
      updatedAtTime: ConvertTime(data.updatedAt).split('-')[0],
      updatedAtDate: ConvertTime(data.updatedAt).split('-')[1],
    }));
    exportToExcel(filterData, 'sellerLeads');
  };
  //search
  const handleSearch = () => {
    fetchData(searchTerm);
  };

  const fetchData = async (searchTerm: string) => {
    const token = Cookies.get('token');
    try {
      const data = await axios.get('/admin/seller-lead/', {
        headers: {
          'x-access-token': token,
        },
        params: {
          sortOrder: sort,
          search: searchTerm,
        },
      });
      setData(data.data.data);
      console.log(data);
    } catch (err) {
      console.error(err);
    }
  };

  //for sort and first time load data
  useEffect(() => {
    fetchData(searchTerm);
  }, [sort]);
  return (
    <div className="Container">
      <SellerLeadsHeader
        fetchData={fetchData}
        setSearchTerm={setSearchTerm}
        Search={handleSearch}
        setSort={setSort}
        downloadAll={handleAllData}
      />
      <div className="seller_container">
        <div className="seller_leader_header">
          <p className="heading">name</p>
          <p className="heading bar">email</p>
          {/* <p className="heading bar">address</p>
          <p className="heading bar">message</p> */}
          <p className="heading bar">contact</p>
          <p className="heading bar center">date&time</p>
          <p className="heading bar center">view</p>
          <p className="heading bar center">mark status</p>
        </div>
        <div className="seller_info_list">
          {data.length !== 0 ? (
            data.map((data) => (
              <SellerLeadInfo
                Data={data}
                key={data._id}
              />
            ))
          ) : (
            <p style={{ color: 'white', padding: '10px' }}>
              No leads data yet.
            </p>
          )}
        </div>
      </div>
    </div>
  );
}
