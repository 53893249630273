/** @format */

import { Container, Feature, FeatureContainer } from './Feature_Facts.style';
export default function FeatureFacts({ Data }) {
  // <h1>Properties</h1>
  // { building_status && <p>Status:{building_status}</p> }
  console.log(Data.length);
  return (
    <Container>
      <h1>Facts & Feature</h1>
      <FeatureContainer>
        <div className="lef_section">
          {Data?.slice(0, 5).map((data, i) => {
            const FilterData = data.children.filter(
              (child) => child?.value?.length > 0
            );
            return (
              <>
                {FilterData.length > 0 ? (
                  <Feature key={i}>
                    <h1>{data.name}</h1>
                    {FilterData.map((child, j) => {
                      return (
                        <p key={j}>{`${child.name} : ${
                          child.value?.length === 0 ? 'N/A' : child.value
                        }`}</p>
                      );
                    })}
                  </Feature>
                ) : null}
              </>
            );
          })}
        </div>

        <div className="right_section">
          {Data?.slice(5).map((data, i) => {
            const FilterData = data.children.filter(
              (child) => child?.value?.length > 0
            );
            return (
              <>
                {FilterData.length > 0 ? (
                  <Feature key={i}>
                    <h1>{3 === i ? 'Land' : data.name}</h1>
                    {FilterData.map((child, j) => {
                      return (
                        <p key={j}>{`${child.name} : ${
                          child.value?.length === 0 ? 'N/A' : child.value
                        }`}</p>
                      );
                    })}
                  </Feature>
                ) : null}
              </>
            );
          })}
        </div>
      </FeatureContainer>
    </Container>
  );
}
