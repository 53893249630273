/** @format */

import LiveEdithTextHighLighter from '../../../../liveEditHighlighterstyles/liveEdithTextHighLighter.component';
import LiveEditImageHighLighter from '../../../../liveEditHighlighterstyles/liveEditImageHighLighter.component';
//images
import {
  Container,
  EmailContainer,
  FooterContainer,
  FooterLinks,
  FooterSocialMediaLinksContainer,
  NavigationContainer,
} from './footer.style';

import useAddUserForNewseletter from '../../../../utils/apis/AddUserForNewseLetter';

type FooterData = {
  data?: {
    sec8: Record<string, string>;
    footer: any;
  };
};
export default function Footer({ data }: FooterData) {
  const { stateData, handleSubscriptionForNewsletter, handleState } =
    useAddUserForNewseletter();

  return (
    <Container>
      <EmailContainer
        $color={data?.sec8.color}
        id="sec7">
        <div className="HomeContainer" style={{ height: "450px" }}>
          <div className="leftSection" style={{ maxWidth: "500px", width: "100%" }}>
            <p className="Text">
              <LiveEdithTextHighLighter
                section={'sec8'}
                type={'text'}>
                {data?.sec8.text}
              </LiveEdithTextHighLighter>
            </p>
            <form
              className="input_container_email"
              onSubmit={(e) => handleSubscriptionForNewsletter(e)}>
              <div className="wrapper">
                <svg
                  width="49"
                  height="49"
                  viewBox="0 0 49 49"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M24.9219 24.1152C22.7219 24.1152 20.8385 23.3319 19.2719 21.7652C17.7052 20.1986 16.9219 18.3152 16.9219 16.1152C16.9219 13.9152 17.7052 12.0319 19.2719 10.4652C20.8385 8.89857 22.7219 8.11523 24.9219 8.11523C27.1219 8.11523 29.0052 8.89857 30.5719 10.4652C32.1385 12.0319 32.9219 13.9152 32.9219 16.1152C32.9219 18.3152 32.1385 20.1986 30.5719 21.7652C29.0052 23.3319 27.1219 24.1152 24.9219 24.1152ZM8.92188 40.1152V34.5152C8.92188 33.3819 9.21388 32.3406 9.79788 31.3912C10.3819 30.4419 11.1565 29.7166 12.1219 29.2152C14.1885 28.1819 16.2885 27.4072 18.4219 26.8912C20.5552 26.3752 22.7219 26.1166 24.9219 26.1152C27.1219 26.1139 29.2885 26.3726 31.4219 26.8912C33.5552 27.4099 35.6552 28.1846 37.7219 29.2152C38.6885 29.7152 39.4639 30.4406 40.0479 31.3912C40.6319 32.3419 40.9232 33.3832 40.9219 34.5152V40.1152H8.92188Z"
                    fill="black"
                  />
                </svg>
                <input
                  className="email_input"
                  type="name"
                  placeholder="name"
                  onChange={(e) => handleState('name', e.target.value)}
                  required
                />
              </div>
              <div className="wrapper">
                <svg
                  width="50"
                  height="50"
                  viewBox="0 0 50 50"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M39.6538 8.80811H11.1154C9.49348 8.80811 7.93801 9.4524 6.79115 10.5993C5.6443 11.7461 5 13.3016 5 14.9235V35.3081C5 36.93 5.6443 38.4855 6.79115 39.6323C7.93801 40.7792 9.49348 41.4235 11.1154 41.4235H39.6538C41.2757 41.4235 42.8312 40.7792 43.9781 39.6323C45.1249 38.4855 45.7692 36.93 45.7692 35.3081V14.9235C45.7692 13.3016 45.1249 11.7461 43.9781 10.5993C42.8312 9.4524 41.2757 8.80811 39.6538 8.80811ZM39.6538 12.885L26.4038 21.997C26.094 22.1759 25.7424 22.2701 25.3846 22.2701C25.0268 22.2701 24.6753 22.1759 24.3654 21.997L11.1154 12.885H39.6538Z"
                    fill="black"
                  />
                </svg>
                <input
                  className="email_input"
                  type="email"
                  placeholder="your email here"
                  required
                  onChange={(e) =>
                    handleState('newsletteremail', e.target.value)
                  }
                  value={stateData.newsletteremail}
                />
              </div>
              <button className="email_button">subscribe/cancel</button>
            </form>
          </div>

          <img
            src={`/public/${data?.sec8?.image}`}
            alt="EmailBackground"
            className="backgroundImage"
            style={{
              filter: 'brightness(40%)'
            }}
          />
        </div>
      </EmailContainer>
      <FooterContainer id="footer">
        <div className="upper_section">
          <div className="leftSection">
            <div>
              <LiveEditImageHighLighter section={'footer'} type="img">
                <img
                  src={'/public/' + data?.footer?.image}
                  alt="logo"
                  style={{ width: '50px', height: '50px' }}
                />
              </LiveEditImageHighLighter>
            </div>


            <p className="description" style={{ fontSize: '16px', marginBottom: '4px' }}>
              <LiveEdithTextHighLighter section={'footer'} type={'description'}>
                {data?.footer?.text2}
              </LiveEdithTextHighLighter>
            </p>

            <p className="description" style={{ fontSize: '9px', marginTop: '2px' }}>
              <LiveEdithTextHighLighter section={'footer'} type={'description'}>
                {data?.footer?.text3}
              </LiveEdithTextHighLighter>
            </p>


            <h6 style={{ color: 'white', marginBottom: '4px' }}>Phone</h6>
            <p className="description" style={{ fontSize: '9px', marginTop: '2px' }}>
              <LiveEdithTextHighLighter section={'footer'} type={'description'}>
                {data?.footer?.text4}
              </LiveEdithTextHighLighter>
            </p>

            <h6 style={{ color: 'white', marginBottom: '4px' }}>Email</h6>
            <p className="description" style={{ fontSize: '9px', marginTop: '2px' }}>
              <LiveEdithTextHighLighter section={'footer'} type={'description'}>
                {data?.footer?.text5}
              </LiveEdithTextHighLighter>
            </p>

            <h6 style={{ color: 'white', marginBottom: '4px' }}>Address</h6>
            <p className="description" style={{ fontSize: '9px', marginTop: '2px' }}>
              <LiveEdithTextHighLighter section={'footer'} type={'description'}>
                {data?.footer?.text6}
              </LiveEdithTextHighLighter>
            </p>

            <FooterSocialMediaLinksContainer>
              {data?.footer?.socialmedialinks.map((data) => (
                <a
                  href={data.link}
                  className="image_wrapper">
                  <img
                    src={`/public/${data.image}`}
                    alt="socialMediaLinks"
                  />
                </a>
              ))}
            </FooterSocialMediaLinksContainer>
            <p className="description" style={{ fontSize: '10px' }}>
              <LiveEdithTextHighLighter
                section={'footer'}
                type={'description'}>
                {data?.footer?.description}
              </LiveEdithTextHighLighter>
            </p>
          </div>
          <div className="rightSection">
            {data?.footer?.columns.map((data) => (
              <NavigationContainer key={data.text}>
                <p className="footer_link_header">{data.text} </p>
                {data.items.map((item) => (
                  <FooterLinks
                    key={item.text}
                    to={item.link}
                    target="_blank">
                    {item.text}
                  </FooterLinks>
                ))}
              </NavigationContainer>
            ))}
          </div>
        </div>
        <div className="down_section">
          <p className="footer_mark">
            Powered by{' '}
            <a
              target="_blank"
              href="https://agentshades.com"
              rel="noreferrer">
              AgentShades.com
            </a>
          </p>
          <p className="footer_mark">Copyright ©2022 Agentshades.com</p>
        </div>
        {/* <div className="agentshades_trademark">
        <p>
          Powered by{' '}
          <a
            target="_blank"
            href="https://agentshades.com"
            rel="noreferrer">
            AgentShades.com
          </a>
        </p>
      </div> */}
      </FooterContainer>
    </Container>
  );
}
