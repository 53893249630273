import { useMessageContext } from "../../../context/Message.context";
import { Container, ErrorBoxContainer } from "./Errorbox.style";

export default function ErrorBox() {
  const { setErrorShow, state } = useMessageContext();
  console.log(state.theme);
  const handleShow = () => {
    setErrorShow(false);
  };
  return (
    <Container>
      <ErrorBoxContainer
        $theme={state.theme}
        initial={{ scale: 0.5 }}
        animate={{ scale: 1 }}
        exit={{ scale: 0 }}
        transition={{ duration: 0.5 }}
      >
        <div className="svg_wrapper">
          <svg
            width="45"
            height="45"
            viewBox="0 0 45 45"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M20.6269 18.75H24.3769V28.125H20.6269V18.75ZM20.625 30H24.375V33.75H20.625V30Z"
              fill="white"
            />
            <path
              d="M25.8133 7.87504C25.1608 6.64691 23.8896 5.88379 22.4983 5.88379C21.1071 5.88379 19.8358 6.64691 19.1833 7.87691L5.42457 33.87C5.11926 34.4408 4.96802 35.0814 4.98578 35.7285C5.00353 36.3756 5.18967 37.0068 5.52582 37.56C5.85713 38.1157 6.32764 38.5754 6.89091 38.8937C7.45418 39.212 8.09072 39.3779 8.73769 39.375H36.2589C37.5864 39.375 38.7883 38.6963 39.4727 37.56C39.8083 37.0066 39.9941 36.3754 40.0119 35.7284C40.0296 35.0815 39.8787 34.441 39.5739 33.87L25.8133 7.87504ZM8.73769 35.625L22.4983 9.63191L36.2683 35.625H8.73769Z"
              fill="white"
            />
          </svg>
        </div>
        <p className="try_again_message">Please Try Again</p>
        <p className="message">{state.Message}</p>
        <button className="close_button" onClick={handleShow}>
          Okay, close this
        </button>
      </ErrorBoxContainer>
    </Container>
  );
}
