/** @format */

import { ConvertTime } from '../../../../utils/timezoneconvertor';

import './preview.css';

export default function Preview({ Data, setPreview }) {
  return (
    <div
      className="seller_preview"
      onClick={(e) => e.stopPropagation()}>
      <div className="seller_preview_header">
        <div className="seller_preview_header_info">
          <h1>View Data</h1>
          <p>-last updated {ConvertTime(Data.updatedAt)}</p>
        </div>
        <div
          className="seller_preview_closer"
          onClick={() => setPreview('close')}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="14"
            height="14"
            viewBox="0 0 14 14"
            fill="none">
            <path
              d="M13.4609 1.76355C13.6562 1.56829 13.6562 1.25171 13.4609 1.05645L12.758 0.353553C12.5627 0.158291 12.2462 0.158291 12.0509 0.353553L7.16801 5.23645C6.97274 5.43171 6.65616 5.43171 6.4609 5.23645L1.57801 0.353553C1.38274 0.158291 1.06616 0.158291 0.8709 0.353553L0.168006 1.05645C-0.0272557 1.25171 -0.0272556 1.56829 0.168007 1.76355L5.0509 6.64645C5.24616 6.84171 5.24616 7.15829 5.0509 7.35355L0.168006 12.2364C-0.0272558 12.4317 -0.0272556 12.7483 0.168007 12.9436L0.8709 13.6464C1.06616 13.8417 1.38274 13.8417 1.57801 13.6464L6.4609 8.76355C6.65616 8.56829 6.97274 8.56829 7.16801 8.76355L12.0509 13.6464C12.2462 13.8417 12.5627 13.8417 12.758 13.6464L13.4609 12.9436C13.6562 12.7483 13.6562 12.4317 13.4609 12.2364L8.57801 7.35355C8.38274 7.15829 8.38274 6.84171 8.57801 6.64645L13.4609 1.76355Z"
              fill="#999999"
            />
          </svg>
        </div>
      </div>
      <div className="seller_preview_info">
        <div className="seller_details">
          <p className="head">name</p>
          <p>{Data.name}</p>
        </div>
        <div className="seller_details">
          <p className="head">email</p>
          <p>{Data.email}</p>
        </div>
        <div className="seller_details">
          <p className="head">phone</p>
          <p>{Data.phone ? Data.phone : 'N/A'}</p>
        </div>
        <div className="seller_details">
          <p className="head">address</p>
          <p>{Data.address}</p>
        </div>
        <div className="seller_details">
          <p className="head">message</p>
          <p>{Data.message}</p>
        </div>
        <div className="seller_details">
          <p className="head">date and time</p>
          <p>{ConvertTime(Data.updatedAt)}</p>
        </div>
        <div className="seller_details">
          <p className="head">status</p>
          <p>
            {Data.status} {ConvertTime(Data.updatedAt)}
          </p>
        </div>
      </div>
    </div>
  );
}
