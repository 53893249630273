/** @format */

import styled from 'styled-components';

export const BoxContainer = styled.div`
  padding: 0.9375rem;
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 100;
  width: 100%;
  box-shadow: 0 0 13px rgba(0, 0, 0, 0.4);
  border-radius: 0rem 0rem 0.9375rem 0.9375rem;
  background: white;
  max-height: 20rem;
  overflow-y: scroll;
  .location {
    font-family: Inter;
    font-size: 14px;
    font-weight: 500;
    line-height: 16.94px;
    text-align: left;
    color: ${(props) =>
      props?.theme?.colors?.primary
        ? props?.theme?.colors?.primary
        : 'var(--primary)'};
  }
`;

export const City = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 1.5rem;
  /* padding-bottom:0.8rem ; */
  border-bottom: 1px solid
    ${(props) =>
      props?.theme?.colors?.primary
        ? props?.theme?.colors?.primary
        : 'var(--primary)'};
  margin-bottom: 0.4rem;
  cursor: pointer;
  .left_section {
    display: flex;
    gap: 1rem;
    width: 100%;
    margin-bottom: 0.5rem;
    svg {
      flex: 0.1;
      path {
        fill: ${(props) =>
          props?.theme?.colors?.primary
            ? props?.theme?.colors?.primary
            : 'var(--primary)'};
      }
    }
    p {
      flex: 0.9;
      margin: 0;
    }
  }
  .right_section {
    p {
      margin: 0;
      font-family: Inter;
      font-size: 14px;
      font-weight: 500;
      line-height: 16.94px;
      text-align: left;
    }
  }
`;
export const Address = styled(City)``;

export const Community = styled(City)``;
