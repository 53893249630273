/** @format */

import { Route, Routes } from 'react-router-dom';
import FilterContextProvider from '../context/filter.context';
import { useMessageContext } from '../context/Message.context';
import ErrorBox from '../pages/Components/Errorbox/Errorbox.component';
import PageNotFound from '../pages/Components/pageNotFound/pageNotFound.component';
import Previewwrapper from '../pages/Components/previewwrapper/previewwrapper.component';
import Successbox from '../pages/Components/Successbox/Successbox.component';
import Template2Aboutus from '../pages/Template2/aboutus/aboutus.t2';
import Template2ContactUs from '../pages/Template2/contactus/contact.t2';
import Template2Home from '../pages/Template2/home/home.t2';
import IDXWrappertemplatetwo from '../pages/Template2/IDXWrapper/IDXWrapper.t2';
import BlogList from '../pages/Template3/blog_section/blog_section.component';
import BlogView from '../pages/utilComponent/BlogView/BlogView';
import Builderdeals from '../pages/utilComponent/builderdeals/builderdeals.component';
import BuilderdealsPropertyDetails from '../pages/utilComponent/builderdealsPropertyDetails/builderdealsPropertyDetails.t1';
import IdxListing from '../pages/utilComponent/idxListing/IdxListing.utilComponent';
import PropertyView from '../pages/utilComponent/idxView/idxView.component';
import ListingMap from '../pages/utilComponent/Maps/ListingMap.untilComponent';
import { PROPERTY_TYPE_CLASSES } from '../pages/utilComponent/PropertyCard/PropertyCard.utilComponent';
import SoldListing from '../pages/utilComponent/soldListing/soldListing.utilcomponent';
export default function Template2Route() {
  const { state } = useMessageContext();
  return (
    <>
      <Routes>
        <Route
          path=":preview?"
          element={<Previewwrapper />}>
          <Route
            path="home"
            element={<Template2Home {...{ templateData: null }} />}
          />
          <Route
            path="about_us"
            element={<Template2Aboutus {...{ templateData: null }} />}
          />
          <Route
            path="contact_us"
            element={
              <Template2ContactUs
                {...{ templateData: null }}
                templateId={2}
              />
            }
          />
          <Route
            path="idxlisting"
            element={
              <FilterContextProvider>
                <IDXWrappertemplatetwo />
              </FilterContextProvider>
            }>
            <Route
              index
              element={
                <IdxListing
                  type="template1"
                  cardType={PROPERTY_TYPE_CLASSES.propertycard_temp3}
                />
              }
            />
            <Route
              path="idxView/:propertyId"
              element={
                <PropertyView
                  cardType={PROPERTY_TYPE_CLASSES.propertycard_temp3}
                />
              }
            />
          </Route>

          <Route
            path="map"
            element={
              <FilterContextProvider>
                <IDXWrappertemplatetwo />
              </FilterContextProvider>
            }>
            <Route
              index
              element={
                <ListingMap
                  cardType={PROPERTY_TYPE_CLASSES.propertycard_temp3}
                />
              }
            />
            <Route
              path="idxView/:propertyId"
              element={
                <PropertyView
                  cardType={PROPERTY_TYPE_CLASSES.propertycard_temp3}
                />
              }
            />
          </Route>
          <Route
            path="blog_list"
            element={<IDXWrappertemplatetwo />}>
            <Route
              index
              element={<BlogList />}
            />
            <Route
              path="blogview"
              element={<BlogView />}
            />
          </Route>
          <Route
            path="soldlisting"
            element={
              <FilterContextProvider>
                <IDXWrappertemplatetwo />
              </FilterContextProvider>
            }>
            <Route
              index
              element={
                <SoldListing
                  cardType={PROPERTY_TYPE_CLASSES.propertycard_temp3}
                />
              }
            />
            <Route
              path=":type/soldView/:propertyId"
              element={
                <PropertyView
                  cardType={PROPERTY_TYPE_CLASSES.propertycard_temp3}
                />
              }
            />
          </Route>
          <Route
            path="builderdeals/"
            element={
              <FilterContextProvider>
                <IDXWrappertemplatetwo />
              </FilterContextProvider>
            }>
            <Route
              index
              element={
                <Builderdeals
                  cardType={PROPERTY_TYPE_CLASSES.propertycard_temp3}
                />
              }
            />
            <Route
              path="builder_view/:propertyId"
              element={
                <BuilderdealsPropertyDetails
                  cardType={PROPERTY_TYPE_CLASSES.propertycard_temp3}
                />
              }
            />
          </Route>
          <Route
            path="*"
            element={<IDXWrappertemplatetwo />}>
            <Route
              path="*"
              element={<PageNotFound />}
            />
          </Route>
        </Route>
      </Routes>
      {state.ErrorShow && <ErrorBox />}
      {state.SuccessShow && <Successbox />}
    </>
  );
}
