/** @format */

export const home_type = [
  {
    home_type: 'Any',
    type: {
      idx: '',
      sold: '',
      builder_deals: '',
    },
  },
  {
    home_type: 'Detached',
    type: {
      idx: 'Detached',
      sold: 'Detached',
      builder_deals: 'Detached',
    },
  },
  {
    home_type: 'Semi-Detached',
    type: {
      idx: 'Semi-Detached',
      sold: 'Semi-Detached',
      builder_deals: 'Semi-Detached',
    },
  },
  {
    home_type: 'Town House',
    type: {
      idx: 'Att/Row/Twnhouse',
      sold: 'Att/Row/Twnhouse',
      builder_deals: 'Att/Row/Twnhouse',
    },
  },
  {
    home_type: 'Condo Town House',
    type: {
      idx: 'Condo Townhouse',
      sold: 'Condo Townhouse',
      builder_deals: 'Condo Townhouse',
    },
  },
  {
    home_type: 'Condo Apartment',
    type: {
      idx: 'Condo Apt',
      sold: 'Condo Apt',
      builder_deals: 'Condo Apt',
    },
  },
];
export const home_style = [
  {
    home_style: 'Any',
    type: {
      idx: '',
      sold: '',
    },
  },
  {
    home_style: '2-Storey',
    type: {
      idx: '2-Storey',
      sold: '2-Storey',
    },
  },
  {
    home_style: '3-Storey',
    type: {
      idx: '3-Storey',
      sold: '3-Storey',
    },
  },
  {
    home_style: 'Bungalow',
    type: {
      idx: 'Bungalow',
      sold: 'Bungalow',
    },
  },
  {
    home_style: 'Multi-Level',
    type: {
      idx: 'Multi-Level',
      sold: 'Multi-Level',
    },
  },
  {
    home_style: 'Other',
    type: {
      idx: 'Other',
      sold: 'Other',
    },
  },
];

export const builder_status = [
  {
    builder_status: 'Any',
    type: {
      builder_deals: '',
    },
  },
  {
    builder_status: 'Pre-construction',
    type: {
      builder_deals: 'Pre-construction',
    },
  },
  {
    builder_status: 'Inventory',
    type: {
      builder_deals: 'Inventory',
    },
  },
  {
    builder_status: 'Assignment sale',
    type: {
      builder_deals: 'Assignment sale',
    },
  },
];

export const sort_by = [
  {
    sort_by: 'Recommended',
    type: {
      // idx: 'list_price',
      idx: 'timestamp_idx',
      sold: 'sold_price',
      builder_deals: '',
    },
    order: 'desc',
  },
  {
    sort_by: 'Most Recent',
    type: {
      idx: 'timestamp_idx',
      sold: 'sold_date',
      builder_deals: 'created_at',
    },
    order: 'desc',
  },
  {
    sort_by: 'Most Expensive',
    type: {
      idx: 'list_price',
      sold: 'sold_price',
      builder_deals: 'list_price',
    },
    order: 'desc',
  },
  {
    sort_by: 'Oldest',
    type: {
      idx: 'list_price',
      sold: 'sold_price',
      builder_deals: 'created_at',
    },
    order: 'asc',
  },
  {
    sort_by: 'Least Expensive',
    type: {
      idx: 'list_price',
      sold: 'sold_price',
      builder_deals: 'list_price',
    },
    order: 'asc',
  },
  {
    sort_by: 'Most Bedroom',
    type: {
      idx: 'bedrooms',
      sold: 'bedrooms',
      builder_deals: 'bedroom',
    },
    order: 'desc',
  },
];
