/** @format */

import React from 'react';
import { Container } from './DomainInstruction.style';
export default function DomainInstruction({ setStep, step }) {
  return (
    <Container
      initial={{ opacity: 0}}
      animate={{ opacity: 1}}
      exit={{ opacity: 0 , y:-1000 }}
      transition={{ easeIn: 'easeOut', duration: 0.5 }}
      className="domain_instruction"
      $step={step}>
      <div className="Instructions">
        <p className="instruction_heading">Domain Setup</p>
        <p className="heading">Do you own a domain?</p>
        <div className="buttons_container">
          <button
            className={`button_redirect ${step === 1  && 'active'} `}
            onClick={() => setStep(1)}>
            No, Show me how to get one
          </button>
          <button
            className="button_redirect"
            onClick={() => setStep(3)}>
            Yes, Skip to connecting it
            <svg
              width="13"
              height="11"
              viewBox="0 0 13 11"
              fill="none"
              xmlns="http://www.w3.org/2000/svg">
              <path
                d="M12 5.5L7.5 1M12 5.5L7.5 10M12 5.5H1.5"
                stroke="#1E1E1E"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </button>
        </div>
      </div>
    </Container>
  );
}
