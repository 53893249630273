/** @format */

import Info from '../Images/ic_outline-info.svg';
export default function BillingHistory() {
  return (
    <div className="billing_container">
      <div className="billing_history_nav justify-content-between">
        <p className="heading">Billing Hisotry</p>
        <select
          name=""
          className="heading float-right"
          id="">
          <option value="">All Dates</option>
          <option value="">Past 6 Months</option>
          <option value="">Past 1 Year</option>
          <option value="">Year 2022</option>
        </select>
      </div>
      <div className="billing_list_container">
        {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((data) => (
          <div
            className="billing_list d-flex justify-content-between mt-2 "
            key={data}>
            <div className="wrapper align-items-center gap-2">
              <img
                src={Info}
                alt="Info"
              />
              <p className="heading">standard plan</p>
            </div>
            <span className="heading">-</span>
            <p className="heading">paid</p>
            <span className="heading">-</span>
            <p className="heading">$1450</p>
            <span className="heading">-</span>
            <p className="heading">November 23, 2023</p>
          </div>
        ))}
      </div>
    </div>
  );
}
