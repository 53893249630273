/** @format */

import styled from 'styled-components';

import { motion } from 'framer-motion';

export const MainContainer = styled.div`
  .button_redirect {
    margin: 0 auto;
    margin-top:0.75rem;
    border: none;
    outline: none;
    padding: 0.9375rem 1.25rem 0.9375rem 1.25rem;
    gap: 0.625rem;
    border-radius: 0.75rem;
    color: white;
    background-color: #1e1e1e;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    svg {
      path {
        stroke: white;
      }
    }
    &:hover {
      background: linear-gradient(94.98deg, #d9d9d9 0%, #ffffff 100%);
      color: #1e1e1e;
      svg {
        path {
          stroke: #1e1e1e;
        }
      }
    }
  }
`;

export const Container = styled(motion.div)`
  width: min(1200px, 95%);
  margin: 0 auto;
  margin-top: -3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 3rem;
  .video{
    .video_player{
      border-radius: 0.625rem;
    }
  }
  .bar {
    height: 20rem;
    min-height: 100%;
    max-height: 100%;
    width: 0.25rem;
    background: var(--Darker-Grey---Fields-BG, #474747);
  }
  .description {
    .description_heading {
      font-family: Roboto;
      font-size: 22px;
      font-weight: 500;
      line-height: 25.78px;
      letter-spacing: 0.2em;
      text-align: left;
      color: #aaaaaa;
    }
    ul {
      li {
        font-family: Roboto;
        font-size: 20px;
        font-weight: 400;
        line-height: 23.44px;
        text-align: left;
        color: #d9d9d9;
      }
    }
  }
`;
