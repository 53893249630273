/** @format */

import React from 'react';
import { Container, MainContainer } from './DomainDescription.style';

export default function DomainDescription({ setStep, domain }) {
  const DomainVideos = [
    'https://www.youtube.com/embed/T6mKmpcyMAc?si=h24daZgdvtFGSjgW',
    'https://www.youtube.com/embed/JRRXTR7PUug?si=13WBSYO-XuyvJgdm',
    'https://www.youtube.com/embed/JJEdcE-kTZI?si=VEWHUW2dLmb0dYi2'
  ];
  return (
    <MainContainer>
      <Container
        initial={{ opacity: 0, y: 100 }}
        animate={{ opacity: 1, y: 0 }}
        exit={{ opacity: 0, y: -1000 }}
        transition={{ easeIn: 'easeOut', duration: 0.5 }}>
        <div className="video">
          <iframe
            className='video_player'
            width="560"
            height="315"
            src={DomainVideos[domain]}
            title="YouTube video player"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            referrerPolicy="strict-origin-when-cross-origin"
            allowFullScreen></iframe>
        </div>
        <div className="bar"></div>
        <div className="description">
          <p className="description_heading">Description</p>
          <ul>
            <li>
              Lorem ipsum dolor sit amet consectetur. Odio quisque in ultricies
              mauris duis. Sollicitudin nec lobortis purus tristique arcu sed.
              Non dolor laoreet enim ac viverra quam egestas tristique volutpat.
              Molestie malesuada sed arcu commodo urna morbi neque nisl
              suspendisse. Hac purus mattis ut mauris. Sollicitudin nibh quis
              dignissim turpis nisl cras tincidunt dapibus. Elementum nunc
              tristique sit orci sollicitudin donec sem. Egestas dictum pharetra
              enim ridiculus aliquam. Turpis odio sit facilisi at quis
              scelerisque commodo pellentesque. Est feugiat sit aenean sed
              bibendum scelerisque viverra. Etiam enim lacus porttitor eget nunc
              nulla vitae at. Risus tempor fermentum cursus vitae maecenas.
              Metus elementum varius diam sit habitasse fermentum nisi fames.{' '}
            </li>
          </ul>
        </div>
      </Container>
      <button
        className="button_redirect"
        onClick={() => setStep(3)}>
        Got my Domain, Help me connect it
        <svg
          width="13"
          height="11"
          viewBox="0 0 13 11"
          fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <path
            d="M11.5 5.5L7 1M11.5 5.5L7 10M11.5 5.5H1"
            stroke="#1E1E1E"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </button>
    </MainContainer>
  );
}
