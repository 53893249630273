
import { useState } from 'react';

import axios from 'axios';
import Cookies from 'js-cookie';
import { useMessageContext } from '../../context/Message.context';

export default function useBuyerLead(formData) {
    const [loader, setloader] = useState<boolean>(false);
    const { setErrorShow, setMessage, setSuccessShow } = useMessageContext()
    const handleBuyerLead = async(e)=>{
        const token = Cookies.get('token');
       e.preventDefault();
       setloader(true)
        const agentUrl = window.location.href.split('/')[2];
       try{
           let config = {};
           if (agentUrl.includes('agentshades')) {
               config = {
                   headers: {
                       'x-access-token': token,
                   },
               };
           }
           await axios.post('/api/add-buyer-lead', formData, config);
           setloader(false);
           setSuccessShow(true);
           setMessage(`Your appointment is scheduled!`)
       }catch(err){
          setloader(false);
       }finally{
        setErrorShow(true);
        setMessage('something went wrong please try again letter')
        setloader(false);
       }
    }
  return {
      handleBuyerLead,
      loader
  }
}
