/** @format */

import styled from 'styled-components';
import { Container } from '../../Style/CommonStyle.t3';

export const HeroContainer = styled(Container)`
  .HeroImage {
    max-height: 85vh;
    overflow: hidden;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  .hero_information {
    margin-top: 2rem;
    .sub_header {
      font-family: 'Cormorant Garamond';
      font-size: 18px;
      font-size: clamp(1rem, 1.125vw, 1.125rem);
      text-align: center;
      color: ${({ theme: { colors } }) => colors.primary};
      // text-transform: uppercase;
      // * {
      //   font-family: Syncopate;
      //   font-size: 18px;
      //   font-size: clamp(1rem, 1.125vw, 1.125rem);
      //   text-align: center;
      //   color: ${({ theme: { colors } }) => colors.primary};
      //   text-transform: uppercase;
      // }
    }
    .header,
    .header p {
      font-family: Syncopate;
      font-size: 2rem;
      font-size: clamp(2rem, 4vw, 4rem);
      font-weight: 400;
      text-align: center;
      color: ${({ theme: { colors } }) => colors.primary};
      margin-top: -1rem;
    }
    .description,
    .description p {
      font-family: 'Cormorant Garamond';
      font-size: 18px;
      font-weight: 300;
      line-height: 1.3181rem;
      text-align: center;
      color: black;
      width: min(659px, 95%);
      margin: 0 auto;
    }
  }
`;
