/** @format */

import styled from 'styled-components';

import { CustomArrowPrev, CutomArrowNext } from '../section2/section2.style';

export const Prev = styled(CustomArrowPrev)`
  svg {
    path {
       fill: ${({theme:{colors}}) => colors?.primary ? colors?.primary : 'var(--primary)'};
    }
  }
`;
export const Next = styled(CutomArrowNext)`
  svg {
    path {
      fill: ${({theme:{colors}}) => colors?.primary ? colors?.primary : 'var(--primary)'};
    }
  }
`;

export const Container = styled.div`
  font-family: 'Syncopate';
  width: min(95%, 85rem);
  margin: 4rem auto;
  h1,
  h4 {
    font-family: 'Syncopate';
    padding-top: 10px;
    margin-bottom: 0;
  }
  p {
      font-family: 'Cormorant Garamond';
  }
  .team_card_wrapper {
    /* padding: 1rem; */
  }
  h1 {
    text-transform: uppercase;
    color: ${(props) => props.theme?.colors?.text2};
    text-align: center;
    margin-bottom: 3rem;
  }
  .slider {
    padding: 1rem;
  }
`;

export const TeamCard = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100%;
  cursor: pointer;
  .team_image_wrapper {
   width: 100%;
    max-width: 25.5rem;
    height: 33.875rem;
    overflow: hidden; 
    img {
       width: 100%;
      height: 100%;
      object-fit: cover; 
    }
  }
  h4 {
    font-size: clamp(1.5rem, 2vw, 2rem);
    color: ${(props) => props.theme?.colors?.text2 || 'black'} !important;
  }
  p {
    font-size: clamp(0.75rem, 1vw, 1.5rem);
   color: ${(props) => props.theme?.colors?.text2 || 'black'} !important;
  }
  transition: all 0.2s ease-in-out;
  &:hover {
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
  }

  @media screen and (max-width: 500px) {
    .team_image_wrapper {
      /* img {
        width: 100%;
      } */
    }
  }
`;

