
import { PreviewButton, PreviewWrapper } from './previewwrapper.style'

import { Outlet, useNavigate, useParams } from 'react-router-dom'


export default function Previewwrapper() {
    const navigate = useNavigate()
    const { preview } = useParams()
    const Navigate = (path) => {
        navigate(path)
    }
    const handleBackClick = () => {
        navigate(-1);
      };

    return (
        <>
            {preview === 'preview' &&
                <PreviewWrapper>
                    <PreviewButton onClick={handleBackClick}>
                        Back To Editor
                    </PreviewButton >
                    <PreviewButton onClick={() => Navigate('/mlsinstruction')}>
                        Publish
                    </PreviewButton>
                </PreviewWrapper >
            }
            <Outlet />
        </>
    )
}
