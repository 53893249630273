/** @format */

import { Container, HomeLink } from './pageNotFound.style';

export default function PageNotFound() {
  return (
    <Container>
      <div className="main_container_not_found">
        <div className="not_found_numbers">
          <p className="first_number">4</p>
          <p className="second_number">0</p>
          <p className="third_number">4</p>
        </div>
        <div className="message_container">
          <p className="message">
            Sorry, the page you are looking for does not exist.
          </p>
          <HomeLink to={'home'}>Go to Homepage</HomeLink>
        </div>
      </div>
    </Container>
  );
}
