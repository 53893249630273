/** @format */

import axios from 'axios';
import Cookies from 'js-cookie';
import { useEffect, useState } from 'react';
import './Blogs.css';
import BlogsHeader from './BlogsHeader/BlogsHeader';
import Blog from './blog/blog';
export default function Blogs() {
  const [data, setData] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [sort, setSort] = useState('desc');
  //search
  const handleSearch = () => {
    fetchData(searchTerm);
  };

  //fetching data
  const fetchData = async (searchTerm: string) => {
    const token = Cookies.get('token');
    try {
      const data = await axios.get('/admin/blog', {
        headers: {
          'x-access-token': token,
        },
        params: {
          sortOrder: sort,
          search: searchTerm,
        },
      });
      setData(data.data.data);
    } catch (err) {
      console.error(err);
    }
  };
  useEffect(() => {
    fetchData(searchTerm);
  }, [sort]);
  return (
    <div className="Container">
      <BlogsHeader
        fetchData={fetchData}
        setSearchTerm={setSearchTerm}
        Search={handleSearch}
        setSort={setSort}
      />
      <div className="wrapperContainer">
        <div className="blogContainer ">
          {data.length !== 0 ? (
            data.map((data, i) => (
              <Blog
                key={i}
                Data={data}
                fetchData={fetchData}
              />
            ))
          ) : (
            <p style={{ color: 'white', padding: '10px' }}>
              No blogs data yet.
            </p>
          )}
        </div>
      </div>
    </div>
  );
}
