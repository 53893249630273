import styled from "styled-components";
import { DashboardMainContainer, FormContainer } from "../../../CommonStyle/dashboardMain.style";

export const MainContainer = styled(DashboardMainContainer)`
display: grid;
grid-template-columns: repeat(auto-fit,28.125rem);
grid-template-rows:repeat(auto-fit,10.625rem);
gap: 0.625rem;
justify-content: center;
`

export const MlsFormContainer = styled(FormContainer)`
  position: fixed;
  z-index: 999;
  top: 0;
  left: 0;
  background: linear-gradient( to bottom, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4) );
  display: flex;
  justify-content: center;
  align-items: center;
`