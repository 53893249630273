/** @format */

import React from 'react';
import { HeroBlogCardContainer } from './hero_blog_card.style';

import ImageNotFound from '../../../../../images/image-not-found-icon.svg';
import { useNavigate } from 'react-router-dom';
import { ConvertTime } from '../../../../../utils/timezoneconvertor';
type BlogCardProps = {
  data?: Blog
}
type Blog = {
  coverImage: string,
  createdAt: string,
  description: string,
  keywords: string,
  metaDescription: string,
  metaTitle: string,
  slug: string,
  title: string,
  updatedAt: string,
  userId: string,
  _id: string,
}
export default function HeroBlogCard({ data }: BlogCardProps) {
  const navigate = useNavigate()
  const Redirect = (id) => {
    navigate(`../blog_list/blogview/?id=${id}`)
  }
  return (
    <HeroBlogCardContainer>
      <div className="left_container_blog">
        <img
          src={data?.coverImage?.length > 0 ? `/public/${data?.coverImage}` : ImageNotFound}
          alt="ImageNotFound"
        />
      </div>
      <div className="right_container_blog">
        <div className="upsection">
          <p className="header">{data?.title}</p>
          <p className="description" dangerouslySetInnerHTML={{ __html: data?.description }}>
          </p>
        </div>
        <div className="downSection">
          <div className="svg_wrapper" onClick={() => Redirect(data?._id)}>
            <svg
              width="25"
              height="25"
              viewBox="0 0 25 25"
              fill="none"
              xmlns="http://www.w3.org/2000/svg">
              <path
                d="M20.25 21.5417H4.75C4.03958 21.5417 3.45833 20.9604 3.45833 20.25V4.75C3.45833 4.03958 4.03958 3.45833 4.75 3.45833H11.2083C11.9187 3.45833 12.5 2.87708 12.5 2.16667C12.5 1.45625 11.9187 0.875 11.2083 0.875H3.45833C2.77319 0.875 2.11611 1.14717 1.63164 1.63164C1.14717 2.11611 0.875 2.77319 0.875 3.45833V21.5417C0.875 22.9625 2.0375 24.125 3.45833 24.125H21.5417C22.9625 24.125 24.125 22.9625 24.125 21.5417V13.7917C24.125 13.0812 23.5437 12.5 22.8333 12.5C22.1229 12.5 21.5417 13.0812 21.5417 13.7917V20.25C21.5417 20.9604 20.9604 21.5417 20.25 21.5417ZM15.0833 2.16667C15.0833 2.87708 15.6646 3.45833 16.375 3.45833H19.7204L7.9275 15.2512C7.68599 15.4928 7.55031 15.8203 7.55031 16.1619C7.55031 16.5034 7.68599 16.831 7.9275 17.0725C8.16901 17.314 8.49657 17.4497 8.83812 17.4497C9.17968 17.4497 9.50724 17.314 9.74875 17.0725L21.5417 5.27958V8.625C21.5417 9.33542 22.1229 9.91667 22.8333 9.91667C23.5437 9.91667 24.125 9.33542 24.125 8.625V2.16667C24.125 1.45625 23.5437 0.875 22.8333 0.875H16.375C15.6646 0.875 15.0833 1.45625 15.0833 2.16667Z"
                fill="#2C3647"
              />
            </svg>
          </div>
          <div className="blog_read_info">
            <p className="min_info">4 min Read</p>
            <p className="blog_date">{ConvertTime(data?.updatedAt)}</p>
          </div>
        </div>
      </div>
    </HeroBlogCardContainer>
  );
}
