import bgImg from "../Landing/images/pg_bg.png";
import "./SiteBuilder.css";
import HomeImg from "./images/home.png";


export default function SiteBuilder(props: any) {
    const { template, siteBuilder, data, setData } = props;

    const handleDataChange = (keyPath: string[], value: any) => {
        let updatedData = { ...data };

        let obj = updatedData;
        for (let i = 0; i < keyPath.length - 1; i++) {
            obj = obj[keyPath[i]];
        }
        obj[keyPath[keyPath.length - 1]] = value;

        setData(updatedData);
    };


    if (!siteBuilder) {
        return (
            <div>
                {template}
            </div>
        )
    }

    return (
        <div style={{ background: `url(${bgImg})`, backgroundSize: "cover" }}>
            <nav className="navbar navbar-expand-lg navbar-light bg-light designer-navbar">
                <div className="container">
                    <a className="navbar-brand" href="#">
                        AGENTSHADES  |   Website Designer
                    </a>
                    <div className="collapse navbar-collapse" id="navbarNav">
                        <ul className="navbar-nav ml-auto design-navbar">

                            <li className="nav-item" >
                                <a className="nav-link design-publish-btn" href="#">
                                    Publish
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </nav>
            <div className="row" style={{ backgroundColor: "#13131A", height: "95vh", padding: "0px 15px" }}>
                <div className="col-md-2" style={{ paddingRight: "0px", paddingLeft: "10px", alignContent: "start", marginTop: "1rem" }}>
                    <div style={{ padding: "15px 5px", backgroundColor: "rgb(32 32 35)", borderRadius: "10px" }}>
                        <h4 style={{ color: "white", textAlign: "center", marginBottom: "1rem" }}>Pages</h4>
                        <div style={{
                            background: "white",
                            borderRadius: "10px",
                            padding: "5px",
                            marginBottom: "1rem"
                        }}>
                            <div>
                                <div className="row">
                                    <div className="col-md-8" style={{ justifyContent: "start" }}>
                                        <p style={{ color: "#13131A", width: "fit-content" }}>Home</p>
                                    </div>
                                    <div className="col-md-4">
                                        <input type="checkbox" name="" id="" style={{ marginTop: "-10=2px" }} />
                                    </div>
                                </div>


                                <img src={HomeImg} alt="" style={{ width: "100%" }} />
                            </div>
                        </div>

                        <div style={{
                            background: "#414141",
                            borderRadius: "10px",
                            padding: "5px",
                            marginBottom: "1rem"
                        }}>
                            <div>
                                <div className="row">
                                    <div className="col-md-8" style={{ justifyContent: "start" }}>
                                        <p style={{ color: "white", width: "fit-content" }}>About Us</p>
                                    </div>
                                    <div className="col-md-4">
                                        <input type="checkbox" name="" id="" style={{ marginTop: "-10=2px" }} />
                                    </div>
                                </div>


                                <img src={HomeImg} alt="" style={{ width: "100%" }} />
                            </div>
                        </div>

                        <div style={{
                            background: "#414141",
                            borderRadius: "10px",
                            padding: "5px",
                            marginBottom: "1rem"
                        }}>
                            <div>
                                <div className="row">
                                    <div className="col-md-8" style={{ justifyContent: "start" }}>
                                        <p style={{ color: "white", width: "fit-content" }}>Contact Us</p>
                                    </div>
                                    <div className="col-md-4">
                                        <input type="checkbox" name="" id="" style={{ marginTop: "-10=2px" }} />
                                    </div>
                                </div>


                                <img src={HomeImg} alt="" style={{ width: "100%" }} />
                            </div>
                        </div>
                        <div style={{
                            background: "#414141",
                            borderRadius: "10px",
                            padding: "5px",
                            marginBottom: "1rem"
                        }}>
                            <div>
                                <div className="row">
                                    <div className="col-md-8" style={{ justifyContent: "start" }}>
                                        <p style={{ color: "white", width: "fit-content" }}>My Team</p>
                                    </div>
                                    <div className="col-md-4">
                                        <input type="checkbox" name="" id="" style={{ marginTop: "-10=2px" }} />
                                    </div>
                                </div>


                                <img src={HomeImg} alt="" style={{ width: "100%" }} />
                            </div>
                        </div>



                    </div>
                </div>
                <div className="col-md-8" style={{ alignContent: "start", marginTop: "1rem" }}>
                    <div>
                        {template}
                    </div>
                </div>
                <div className="col-md-2" style={{ alignContent: "start", marginTop: "1rem", padding: "0rem 2px" }}>
                    <div style={{ backgroundColor: "rgb(32 32 35)", borderRadius: "10px", minHeight: "80vh", padding: "1rem", maxHeight: "88vh", overflowY:"auto", width: "100%" }}>

                        <p style={{ fontSize: "16px" }}>Company Name</p>
                        <input
                            style={{ backgroundColor: "#434343", border: "0px", color: "#a8a8a8", marginBottom: "2rem", fontSize: "14px" }}
                            type="text"
                            className="form-control"
                            placeholder="Company Name"
                            value={data.header.text}
                            onChange={(e) => handleDataChange(['header', 'text'], e.target.value)}
                        />

                        {data.header.nav.map((navItem: any, index: number) => (
                            <div key={index}>
                                <p style={{ fontSize: "16px" }}>{`Nav Link ${index + 1} Text`}</p>
                                <input
                                    style={{ backgroundColor: "#434343", border: "0px", color: "#a8a8a8", marginBottom: "2rem", fontSize: "14px" }}
                                    type="text"
                                    className="form-control"
                                    placeholder={`Nav Link ${index + 1} Text`}
                                    value={navItem.text}
                                    onChange={(e) => handleDataChange(['header', 'nav', index+"", 'text'], e.target.value)}
                                />

                                {/* <p style={{ fontSize: "16px" }}>{`Nav Link ${index + 1} URL`}</p>
                                <input
                                    style={{ backgroundColor: "#434343", border: "0px", color: "#a8a8a8", marginBottom: "2rem", fontSize: "14px" }}
                                    type="text"
                                    className="form-control"
                                    placeholder={`Nav Link ${index + 1} URL`}
                                    value={navItem.link}
                                    onChange={(e) => handleDataChange(['header', 'nav', index+"", 'link'], e.target.value)}
                                /> */}
                            </div>
                        ))}

                        {/* <p style={{ fontSize: "16px" }}>Header Primary Color</p>
                        <input
                            style={{ backgroundColor: "#434343", border: "0px", color: "#a8a8a8", marginBottom: "2rem", fontSize: "14px" }}
                            type="color"
                            className="form-control"
                            value={data.styles[0].css.primary}
                            onChange={(e) => handleDataChange(['styles', "0", 'css', 'primary'], e.target.value)}
                        />

                        <p style={{ fontSize: "16px" }}>Header Text Color</p>
                        <input
                            style={{ backgroundColor: "#434343", border: "0px", color: "#a8a8a8", marginBottom: "2rem", fontSize: "14px" }}
                            type="color"
                            className="form-control"
                            value={data.styles[0].css.secondary}
                            onChange={(e) => handleDataChange(['styles', "0", 'css', 'secondary'], e.target.value)}
                        /> */}

                        <p style={{ fontSize: "16px" }}>Section 1 Text</p>
                        <input
                            style={{ backgroundColor: "#434343", border: "0px", color: "#a8a8a8", marginBottom: "2rem", fontSize: "14px" }}
                            type="text"
                            className="form-control"
                            placeholder="Section Text"
                            value={data.sec1.text}
                            onChange={(e) => handleDataChange(['sec1', 'text'], e.target.value)}
                        />

                        <p style={{ fontSize: "16px" }}>Section 1 Description</p>
                        <textarea
                            style={{ backgroundColor: "#434343", border: "0px", color: "#a8a8a8", marginBottom: "2rem", fontSize: "14px" }}
                            className="form-control"
                            placeholder="Section Description"
                            value={data.sec1.description}
                            onChange={(e) => handleDataChange(['sec1', 'description'], e.target.value)}
                        />

                        <p style={{ fontSize: "16px" }}>Action Button Text</p>
                        <input
                            style={{ backgroundColor: "#434343", border: "0px", color: "#a8a8a8", marginBottom: "2rem", fontSize: "14px" }}
                            type="text"
                            className="form-control"
                            placeholder="Action Text"
                            value={data.sec1.action.text}
                            onChange={(e) => handleDataChange(['sec1', 'action', 'text'], e.target.value)}
                        />

                        <p style={{ fontSize: "16px" }}>Action Button Link Address</p>
                        <input
                            style={{ backgroundColor: "#434343", border: "0px", color: "#a8a8a8", marginBottom: "2rem", fontSize: "14px" }}
                            type="text"
                            className="form-control"
                            placeholder="Action Button URL"
                            value={data.sec1.action.link}
                            onChange={(e) => handleDataChange(['sec1', 'action', 'link'], e.target.value)}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}
