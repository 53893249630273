/** @format */

import styled from "styled-components";

export const NewsletterContainer = styled.div`
  width: min(101.25rem, 95%);
  min-height: 25.125rem;
  margin: 5rem auto;
  border-radius: 0.9375rem;
  background-color: ${({ theme: { colors } }) => colors.primary};
  display: flex;
  .wrapper_container {
    height: 100%;
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    flex-direction: column;
    padding: 30px;
    .heading {
      font-family: Syncopate;
      font-size: 3rem;
      font-size: clamp(2rem, 3vw, 3rem);
      font-weight: 400;
      line-height: 4.4463rem;
      text-align: center;
      color: ${({ theme: { colors } }) => colors.white};
    }
    .subHeading {
      width: min(48rem, 95%);
      font-family: 'Cormorant Garamond';
      font-size: 1rem;
      font-weight: 200;
      line-height: 1.3181rem;
      text-align: center;
      color: ${({ theme: { colors } }) => colors.white};
    }
    .terms_condition {
      font-family: 'Cormorant Garamond';
      font-size: 1rem;
      font-weight: 200;
      line-height: 18px;
      text-align: center;
      color: ${({ theme: { colors } }) => colors.white};
    }
    .email_form {
      padding-inline: 0.45rem;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-wrap: wrap;
      gap: 0.625rem;
      min-height: 3.5625rem;
      width: 100%;
      .name_input_box {
        flex: 0.3;
        padding: 0.5rem 0.5rem 0.5rem 1.5rem;
        border-radius: 0.9375rem;
        background-color: ${({ theme: { colors } }) => colors.white};
        width: 100%;
        .input_box {
          outline: none;
          border: none;
          padding: 0.9375rem 1.25rem 0.9375rem 1.25rem;
          text-transform: capitalize;
        }
      }
      .email_input_box {
        flex: 0.7;
        width: 100%;
        padding: 0.5rem 0.5rem 0.5rem 1.5rem;
        border-radius: 0.9375rem;
        background-color: ${({ theme: { colors } }) => colors.white};
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-wrap: wrap;
        width: 100%;
        .input_box {
          flex: 0.8;
          width: 100%;
          outline: none;
          border: none;
          padding: 0.9375rem 1.25rem 0.9375rem 1.25rem;
        }
        .submitForm {
          flex: 0.2;
          width: 100%;
          height: 3.1875rem;
          padding: 0.9375rem 1.25rem 0.9375rem 1.25rem;
          gap: 0.625rem;
          border-radius: 0.625rem;
          background-color: ${({ theme: { colors } }) => colors.primary};
          font-family: Syncopate;
          font-size: 1.125rem;
          font-weight: 400;
          line-height: 1.3181rem;
          text-align: left;
          color: ${({ theme: { colors } }) => colors.white};
          text-align: center;
          border: none;
          outline: none;
          width: 100%;
        }
        @media screen and (max-width: 692px) {
          flex-direction: column;
          background-color: transparent;
          padding: 0;
          .input_box {
            background-color: ${({ theme: { colors } }) => colors.white};
            padding: 1.5rem 1.5rem 1.5rem 1.5rem;
            border-radius: 0.9375rem;
            margin-bottom: 1.5rem;
          }
          .submitForm {
            background-color: ${({ theme: { colors } }) => colors.white};
            color: ${({ theme: { colors } }) => colors.primary};
          }
        }
      }
      @media screen and (max-width: 692px) {
        flex-direction: column;
      }
    }
    @media screen and (max-width: 692px) {
      padding-block: 1.25rem;
      padding-inline: 0.9375rem;
    }
  }
`;
