/** @format */

import styled from 'styled-components';

export const Container = styled.div`
padding: 4rem 0;
 border-bottom: 2px solid #e1e1e1;
  h1 {
    color: #13131a;
    font-family: Inter;
    font-size: 1.25rem;
    font-weight: 500;
  }
  p {
    color: #414141;
    font-family: Inter;
    font-size: 1rem;
    font-weight: 400;
  }
`;
export const FeatureContainer = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 1.5rem;

  @media screen and (max-width:704px) {
    flex-direction: column;
  }
`;

export const Feature = styled.div`
  min-width: 20rem;
  margin: 2rem 0;
  h1 {
    color: ${props => props.theme.colors.primary} ;
    /* font-family: Inter; */
    font-size: 1rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
`;
