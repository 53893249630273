/** @format */

import { color } from 'framer-motion';
import { Link } from 'react-router-dom';
import Slider from 'react-slick';
import styled from 'styled-components';
import { transparentize } from 'polished';

export const Container = styled.div`
  margin-top: 2rem;
  display: grid;
  grid-template-columns: 70% minmax(0, 30%);
  gap: 1.5rem;
  @media screen and (max-width: 926px) {
    grid-template-columns: 100%;
  }
`;

export const LeftContainer = styled.div`
  position: relative;
  width: 100%;
`;

export const RightContainer = styled.div`
  align-self: flex-start;
  position: sticky;
  display: block;
  top: 80px;
  .ACard {
    width: inherit;
    padding: 1.5rem;
    margin-top: 1rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 1.2rem;
    background: #eaf3ff;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
    h1 {
      color: #212121;
      font-family: Inter;
      font-size: 0.875rem;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      margin: 0;
    }
    p {
      color: #575f6b;
      font-family: Inter;
      font-size: 0.8125rem;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      margin: 0;
    }
  }
  @media screen and (max-width: 922px) {
    display: none;
  }
`;
type OverViewProps = {
  Check: boolean;
  $descriptionSize:boolean
};
export const OverView = styled.div<OverViewProps>`
  padding-bottom: 3rem;
  padding-bottom: 0.95rem;
  border-bottom: 1px solid #d1d1d1;
  h1 {
    color: #13131a;
    font-family: Inter;
    font-size: 1.25rem;
    /* font-size: clamp(1rem, 1.25vw, 1.25rem); */
    font-weight: 600;
    line-height: 1.5125rem;
    text-align: left;
  }
  .InfoContainer {
    margin-top: 2rem;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    .Info {
      h1 {
        color: ${(props) => props.theme?.colors.primary};
        font-family: Inter;
        font-size: 0.875rem;
        /* font-size: clamp(0.75rem, 1.5vw, 1.5rem); */
        font-weight: 400;
      }
      h3 {
        color: #323232;
        font-family: Inter;
        font-size: 1rem;
        /* font-size: clamp(0.75rem, 1.5vw, 1.5rem); */
        font-weight: 400;
      }
    }
  }
  .Description {
    margin-top: 2rem;
    padding-left: 1rem;
    padding-bottom: 2rem;
    height: ${(props) => (props.Check && props.$descriptionSize ? '2.5rem' : '100%')};
    overflow: hidden;
    background: linear-gradient(
      180deg,
      hsla(0, 0%, 100%, 0),
      hsla(0, 0%, 85%, 0.2)
    );
  }
  .Wrapper {
    width: max-content;
    border-radius: 1rem;
    border: 1px solid #d1d1d1;
    padding: 0.25rem 1rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 1rem;
    margin: -1rem auto 0 auto;
    background-color:  ${(props) => props.theme?.colors.primary};
    color: white;
    .SvgWrapper {
      transform: rotate(${(props) => (props.Check ? '0deg' : '180deg')});
    }
    p {
      margin: 0;
      color:white;
    }
    &:hover {
      cursor: pointer;
    }
  }
`;

export const Map = styled.div`
  border-top: 2px solid #e1e1e1;
  border-bottom: 2px solid #e1e1e1;
  padding: 4rem 0;
  .MapContainer {
    display: flex;
    gap: 1.5rem;
    align-items: center;
  }
  h1 {
    color: #13131a;
    font-family: Inter;
    font-size: 1.25rem;
    font-weight: 500;
    line-height: 1.5125rem;
    text-align: left;
  }
  .leftSection {
    flex: 1;
    iframe {
      width: 100%;
      height: 100%;
      min-height: 15rem;
      border-radius: 0.625rem;
    }
  }
  .rightSection {
    text-align: center;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 1.5rem;
    flex: 1;
    .BulletPoints {
      .wrapper {
        margin: auto;
        width: 3rem;
        height: 3rem;
        background-color: #fff;
        border-radius: 50%;
        filter: drop-shadow(
          0px 2px 5px var(${(props) => props.theme?.colors.primary})
        );
        display: flex;
        justify-content: center;
        align-items: center;
      }
      p {
        margin: 0.75rem 0 0 0;
        color: ${(props) => props.theme?.colors.primary};
        text-align: center;
      }
    }
  }
  @media screen and (max-width: 1160px) {
    .MapContainer {
      flex-direction: column;
      .leftSection,
      .rightSection {
        width: 100%;
      }
    }
  }
`;

export const Amenities = styled.div`
  padding: 4rem 0;
  border-bottom: 2px solid #e1e1e1;
  h1 {
    color: #13131a;
    font-family: Inter;
    font-size: 1.25rem;
    font-weight: 500;
    margin-bottom: 2rem;
  }
  .AmenitiesContainer {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    align-items: center;
    gap: 1.5rem;
    .wrapper {
      margin: auto;
      width: 3rem;
      height: 3rem;
      background-color: #fff;
      border-radius: 50%;
      filter: drop-shadow(
        0px 2px 5px var(${(props) => props.theme?.colors.primary})
      );
      display: flex;
      justify-content: center;
      align-items: center;
    }
    p {
      margin: 0.75rem 0 0 0;
      color: ${(props) => props.theme?.colors.primary};
    }
  }
`;

export const PropertyDetails = styled.div`
  padding: 4rem 0;
  border-bottom: 2px solid #e1e1e1;
  h1 {
    color: #13131a;
    font-family: Inter;
    font-size: 1.25rem;
    font-weight: 500;
    margin-bottom: 0.875rem;
  }
  .Details {
    display: flex;
    justify-content: flex-start;
    align-items: baseline;
    gap: 1.5rem;
    .Wrapper {
      svg {
        circle {
          fill: ${(props) => props.theme?.colors.primary};
        }
      }
    }
    p {
      color: ${(props) => props.theme?.colors.primary};
    }
    span {
      color: #13131a;
      font-weight: 500;
    }
  }
`;
export const Appointment = styled.div`
  border-radius: 3.125rem 1.25rem 1.25rem 3rem;
  background: #fff;
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.1);
  padding: 1rem;
  display: flex;
  gap: 1.5rem;
  .submit_button {
    padding: 0.75rem 1rem 0.75rem 1rem;
    display: flex;
    align-items: center;
    gap: 0.625rem;
    border-radius: 0.9375rem;
    font-family: Inter;
    font-size: 1rem;
    font-weight: 600;
    line-height: 1.3613rem;
    text-align: center;
    border: none;
    outline: none;
    color: white;
    box-shadow: 2px 2px 4px 0px
      ${({ theme: { colors } }) =>
        colors?.primary ?transparentize(0.7, colors?.primary):colors.primary};
    background-color: ${({ theme: { colors } }) => colors.primary};
    margin: 0 auto;
    margin-bottom: 1rem;
  }
  &.mobileAnimation {
    display: none;
    background: none;
  }
  p {
    margin: 0;
    color: #212121;
    font-family: Inter;
    font-size: 0.875rem;
    font-weight: 500;
    margin-left: 1rem;
  }
  .animation {
    .wrapper {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: ${(props) => props.theme?.colors.primary};
      border-radius: 50%;
      padding: 0.3rem;
      &::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border: 10px solid ${(props) => props.theme?.colors.primary};
        border-radius: 50%;
        animation: wave 2s linear infinite;
      }
      &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border: 10px solid ${(props) => props.theme?.colors.primary};
        border-radius: 50%;
        animation: wave 1s linear infinite;
      }
      @keyframes wave {
        0% {
          transform: scale(1);
          opacity: 1;
        }
        100% {
          transform: scale(2);
          opacity: 0;
        }
      }
    }
  }
  /* &:hover {
    background: linear-gradient(
      271deg,
      ${(props) => props.theme?.colors.primary} 15.89%,
      rgba(0, 97, 223, 0) 98.9%
    );
    .content {
      color: #ffff;
    }
  } */
  @media screen and (max-width: 922px) {
    &.mobileAnimation {
      display: block;
      position: sticky;
      bottom: 3rem;
      width: fit-content;
      margin-left: auto;
      box-shadow: none;
    
    }
  }
`;

export const AvailableFloorPlans = styled.div`
  padding: 4rem 0;
  border-bottom: 2px solid #e1e1e1;
  h1 {
    color: #13131a;
    font-size: 1.25rem;
    font-family: Inter;
    font-weight: 500;
  }
  .FloorPlansContainer {
    display: flex;
    flex-direction: column;
  }
`;
export const AppointmentContainer = styled.div`
  height: 100vh;
  width: 100%;
  position: fixed;
  display: block;
  z-index: 10000;
  top: 0;
  left: 0;
  background: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0.4),
    rgba(0, 0, 0, 0.4)
  );
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const DepositePremium = styled.div`
  background-color: #eaf3ff;
  border-radius: 0.52rem;
  padding: 1rem;
  margin-top: 0.75rem;
  .premium_section {
    h4 {
      text-align: center;
      color: ${(props) => props.theme?.colors.primary};
    }
    p {
      color: ${(props) => props.theme?.colors.primary};
    }
  }
`;
export const MobileDepositePremium = styled.div`
  border-radius: 0.52rem;
  padding: 1rem;
  margin: 0.55rem 0;
  .premium_section {
    h4 {
      color: ${(props) => props.theme?.colors.primary};
    }
  }
  /* @media screen and (max-width: 922px) {
    display: block;
  } */
`;
export const DepositeStructure = styled.div`
  /* padding: 4rem 0; */
  /* border-bottom: 2px solid #e1e1e1; */
  h2 {
    font-family: Inter;
    font-size: 1.25rem;
  }
  p {
    color: ${(props) => props.theme?.colors.primary};
  }
  .DepositeStructure {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    p {
      color: ${(props) => props.theme?.colors.primary};
    }
  }
`;

export const NavContainer = styled(Slider)`
  position: sticky;
  top: 100px;
  display: flex;
  gap: 0.25rem;
  width: 100%;
  /* width:min(67.5rem,100%) ; */
  /* overflow-x:auto; */
`;

type TabProps = {
  $active?: boolean;
};

export const Tab = styled.a<TabProps>`
  min-width: 150.86px;
  padding: 0.625rem 0rem 0.625rem 0rem;
  gap: 0.625rem;
  border-radius: 0.25rem;
  background: #ededed;
  cursor: pointer;
  text-decoration: none;
  background-color: ${({ $active, theme: { colors } }) =>
    $active && colors.primary};
  .svg_wrapper {
    text-align: center;
    svg {
      path {
        fill: ${({ $active }) => $active && '#FFFFFF'};
      }
      &.no_fill {
        path {
          stroke: ${({ $active }) => $active && '#FFFFFF'};
          fill: none;
        }
      }
    }
  }
  .sub_heading {
    font-family: Inter;
    font-size: 1rem;
    font-weight: 500;
    line-height: 1.5rem;
    color: #323232;
    text-align: center;
    margin: 0;
    color: ${({ $active }) => $active && '#FFFFFF'};
  }
  &:hover {
    .svg_wrapper {
      svg {
        path {
          fill: ${({ $active, theme: { colors } }) =>
            !$active && colors.primary};
        }
        &.no_fill {
          path {
            stroke: ${({ $active, theme: { colors } }) =>
              !$active && colors.primary};
            fill: none;
          }
        }
      }
    }
    .sub_heading {
      color: ${({ $active, theme: { colors } }) => !$active && colors.primary};
    }
  }
`;

export const DropDown = styled.div`
  margin-block: 1rem;
  border-bottom: 2px solid #e1e1e1;
  padding-block: 1.5rem;
  cursor: pointer;
  .drop_down_up_section {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .heading {
      font-family: Inter;
      font-size: 1.25rem;
      font-weight: 500;
      line-height: 1.5125rem;
      text-align: left;
      color: #13131a;
      text-transform: capitalize;
      margin: 0;
    }
  }
`;
