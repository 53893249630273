import { ReactNode, createContext, useContext, useReducer } from 'react';

export type formType = 'add' | 'preview' | null;

export type State = {
    blogs:[];
    formVisualState:boolean;
    formType:formType;
}

type ContextValue = {
    state:State;
    setProperty: (value:[]) => void;
}

const initialState:State ={
    blogs: [],
    formVisualState:false,
    formType:null,
}
type Action =
    | {
        type: 'set_property';
        payload: {
            value:[];
        };
    }
const DashboardBlogsReducer = (state:State,action:Action)=>{
    switch(action.type){
        case 'set_property':
          return {...state,blogs:action.payload.value}
    }
}

const DashboardBlogsContext = createContext<ContextValue | undefined>(undefined)

interface DashboardContextProviderProps {
    children:ReactNode;
}

const DashboardBlogsContextProvider = ({
    children,
}: DashboardContextProviderProps)=>{
    const [state, dispatch] = useReducer(DashboardBlogsReducer,initialState);

    const setProperty = (value:[]) => {
        dispatch({ type: 'set_property', payload: { value } });
    };

    const contextValue:ContextValue = {
        state,
        setProperty,
    }
    return (
       <DashboardBlogsContext.Provider value={contextValue}>
        {children}
       </DashboardBlogsContext.Provider>
    )
}

//customHook 

export function useDashboardBlogs(){
    const context = useContext(DashboardBlogsContext);
    if(!context){
        throw new Error(
            `DashboardBlogsContext must be used in withing a DashBoardBlogsContextProvider`
        )
    }
    return context
}

export default DashboardBlogsContextProvider