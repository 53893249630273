/** @format */

import axios from 'axios';
import Cookies from 'js-cookie';
import { useEffect, useState } from 'react';
import BlogCardT3 from '../Components/blogcard/blogcard.component';
import HeroBlogContainer from '../Components/hero_blog_container/hero_blog_container.component';
import { Container } from './Blogs.style';
export default function BlogsT3() {
  const [data, setData] = useState([]);
  useEffect(() => {
    const FetchData = async () => {
      const token = Cookies.get('token');
      let config = {
        headers: {
          'x-access-token': token,
        },
      };
      try {
        const fetchData = await axios.get('/admin/blog?sortOrder=desc', config);
        const data = await fetchData.data.data;
        setData(data);
      } catch (e) {
        throw new Error(e);
      }
    };
    FetchData();
  }, []);
  return (
    <>
      <HeroBlogContainer data={data} />
      <Container>
        {data.map((data) => (
          <BlogCardT3
            data={data}
            key={data.id}
          />
        ))}
      </Container>
    </>
  );
}
