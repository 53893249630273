/** @format */

import { useEffect, useState } from 'react';
import { BlogSliderContainer } from './blog_slider.style';

import BlogCardT3 from '../blogcard/blogcard.component';

import axios from 'axios';
import Cookies from 'js-cookie';
import Slider from 'react-slick';
export default function BlogSlider() {
  const [data, setData] = useState([]);
  const BlogSliderSettings = {
    arrows: false,
    infinite: true,
    speed: 500,
    slidesToShow: Math.min(data.length, 4),
    slidesToScroll: 1,
    autoplay: true,
    responsive: [
      {
        breakpoint: 1384,
        settings: {
          slidesToShow: Math.min(data.length, 4),
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1122,
        settings: {
          slidesToShow: Math.min(data.length, 2),
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 800,
        settings: {
          slidesToShow: Math.min(data.length, 1),
          slidesToScroll: 1,
        },
      },
    ],
  };
  useEffect(() => {
    const FetchData = async () => {
      const token = Cookies.get('token');
      const agentUrl = window.location.href.split('/')[2];
      //const agentUrl = "pranavjadhav.agentshades.com";
      let config = {
        headers: {
          'x-access-token': token,
        },
        params: {
          domain: agentUrl,
        },
      };
      try {
        const fetchData = await axios.get('/api/get-agent-blog', config);
        const data = await fetchData.data.data;
        setData(data);
      } catch (e) {
        throw new Error(e);
      }
    };
    FetchData();
  }, []);
  return (
    <BlogSliderContainer>
      <div className="header_container">
        <p className="heading">Blogs</p>
        <p className="sub_heading">Check out our latest posts</p>
      </div>
      <Slider {...BlogSliderSettings}>
        {data.map((data) => (
          <BlogCardT3
            data={data}
            key={data.title}
          />
        ))}
      </Slider>
    </BlogSliderContainer>
  );
}
