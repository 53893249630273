/** @format */

import styled from 'styled-components';
import { Link } from 'react-router-dom';
export const Container = styled.div`
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  .main_container_not_found {
    width: min(29.375rem, 95%);
    margin: 0 auto;
    .not_found_numbers {
      display: flex;
      justify-content: center;
      align-items: center;
      p {
        font-family: ${({ theme: { fonts } }) => fonts.primary_font};
        color: ${({ theme: { colors } }) => colors.primary};
        font-size: 8rem;
        font-weight: ${({
          theme: {
            not_found_page: { warning_number },
          },
        }) => warning_number.weight};
        line-height: 133.25px;
        text-align: center;
        -webkit-text-stroke-width: ${({
          theme: {
            not_found_page: { warning_number },
          },
        }) => warning_number.stroke}px;
        -webkit-text-stroke-color: white;
        margin: 0;
      }
      .first_number {
        position: relative;
        z-index: 0;
      }
      .second_number {
        position: relative;
        z-index: 1;
        margin-left: ${({
          theme: {
            not_found_page: { warning_number },
          },
        }) => warning_number.second_letter}px;
      }
      .third_number {
        position: relative;
        z-index: 2;
        margin-left: ${({
          theme: {
            not_found_page: { warning_number },
          },
        }) => warning_number.third_letter}px;
      }
    }
    .message_container {
      .message {
        font-family: ${({ theme: { fonts } }) => fonts.secondary_font};
        font-size: 24px;
        font-weight: 400;
        line-height: 27px;
        text-align: center;
      }
    }
  }
`;

export const HomeLink = styled(Link)`
  display: block;
  padding: 1rem 2rem 1rem 2rem;
  gap: 0.625rem;
  background-color: ${({ theme: { colors } }) => colors.primary};
  text-decoration: none;
  color: white;
  font-family: ${({ theme: { fonts } }) => fonts.primary_font};
  font-size: 1.25rem;
  font-weight: 400;
  line-height: 1.3013rem;
  text-align: left;
  margin: 0 auto;
  width: fit-content;
  margin-top: 2rem;
  border-radius: ${({
    theme: {
      not_found_page: { button },
    },
  }) => button.border_radius}rem;
`;
