/** @format */

import { useEffect, useState } from 'react';

import axios from 'axios';
import Cookies from 'js-cookie';
import Header from '../Images/header_blog_icon.svg';
import './Preview.css';

export default function Preview({ setPreview, _id }) {
  const [data, setData] = useState(null);
  //getBlog
  useEffect(() => {
    const handleGetBlog = async () => {
      const token = Cookies.get('token');
      try {
        const Fetchdata = await axios.get(`/admin/blog/${_id}`, {
          headers: {
            'x-access-token': token,
          },
        });
        setData(Fetchdata.data.data);
      } catch (err) {
        console.error(err);
      }
    };
    handleGetBlog();
  });

  return (
    <div
      className="preiview_container"
      onClick={(event) => event.stopPropagation()}>
      <nav className=" preview_hader d-flex justify-content-between align-items-center">
        <p>Blog Preview</p>
        <div
          className="close_blog"
          onClick={() => setPreview('idle')}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="14"
            height="14"
            viewBox="0 0 14 14"
            fill="none">
            <path
              d="M13.6464 1.76361C13.8417 1.56835 13.8417 1.25177 13.6464 1.05651L12.9436 0.353614C12.7483 0.158352 12.4317 0.158352 12.2364 0.353614L7.35355 5.23651C7.15829 5.43177 6.84171 5.43177 6.64645 5.23651L1.76355 0.353614C1.56829 0.158352 1.25171 0.158352 1.05645 0.353614L0.353553 1.05651C0.158291 1.25177 0.158291 1.56835 0.353553 1.76361L5.23645 6.64651C5.43171 6.84177 5.43171 7.15835 5.23645 7.35361L0.353553 12.2365C0.158291 12.4318 0.158291 12.7484 0.353553 12.9436L1.05645 13.6465C1.25171 13.8418 1.56829 13.8418 1.76355 13.6465L6.64645 8.76361C6.84171 8.56835 7.15829 8.56835 7.35355 8.76361L12.2364 13.6465C12.4317 13.8418 12.7483 13.8418 12.9436 13.6465L13.6464 12.9436C13.8417 12.7484 13.8417 12.4318 13.6464 12.2365L8.76355 7.35361C8.56829 7.15835 8.56829 6.84177 8.76355 6.64651L13.6464 1.76361Z"
              fill="#999999"
            />
          </svg>
        </div>
      </nav>
      <div className="blog_preview">
        <nav className="d-flex justify-content-between align-items-center">
          <img
            src={Header}
            alt="header"
          />
          <div className="nav_action_dummy d-flex gap-3 align-items-center">
            <p>About us</p>
            <p>Search homes</p>
            <p>Tools</p>
            <p>Contact us</p>
            <div className="wrapper">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="22"
                height="22"
                viewBox="0 0 22 22"
                fill="none">
                <path
                  d="M3.02637 3.56198H18.9326V5.32934H3.02637V3.56198ZM3.02637 9.74775H18.9326V11.5151H3.02637V9.74775ZM3.02637 15.9335H18.9326V17.7009H3.02637V15.9335Z"
                  fill="black"
                />
              </svg>
            </div>
          </div>
        </nav>
        <div className="blog_content_preview">
          <h1>{data?.title}</h1>

          <div className="blog_image_container">
            <img
              src={`/public/${data?.coverImage}`}
              alt="BlogImage"
            />
          </div>
          <div
            className="blog_preivew_content"
            dangerouslySetInnerHTML={{ __html: data?.description }}></div>
        </div>
      </div>
    </div>
  );
}
