/** @format */

import Slider from 'react-slick';

import { Container } from '../../Style/CommonStyle.t3';
import styled from 'styled-components';

export const BlogSliderContainer = styled(Container)`
  width: min(110rem, 95%);
  margin-top: 6rem;
  margin-bottom: 3rem;
  .header_container {
    .heading { 
      display: block;
      font-family: Syncopate;
      font-size: clamp(1.5rem, 1rem + 2vw, 3rem);
      font-weight: 400;
      line-height: 3rem;
      text-align: left;
      color: ${({ theme: { colors } }) => colors.primary};
    }
    .sub_heading {
      font-family: 'Cormorant Garamond';
      font-size: 1.125rem;
      font-weight: 300;
      line-height: 1.3181rem;
      text-align: left;
      padding-left: 15px;
       color: ${({ theme: { colors } }) => colors.primary};
    }
  }
  .slick-slide {
    margin: 0 auto;
  }
  .blog_container {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1rem;
  }
`;
