import { PROPERTY_TYPE_CLASSES } from "./PropertyCard.utilComponent"

export const CoverImages = (images,type,cardType)=>{
    if (cardType === PROPERTY_TYPE_CLASSES.propertycard_temp1 || cardType === PROPERTY_TYPE_CLASSES.propertycard_temp2 ){
         if(type === 'builder_deals'){
             return [`https://redbuyers.com${images[0].file_url}`]
         }
        return [images[0]]
    }else if(cardType === PROPERTY_TYPE_CLASSES.propertycard_temp3){
        if (type === 'builder_deals'){
             return images.slice(0, 3).map((img) => `https://redbuyers.com${img.file_url}`)
         }else{
            return images.slice(0, 3)
         }
    }
    return []
}