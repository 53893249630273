import React from 'react'
import PropertyCard from '../../PropertyCard/PropertyCard.utilComponent'
import { ImageConverter } from '../../idxView/idxhelperfunctions'
import ConvertToCanadianNumber from '../../../../utils/New_construction_details/ConvertNumberToDollar.util'
import ReactPaginate from 'react-paginate'

export default function PropertyColumn({property,handlePageChange,currentPage,lastPage,cardType}) {
  return (
    <>
              <div className="idxContainer">
            {property &&
              property.map((data) => (
                <PropertyCard
                  propertyCardType={cardType}
                  propertyType={'idx'}
                  propertyData={{
                    id: data.unique_id.toString(),
                    img: ImageConverter(data.photos, 'idx'),
                    price: ConvertToCanadianNumber(data.list_price),
                    title: `${data.address} ${data.city}`,
                    bedrooms: data.bedrooms?.toString(),
                    bathrooms: data.bathrooms?.toString(),
                    garage: data?.garage?.toString(),
                    sale_or_lease: data.sale_or_lease,
                    redirect: `idxView`,
                  }}
                  key={data.unique_id}
                />
              ))}
          </div>
          <ReactPaginate
            containerClassName="peginate_class"
            pageClassName="page"
            onPageChange={handlePageChange}
            forcePage={currentPage - 1}
            pageCount={lastPage}
          /></>
  )
}
