export function PropertySetImageBanner(Data) {
    const homeType = Data?.home_type;
    let planProperties = []
    if (Data?.plan_properties) {
        planProperties = Data?.plan_properties[0]?.property_cover_image;
    }

    const elevationImage = Data?.property_elevation_image;
    const properties = ["Detached", "Semi-detached", "Town House"]

    if (!properties.includes(homeType) && planProperties?.length) {
        return (planProperties)
    } else if (properties.includes(homeType) && elevationImage?.length) {
        return(elevationImage)
    } else {
        return([{ file_url: "/assets/images/no-image-available.png" }])
    }
}