/** @format */

import SeoMannagerHeader from './SeoHeader/SeoMannagerHeader';
import SeoListting from './SeoListting/SeoListting';
import './Seomanager.css';
export default function Seomanager() {
  return (
    <div className="Container">
      <SeoMannagerHeader />
      <div className="seo_mannager">
        <div className="seo_mannager_header">
          <p
            className="heading"
            style={{ marginLeft: '15px' }}>
            url
          </p>
          <p className="heading bar">title</p>
          <p className="heading bar">Description</p>
          <p className="heading bar">Keywords</p>
          <p className="heading bar">Status</p>
        </div>
        <div className="seo_mannager_list">
          <SeoListting active={true} />
          <SeoListting active={false} />
          <SeoListting active={true} />
          <SeoListting active={true} />
          <SeoListting active={true} />
          <SeoListting active={true} />
          <SeoListting active={true} />
          <SeoListting active={true} />
          <SeoListting active={true} />
          <SeoListting active={true} />
          <SeoListting active={true} />
          <SeoListting active={true} />
          <SeoListting active={true} />
          <SeoListting active={true} />
          <SeoListting active={true} />
          <SeoListting active={true} />
          <SeoListting active={true} />
          <SeoListting active={true} />
          <SeoListting active={true} />
          <SeoListting active={true} />
          <SeoListting active={true} />
          <SeoListting active={true} />
          <SeoListting active={true} />
          <SeoListting active={true} />
        </div>
      </div>
    </div>
  );
}
