/** @format */

import styled from 'styled-components';

export const NewProject = styled.div`
  h1 {
    font-family: 'Syncopate';
    font-size: clamp(2rem, 3vw, 3rem);
    text-align: center;
    text-transform: capitalize;
  }
`;

export const OurTeamContainer = styled.div`
 margin-top: 3rem;
  h1 {
    text-transform: uppercase;
    color: ${(props) => props.theme?.colors?.text2};
    text-align: center;
    margin-bottom: 3rem;
    font-family: 'Syncopate';
  }
`;
