/** @format */

import { createContext, useReducer, ReactNode, useContext } from "react";

export type State = {
  ErrorShow: boolean;
  SuccessShow: boolean;
  Message: string;
  theme: object;
};

type Action =
  | {
      type: "set_error_show";
      payload: { value: boolean };
    }
  | { type: "set_success_show"; payload: { value: boolean } }
  | { type: "set_message"; payload: { value: string } }
  | { type: "set_theme"; payload: { value: object } };

type ContextValue = {
  state: State;
  setErrorShow: (value: boolean) => void;
  setSuccessShow: (value: boolean) => void;
  setMessage: (value: string) => void;
  setTheme: (value: object) => void;
};

const initialState: State = {
  ErrorShow: false,
  SuccessShow: false,
  Message: "Something Went Wrong",
  theme: {
    primary_color: "black",
    secondary_color: "white",
    borderRadius: "16px",
    warning_message: {
      fontFamily: "Syncopate",
      fontSize: "20px",
      fontWeight: "700",
      lineHeight: "20.82px",
    },
    warning_text: {
      fontFamily: "Garamond",
      fontSize: "18px",
      fontWeight: "400",
      lineHeight: "22.32px",
      textAlign: "center",
    },
    button: {
      fontFamily: "Syncopate",
      fontSize: "18px",
      fontWeight: "700",
      lineHeight: "18.74px",
    },
  },
};

const MessageBoxReducer = (state: State, action: Action): State => {
  switch (action.type) {
    case "set_error_show":
      return { ...state, ErrorShow: action.payload.value };
    case "set_message":
      return { ...state, Message: action.payload.value };
    case "set_success_show":
      return { ...state, SuccessShow: action.payload.value };
    case "set_theme":
      return { ...state, theme: action.payload.value };
  }
};
const MessageBoxContext = createContext<ContextValue | undefined>(undefined);
type ErrorProviderProps = {
  children: ReactNode;
};

const MessageBoxContextProvider = ({ children }: ErrorProviderProps) => {
  const [state, dispatch] = useReducer(MessageBoxReducer, initialState);
  const setErrorShow = (value: boolean) => {
    dispatch({ type: "set_error_show", payload: { value } });
  };
  const setMessage = (value: string) => {
    dispatch({ type: "set_message", payload: { value } });
  };
  const setSuccessShow = (value: boolean) => {
    dispatch({ type: "set_success_show", payload: { value } });
  };
  const setTheme = (value: object) => {
    dispatch({ type: "set_theme", payload: { value } });
  };
  const contextValue: ContextValue = {
    state,
    setErrorShow,
    setSuccessShow,
    setMessage,
    setTheme,
  };
  return (
    <MessageBoxContext.Provider value={contextValue}>
      {children}
    </MessageBoxContext.Provider>
  );
};

//custom Hook

export function useMessageContext() {
  const context = useContext(MessageBoxContext);
  if (!context) {
    throw new Error(
      "MessageContext must be used within a ErrorContextProvider",
    );
  }
  return context;
}

export default MessageBoxContextProvider;
