import axios from 'axios';
import Cookies from 'js-cookie';
import { useEffect, useState } from 'react';
import { BlogContainer, Container } from './blog_section.style';
import BlogCard from './components/BlogCards/Blog_card.component';
export default function BlogList() {
    const [data, setData] = useState([]);
    useEffect(() => {
        const FetchData = async () => {
            const token = Cookies.get('token');
            let config = {
                headers: {
                    'x-access-token': token,
                },
            };
            try {
                const fetchData = await axios.get('/admin/blog?sortOrder=desc', config);
                const data = await fetchData.data.data;
                setData(data);
            } catch (e) {
                throw new Error(e);
            }
        };
        FetchData();
    }, []);
  return (
    <Container>
        <h1>Blogs</h1>
          <BlogContainer>
            {data.map((data)=>
             <BlogCard data={data} key={data.id} />
            )}
          </BlogContainer>
    </Container>
  )
}
