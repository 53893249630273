/** @format */

import styled from 'styled-components';

export const ContactUsContainer = styled.div`
  width: min(90rem, 95%);
  margin-block: 7rem;
  /*max-height: 50rem;*/
  display: flex;
  justify-content: space-between;
  /* align-items: center; */
  background: ${({ theme: { colors } }) => colors.text};
  border-radius: 1.25rem;
  padding: 1.7rem;
  padding-inline: clamp(0.8rem, 1.7vw, 1.7rem);
  flex-wrap: wrap;
  gap: 1rem;
  position: relative;
  gap: 5rem;
  .dots {
    position: absolute;
    top: -10%;
    z-index: 100;
    left: 0;
    rect {
      fill: ${({ theme: { colors } }) => colors.primary};
    }
  }
  .circle_svg {
    position: absolute;
    top: 20%;
    right: -10%;
    z-index: -1;
    path {
      fill: ${({ theme: { colors } }) => colors.primary};
    }
  }
  .lef_contact_us_section {
    /* width: min(30.5rem, 95%);
    min-height: 22.5rem; */
    flex: 0.4;
    .image_container_contact_us {
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      height: 100%;
      .Image_not_found {
        width: 2.8125rem;
        height: 2.8125rem;
      }
      .contact_us_image {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        transform: scale(1.2);
        border-radius: 1.5625rem;
        aspect-ratio: 3/4;
      }
    }
  }
  .right_contact_us_section {
    flex: 0.6;
    .header_container {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      justify-content: flex-start;
      gap: 1rem;
      .header {
        font-family: Syncopate;
        font-size: 48px;
        font-weight: 400;
        line-height: 71.14px;
        text-align: left;
        color: ${({ theme: { colors } }) => colors.primary};
      }
      .sub_header {
        width: min(373px, 95%);
        font-family: Syncopate;
        font-size: 18px;
        font-weight: 200;
        line-height: 21.09px;
        text-align: left;
        margin: 0;
        color: ${({ theme: { colors } }) => colors.TextGrey};
      }
    }
    .agent_info_container {
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-wrap: wrap;
      .number,
      .email,
      .address {
        padding: 1.25rem;
        border-radius: 0.625rem;
        color: ${({ theme: { colors } }) => colors.primary};
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 0.45rem;
        font-family: Syncopate;
        cursor: pointer;
        &:hover {
          background-color: white;
        }
        .svg_wrapper {
          path {
            fill: ${({ theme: { colors } }) => colors.primary};
          }
        }
      }
      .address_text {
        padding: 1.25rem;
        border-radius: 0.625rem;
        color: ${({ theme: { colors } }) => colors.primary};
        /* display: flex;
        align-items: center;
        justify-content: center; */
        gap: 0.45rem;
        font-family: Syncopate;
        letter-spacing: 0;
        text-transform: none;
        margin-bottom: 0;
        cursor: pointer;
        &:hover {
          background-color: white;
        }
        .svg_wrapper {
          path {
            fill: ${({ theme: { colors } }) => colors.primary};
          }
        }
      }
      .address_text p {
        border-radius: 0.625rem;
        color: ${({ theme: { colors } }) => colors.primary};
        font-family: Syncopate;
        letter-spacing: 0;
        text-transform: none;
        margin-bottom: 0;
        cursor: pointer;
        &:hover {
          background-color: white;
        }
        .svg_wrapper {
          path {
            fill: ${({ theme: { colors } }) => colors.primary};
          }
        }
      }
    }
    .contact_us_form {
      box-shadow: 0rem 0rem 0.625rem 0rem #2c36474d;
      padding-block: 2.5rem;
      padding-inline: clamp(0.9rem, 2vw, 2.5rem);
      border-radius: 1.5625rem;
      background-color: white;
      .name_email {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 0.7rem;
        flex-wrap: wrap;
        .name,
        .email {
          flex: 1;
        }
        .input_box_container {
          display: flex;
          align-items: center;
          gap: 16px;
          padding: 0.9375rem;
          border-radius: 0.625rem;
          border: 0.125rem solid #f3f3f4;
          background: var(--Light-Grey, #f3f3f4);
          .svg_wrapper {
            path {
              fill: ${({ theme: { colors } }) => colors.primary};
            }
          }
          .input_box {
            outline: none;
            border: none;
            background-color: transparent;
          }
          &:hover {
            outline: 1px solid ${({ theme: { colors } }) => colors.primary};
          }
        }
        label {
          font-family: Inter;
          font-size: 1rem;
          font-weight: 400;
          line-height: 1.5rem;
          text-align: left;
        }
      }
      .message {
        margin-top: 1rem;
        .text_box {
          display: block;
          width: 100%;
          height: 100%;
          background: var(--Light-Grey, #f3f3f4);
          padding: 0.9375rem 1.25rem 0.9375rem 1.25rem;
          border-radius: 0.625rem;
          height: 11.25rem;
          overflow-y: auto;
          border: none;
          outline: none;
          &:hover {
            outline: 1px solid ${({ theme: { colors } }) => colors.primary};
          }
        }
      }
      .link_send_message {
        display: flex;
        justify-content: space-between;
        margin-top: 0.75rem;
        .Link_container {
          display: flex;
          gap: 0.85rem;
          a {
            background: ${({ theme: { colors } }) => colors.text};
            width: 4rem;
            height: 4rem;
            border-radius: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            .svg_wrapper {
              path {
                fill: ${({ theme: { colors } }) => colors.primary};
              }
            }
            &:hover {
              background-color: ${({ theme: { colors } }) => colors.primary};
              .svg_wrapper {
                path {
                  fill: ${({ theme: { colors } }) => colors.white};
                }
              }
            }
          }
        }
        .submit_button {
          padding: 0px 20px 0px 20px;
          border-radius: 10px;
          color: white;
          background-color: ${({ theme: { colors } }) => colors.primary};
          font-family: Syncopate;
          font-size: 1.125rem;
          font-weight: 400;
          line-height: 1.3181rem;
        }
        @media screen and (max-width: 552px) {
          flex-direction: column-reverse;
          .submit_button {
            padding: 15px 20px 15px 20px;
            margin-bottom: 1rem;
          }
          .Link_container {
            justify-content: space-between;
          }
        }
      }
    }
  }
  @media screen and (max-width: 1072px) {
    flex-direction: column;
    .lef_contact_us_section {
      width: 100%;
      height: 100%;
      min-height: 55vh;
      position: relative;
      .image_container_contact_us {
        position: absolute;
        height: 100%;
        width: 100%;
        .contact_us_image {
          transform: scale(1);
          height: 100%;
          width: 100%;
        }
      }
    }
    .right_contact_us_section {
      width: 100%;
      left: 100%;
    }
  }
`;

export const Container = styled.div`
  margin: auto;
  /*overflow: hidden;
  height: 92vh;*/
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  max-height: 100%;
  overflow: hidden;
`;

export const ErrorBox = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  .wrap {
    padding: 1rem;
    background: white;
    border-radius: 0.75rem;
    width: min(30rem, 95%);
    height: 15rem;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    .icon_wrapper {
      position: absolute;
      width: 1.5rem;
      top: 5%;
      right: 5%;
      cursor: pointer;
    }
    p {
      font-family: Inter;
      font-size: 1.5rem;
      font-weight: 400;
      line-height: 1.5rem;
      text-align: left;
      margin-top: 2rem;
    }
  }
`;
