/** @format */

import Cookies from 'js-cookie';
import { HTMLAttributes, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import ImageNotFound from '../../../images/image-not-found-icon.svg';
import usePermissionSoldProperty from '../../CustomHooks/usePermissionSoldProperty.customHook';
import SoldHomeForm from '../SoldHomeForm/SoldHomeForm.component';
import {
  BuilderPropertyCompany,
  PropertyInfoContainer,
  PropertyInformation,
  SliderContainer,
  SoldPropertyError,
  SoldPropertyInfoContainer,
} from './PropertyCard.commonstyle';
import { faLock } from '@fortawesome/free-solid-svg-icons';

import {
  BasePropertyCard,
  PropertyCardOne,
  PropertyCardThree,
  PropertyCardTwo,
} from './PropertyCard.style';
import {
  CustomNextArrow,
  CustomPrevArrow,
  handleNext,
  handlePrevious,
} from './arrows.component';
import { CoverImages } from './helperFunctions';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
export enum PROPERTY_TYPE_CLASSES {
  propertycard = 'propertycard',
  propertycard_temp1 = 'property_temp1',
  propertycard_temp2 = 'propertycard_temp2',
  propertycard_temp3 = 'propertycard_temp3',
}

const getPropertyCard = (
  propertyType = PROPERTY_TYPE_CLASSES.propertycard
): typeof BasePropertyCard =>
  ({
    [PROPERTY_TYPE_CLASSES.propertycard]: BasePropertyCard,
    [PROPERTY_TYPE_CLASSES.propertycard_temp1]: PropertyCardOne,
    [PROPERTY_TYPE_CLASSES.propertycard_temp2]: PropertyCardTwo,
    [PROPERTY_TYPE_CLASSES.propertycard_temp3]: PropertyCardThree,
  }[propertyType]);

type PropertyCardProps = {
  propertyCardType?: PROPERTY_TYPE_CLASSES;
  propertyType: 'idx' | 'sold' | 'builder_deals' | 'recently_added';
  propertyData: PropertyData;
} & HTMLAttributes<HTMLDivElement>;

type PropertyData = {
  img: [];
  price: String;
  garage: String;
  bathrooms: String;
  bedrooms: String;
  title: string;
  id: string;
  redirect: string;
  sale_or_lease: string;
  plan?: string;
  parent_property?: string;
  company?: string;
  property_sqft?: string;
  listed_price?: string;
  sold_on?: string;
  sold_in?: string;
  timeLabel?: string;
};

export default function PropertyCard({
  propertyCardType,
  propertyType,
  propertyData,
  ...otherProps
}: PropertyCardProps) {
  const CustomPropertyCard = getPropertyCard(propertyCardType);
  const sliderRef = useRef(null);
  const Navigate = useNavigate();
  const OnBody = [
    PROPERTY_TYPE_CLASSES.propertycard_temp1,
    PROPERTY_TYPE_CLASSES.propertycard_temp2,
    PROPERTY_TYPE_CLASSES.propertycard_temp3,
  ];
  const {
    user,
    open,
    handleOpenClose,
    handleNavigation,
    handleSubmitForm,
    handleState,
  } = usePermissionSoldProperty();
  const handleOnBodyClick = (id: string) => {
    if (OnBody.includes(propertyCardType)) {
      Navigate(`${propertyData.redirect}/${id}`);
    }
  };

  const FeatureSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    prevArrow: <CustomPrevArrow onClick={() => handlePrevious(sliderRef)} />,
    nextArrow: <CustomNextArrow onClick={() => handleNext(sliderRef)} />,
  };
  const NavigationHandler = () => {
    if (propertyType === 'idx') {
      handleOnBodyClick(propertyData.id);
    } else if (propertyType === 'sold') {
      if (!user) {
        handleNavigation();
      } else {
        handleOnBodyClick(propertyData.id);
      }
    }
  };
  return (
    <>
      {propertyType === 'builder_deals' ? (
        <CustomPropertyCard
          {...otherProps}
          $property_type={propertyType}
          onClick={() => handleOnBodyClick(propertyData.id)}>
          <div>
            <SliderContainer
              {...FeatureSettings}
              ref={sliderRef}>
              {propertyData?.img?.length > 0 ? (
                CoverImages(
                  propertyData.img,
                  propertyType,
                  propertyCardType
                ).map((img: string) => (
                  <div
                    className="home_Images"
                    key={img}>
                    <p className="status_info">{propertyData.sale_or_lease}</p>
                    <img
                      src={img}
                      alt="home_image"
                    />
                  </div>
                ))
              ) : (
                <div className="home_Images">
                  <img
                    src={ImageNotFound}
                    alt="home_image"
                  />
                </div>
              )}
            </SliderContainer>

            <BuilderPropertyCompany>
              <div className="left_section">
                <p className="Heading">SQFT</p>
                <p className="SubHeading">
                  {propertyData.property_sqft || 'N/A'}
                </p>
              </div>
              <div className="divider"></div>
              <div className="right_section">
                {/* <p className="Heading">{propertyData.parent_property}</p>*/}
                <p className="builder_price">${propertyData.price}</p>
              </div>
            </BuilderPropertyCompany>
          </div>
          <PropertyInfoContainer>
            <div className="bed">
              <svg
                width="28"
                height="19"
                viewBox="0 0 28 19"
                fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M22.75 2.75H15.75C14.0931 2.75 12.75 4.09315 12.75 5.75V8.5C12.75 10.1569 11.4069 11.5 9.75 11.5H5.75C4.09315 11.5 2.75 10.1569 2.75 8.5V1.5C2.75 0.809644 2.19036 0.25 1.5 0.25C0.809644 0.25 0.25 0.809644 0.25 1.5V17.75C0.25 18.4404 0.809644 19 1.5 19C2.19036 19 2.75 18.4404 2.75 17.75V17.125C2.75 16.0895 3.58947 15.25 4.625 15.25H23.375C24.4105 15.25 25.25 16.0895 25.25 17.125V17.75C25.25 18.4404 25.8096 19 26.5 19C27.1904 19 27.75 18.4404 27.75 17.75V7.75C27.75 6.42392 27.2232 5.15215 26.2855 4.21447C25.3478 3.27678 24.0761 2.75 22.75 2.75ZM7.75 10.25C8.74456 10.25 9.69839 9.85491 10.4016 9.15165C11.1049 8.44839 11.5 7.49456 11.5 6.5C11.5 5.50544 11.1049 4.55161 10.4016 3.84835C9.69839 3.14509 8.74456 2.75 7.75 2.75C6.75544 2.75 5.80161 3.14509 5.09835 3.84835C4.39509 4.55161 4 5.50544 4 6.5C4 7.49456 4.39509 8.44839 5.09835 9.15165C5.80161 9.85491 6.75544 10.25 7.75 10.25Z"
                  fill="#2C3647"
                />
              </svg>
              <p className="property_info">{propertyData.bedrooms || 0} Bed</p>
            </div>
            <div className="bath">
              <svg
                width="26"
                height="26"
                viewBox="0 0 26 26"
                fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M24.875 15.5C24.5298 15.5 24.25 15.7798 24.25 16.125V16.75C24.25 18.858 23.2073 20.7224 21.6037 21.8528C21.228 22.1176 21.0161 22.5757 21.128 23.0215L21.4379 24.2566C21.5964 24.888 21.119 25.5 20.468 25.5H20.0308C19.5719 25.5 19.1719 25.1877 19.0606 24.7425L18.8144 23.7575C18.7031 23.3123 18.3031 23 17.8442 23H8.15578C7.69691 23 7.29693 23.3123 7.18563 23.7575L6.93937 24.7425C6.82807 25.1877 6.42809 25.5 5.96922 25.5H5.53198C4.88103 25.5 4.4036 24.888 4.56205 24.2566L4.87183 23.0222C4.98381 22.576 4.77154 22.1178 4.39536 21.853C3.68775 21.3548 3.08916 20.713 2.64049 19.9664C2.05685 18.9951 1.74898 17.8831 1.75 16.75V16.125C1.75 15.7798 1.47018 15.5 1.125 15.5C0.779822 15.5 0.5 15.2202 0.5 14.875V14C0.5 13.4477 0.947715 13 1.5 13H22C22.5523 13 23 12.5523 23 12V4.25C23 3.91848 22.8683 3.60054 22.6339 3.36612C22.3995 3.1317 22.0815 3 21.75 3C21.3556 3 21.0209 3.16924 20.79 3.43548C20.5381 3.72585 20.6957 4.13914 20.9385 4.43712C21.2529 4.82283 21.4847 5.27331 21.6182 5.76168C21.7639 6.2944 21.3023 6.75 20.75 6.75H15.25C14.6977 6.75 14.237 6.29454 14.3825 5.76178C14.5529 5.13817 14.8832 4.56346 15.3483 4.09835C16.0516 3.39509 17.0054 3 18 3C18.1274 3 18.2398 2.91917 18.289 2.80173C18.8537 1.45526 20.1881 0.5 21.75 0.5C22.7446 0.5 23.6984 0.895088 24.4017 1.59835C25.1049 2.30161 25.5 3.25544 25.5 4.25V14.875C25.5 15.2202 25.2202 15.5 24.875 15.5Z"
                  fill="#2C3647"
                />
              </svg>
              <p className="property_info">
                {propertyData.bathrooms || 0} Bath
              </p>
            </div>
            <div className="gar">
              <svg
                width="18"
                height="19"
                viewBox="0 0 18 19"
                fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M17.75 18C17.75 18.5523 17.3023 19 16.75 19H16.25C15.6977 19 15.25 18.5523 15.25 18V8.75C15.25 8.19772 14.8023 7.75 14.25 7.75H3.75C3.19772 7.75 2.75 8.19772 2.75 8.75V18C2.75 18.5523 2.30228 19 1.75 19H1.25C0.697715 19 0.25 18.5523 0.25 18V5.83032C0.25 5.47147 0.442286 5.14012 0.753861 4.96208L8.50386 0.533508C8.81129 0.357832 9.18871 0.357832 9.49614 0.533508L17.2461 4.96208C17.5577 5.14012 17.75 5.47147 17.75 5.83032V18ZM4 10C4 9.44771 4.44772 9 5 9H13C13.5523 9 14 9.44771 14 10V10.5C14 11.0523 13.5523 11.5 13 11.5H5C4.44772 11.5 4 11.0523 4 10.5V10ZM4 13.75C4 13.1977 4.44772 12.75 5 12.75H13C13.5523 12.75 14 13.1977 14 13.75V14.25C14 14.8023 13.5523 15.25 13 15.25H5C4.44772 15.25 4 14.8023 4 14.25V13.75ZM13 16.5C13.5523 16.5 14 16.9477 14 17.5V18C14 18.5523 13.5523 19 13 19H5C4.44772 19 4 18.5523 4 18V17.5C4 16.9477 4.44772 16.5 5 16.5H13Z"
                  fill="#2C3647"
                />
              </svg>
              <p className="property_info">{propertyData.garage || 0} Gar</p>
            </div>
          </PropertyInfoContainer>

          <PropertyInformation>
            <div className="price_address">
              <div className="company">
                <p className="property_name">{propertyData.parent_property}</p>
                <p className="property_company"> by {propertyData.company}</p>
              </div>
              <p className="address">{propertyData.title}</p>
            </div>
          </PropertyInformation>
          <div className="bar"></div>
        </CustomPropertyCard>
      ) : (
        <CustomPropertyCard
          {...otherProps}
          $property_type={propertyType}
          onClick={() => NavigationHandler()}>
          {propertyType === 'sold' && !user && (
            <SoldPropertyError>
              <div className="info_content_sold_property">
                <FontAwesomeIcon
                  icon={faLock}
                  className="icon_lock"
                />
                <h3>Free account required</h3>
                <p>Unlock to view more listings</p>
                <p className="error_info">Unlock</p>
              </div>
            </SoldPropertyError>
          )}
          <div>
            <SliderContainer
              {...FeatureSettings}
              ref={sliderRef}>
              {propertyData?.img?.length > 0 ? (
                CoverImages(
                  propertyData.img,
                  propertyType,
                  propertyCardType
                ).map((img: string) => (
                  <div
                    className="home_Images"
                    key={img}>
                    <p className="status_info">{propertyData.sale_or_lease}</p>
                    {propertyData?.timeLabel && (
                      <p className="time_info_label">
                        {propertyData?.timeLabel}
                      </p>
                    )}
                    <img
                      src={img}
                      alt="home_image"
                    />
                  </div>
                ))
              ) : (
                <div className="home_Images">
                  <p className="status_info">{propertyData.sale_or_lease}</p>
                  {propertyData?.timeLabel && (
                    <p className="time_info_label">{propertyData?.timeLabel}</p>
                  )}
                  <img
                    src={ImageNotFound}
                    alt="home_image"
                  />
                </div>
              )}
            </SliderContainer>
          </div>

          <PropertyInformation>
            <div className="price_address">
              <p className={`price ${propertyType == 'sold' ? 'blurred' : ''}`}>
                ${propertyData.price}
              </p>
              <p className="address">{propertyData.title}</p>
            </div>
            <div
              className="search_icon"
              // onClick={() => handleRedirect(propertyData.id)}
            >
              <svg
                width="30"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M8.91429 0.5C11.2785 0.5 13.5459 1.43918 15.2176 3.11093C16.8894 4.78269 17.8286 7.05007 17.8286 9.41429C17.8286 11.4928 17.1116 13.4133 15.9178 14.9356C15.7918 15.0962 15.8004 15.3266 15.9447 15.471V15.471C16.0182 15.5444 16.1178 15.5857 16.2217 15.5857H16.6011C16.948 15.5857 17.2806 15.7235 17.5259 15.9688L22.9714 21.4143C23.5395 21.9824 23.5395 22.9034 22.9714 23.4714V23.4714C22.4034 24.0395 21.4824 24.0395 20.9143 23.4714L15.4688 18.0259C15.2235 17.7806 15.0857 17.448 15.0857 17.1011V16.7215C15.0857 16.6177 15.0445 16.5182 14.9711 16.4448V16.4448C14.8268 16.3005 14.5962 16.2921 14.4357 16.4183C12.8641 17.6536 10.9202 18.3284 8.91429 18.3286C6.55007 18.3286 4.28269 17.3894 2.61093 15.7176C0.939181 14.0459 0 11.7785 0 9.41429C0 7.05007 0.939181 4.78269 2.61093 3.11093C4.28269 1.43918 6.55007 0.5 8.91429 0.5ZM8.91429 3.24286C5.48571 3.24286 2.74286 5.98571 2.74286 9.41429C2.74286 12.8429 5.48571 15.5857 8.91429 15.5857C12.3429 15.5857 15.0857 12.8429 15.0857 9.41429C15.0857 5.98571 12.3429 3.24286 8.91429 3.24286Z"
                  fill="#2C3647"
                />
              </svg>
            </div>
          </PropertyInformation>
          {propertyType === 'sold' && (
            <SoldPropertyInfoContainer>
              <div className="container_stat">
                <p className="sold_stat_header">Listed Price</p>
                <p className="sold_stat">${propertyData.listed_price}</p>
              </div>
              <div className="container_stat">
                <p className="sold_stat_header">Sold On</p>
                <p className="sold_stat">{propertyData.sold_on}</p>
              </div>
              <div className="container_stat">
                <p className="sold_stat_header">Sold In</p>
                <p className="sold_stat">{propertyData.sold_in}Days</p>
              </div>
            </SoldPropertyInfoContainer>
          )}
          <div className="bar"></div>
          <PropertyInfoContainer>
            <div className="bed">
              <svg
                width="28"
                height="19"
                viewBox="0 0 28 19"
                fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M22.75 2.75H15.75C14.0931 2.75 12.75 4.09315 12.75 5.75V8.5C12.75 10.1569 11.4069 11.5 9.75 11.5H5.75C4.09315 11.5 2.75 10.1569 2.75 8.5V1.5C2.75 0.809644 2.19036 0.25 1.5 0.25C0.809644 0.25 0.25 0.809644 0.25 1.5V17.75C0.25 18.4404 0.809644 19 1.5 19C2.19036 19 2.75 18.4404 2.75 17.75V17.125C2.75 16.0895 3.58947 15.25 4.625 15.25H23.375C24.4105 15.25 25.25 16.0895 25.25 17.125V17.75C25.25 18.4404 25.8096 19 26.5 19C27.1904 19 27.75 18.4404 27.75 17.75V7.75C27.75 6.42392 27.2232 5.15215 26.2855 4.21447C25.3478 3.27678 24.0761 2.75 22.75 2.75ZM7.75 10.25C8.74456 10.25 9.69839 9.85491 10.4016 9.15165C11.1049 8.44839 11.5 7.49456 11.5 6.5C11.5 5.50544 11.1049 4.55161 10.4016 3.84835C9.69839 3.14509 8.74456 2.75 7.75 2.75C6.75544 2.75 5.80161 3.14509 5.09835 3.84835C4.39509 4.55161 4 5.50544 4 6.5C4 7.49456 4.39509 8.44839 5.09835 9.15165C5.80161 9.85491 6.75544 10.25 7.75 10.25Z"
                  fill="#2C3647"
                />
              </svg>
              <p className="property_info">{propertyData.bedrooms || 0} Bed</p>
            </div>
            <div className="bath">
              <svg
                width="26"
                height="26"
                viewBox="0 0 26 26"
                fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M24.875 15.5C24.5298 15.5 24.25 15.7798 24.25 16.125V16.75C24.25 18.858 23.2073 20.7224 21.6037 21.8528C21.228 22.1176 21.0161 22.5757 21.128 23.0215L21.4379 24.2566C21.5964 24.888 21.119 25.5 20.468 25.5H20.0308C19.5719 25.5 19.1719 25.1877 19.0606 24.7425L18.8144 23.7575C18.7031 23.3123 18.3031 23 17.8442 23H8.15578C7.69691 23 7.29693 23.3123 7.18563 23.7575L6.93937 24.7425C6.82807 25.1877 6.42809 25.5 5.96922 25.5H5.53198C4.88103 25.5 4.4036 24.888 4.56205 24.2566L4.87183 23.0222C4.98381 22.576 4.77154 22.1178 4.39536 21.853C3.68775 21.3548 3.08916 20.713 2.64049 19.9664C2.05685 18.9951 1.74898 17.8831 1.75 16.75V16.125C1.75 15.7798 1.47018 15.5 1.125 15.5C0.779822 15.5 0.5 15.2202 0.5 14.875V14C0.5 13.4477 0.947715 13 1.5 13H22C22.5523 13 23 12.5523 23 12V4.25C23 3.91848 22.8683 3.60054 22.6339 3.36612C22.3995 3.1317 22.0815 3 21.75 3C21.3556 3 21.0209 3.16924 20.79 3.43548C20.5381 3.72585 20.6957 4.13914 20.9385 4.43712C21.2529 4.82283 21.4847 5.27331 21.6182 5.76168C21.7639 6.2944 21.3023 6.75 20.75 6.75H15.25C14.6977 6.75 14.237 6.29454 14.3825 5.76178C14.5529 5.13817 14.8832 4.56346 15.3483 4.09835C16.0516 3.39509 17.0054 3 18 3C18.1274 3 18.2398 2.91917 18.289 2.80173C18.8537 1.45526 20.1881 0.5 21.75 0.5C22.7446 0.5 23.6984 0.895088 24.4017 1.59835C25.1049 2.30161 25.5 3.25544 25.5 4.25V14.875C25.5 15.2202 25.2202 15.5 24.875 15.5Z"
                  fill="#2C3647"
                />
              </svg>
              <p className="property_info">
                {propertyData.bathrooms || 0} Bath
              </p>
            </div>
            <div className="gar">
              <svg
                width="18"
                height="19"
                viewBox="0 0 18 19"
                fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M17.75 18C17.75 18.5523 17.3023 19 16.75 19H16.25C15.6977 19 15.25 18.5523 15.25 18V8.75C15.25 8.19772 14.8023 7.75 14.25 7.75H3.75C3.19772 7.75 2.75 8.19772 2.75 8.75V18C2.75 18.5523 2.30228 19 1.75 19H1.25C0.697715 19 0.25 18.5523 0.25 18V5.83032C0.25 5.47147 0.442286 5.14012 0.753861 4.96208L8.50386 0.533508C8.81129 0.357832 9.18871 0.357832 9.49614 0.533508L17.2461 4.96208C17.5577 5.14012 17.75 5.47147 17.75 5.83032V18ZM4 10C4 9.44771 4.44772 9 5 9H13C13.5523 9 14 9.44771 14 10V10.5C14 11.0523 13.5523 11.5 13 11.5H5C4.44772 11.5 4 11.0523 4 10.5V10ZM4 13.75C4 13.1977 4.44772 12.75 5 12.75H13C13.5523 12.75 14 13.1977 14 13.75V14.25C14 14.8023 13.5523 15.25 13 15.25H5C4.44772 15.25 4 14.8023 4 14.25V13.75ZM13 16.5C13.5523 16.5 14 16.9477 14 17.5V18C14 18.5523 13.5523 19 13 19H5C4.44772 19 4 18.5523 4 18V17.5C4 16.9477 4.44772 16.5 5 16.5H13Z"
                  fill="#2C3647"
                />
              </svg>
              <p className="property_info">{propertyData.garage || 0} Gar</p>
            </div>
          </PropertyInfoContainer>
        </CustomPropertyCard>
      )}
      {open && (
        <SoldHomeForm
          handleOpenClose={handleOpenClose}
          handleState={handleState}
          handleSubmitForm={handleSubmitForm}
        />
      )}
    </>
  );
}
