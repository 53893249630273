/** @format */

import styled from 'styled-components';

export const Container = styled.div`
  margin-top: 2rem;
  padding-bottom: 1rem;
  .layouts_heading{
    color: #13131a;
    font-family: Inter;
    font-size: 1.25rem;
    font-weight: 500;
    margin: 0.8rem;
  }
  .layouts_container {
    display: flex;
    align-items: center;
    gap: 1.5rem;
    filter: drop-shadow(0px 0px 5px rgba(44, 54, 71, 0.2));
  }

  p {
    color: #13131a;
    font-family: Inter;
    font-size: 1.25rem;
    font-size: clamp(1rem, 1rem + 1vw, 1.25rem);
    font-weight: 500;
  }
  img {
    width: 6rem;
    height: 6rem;
    object-fit: cover;
    border-radius: 1.5rem;
    cursor: pointer;
  }
`;
