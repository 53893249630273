/** @format */

import styled from 'styled-components';

export const ImageContainer = styled.div`
  border-radius: 0.3125rem;
  opacity: 0rem;
  background: #d9d9d9;
  overflow: hidden;
  height: 5rem;
  width: 5rem;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

export const ListingContainer = styled.div`
  padding: 0.3125rem;
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  .left_section {
    flex: 0.8;
    display: flex;
    gap: 0.65rem;
    ${ImageContainer} {
      flex: 0.3;
    }
    .property_info {
      flex: 0.7;
      .price {
        font-family: Inter;
        font-size: 1rem;
        font-weight: 500;
        line-height: 0.9075rem;
        text-align: left;
        color: ${(props) =>
          props?.theme?.colors?.primary
            ? props?.theme?.colors?.primary
            : 'var(--primary)'};
      }
      .address {
        font-family: Inter;
        font-size: 1rem;
        font-weight: 500;
        line-height: 0.9075rem;
        text-align: left;
      }
    }
  }
  .right_section {
    flex: 0.2;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-end;
    .l_up_section {
      background-color: ${(props) =>
        props?.theme?.colors?.primary
          ? props?.theme?.colors?.primary
          : 'var(--primary)'};
      padding: 0.125rem 0.25rem 0.125rem 0.25rem;
      border-radius: 0.125rem;
      p {
        color: ${(props) =>
          props?.theme?.colors?.text
            ? props?.theme?.colors?.text
            : 'var(--secondary)'};
        font-family: Inter;
        font-size: 14px;
        font-weight: 500;
        line-height: 0.9075rem;
        text-align: left;
        margin: 0;
      }
    }
    .l_down_section {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
    }
  }
`;
