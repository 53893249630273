/** @format */

import polyline from '@mapbox/polyline';
import { MapCameraChangedEvent, MapCameraProps } from '@vis.gl/react-google-maps';
import axios from 'axios';
import { useCallback, useEffect, useState } from 'react';
import { useFilterContext } from '../../../context/filter.context';
import { PropertyType } from './component/Property.component';
import { getLocation } from '../idxListing/api/geo_location';
import { useMessageContext } from '../../../context/Message.context';

const INITIAL_CAMERA_STATE: MapCameraProps = {
  center: { lat: 43.65107, lng: -79.347015 },
  zoom: 10.8,
  heading: 0,
  tilt: 0,
};

const API_URL = 'https://valuation.santhoshmathew.com/api/v1/get-idx-map-listings';

export default function useMap() {
  const [property, setProperty] = useState<PropertyType[]>(null);
  const [geoLocation, setGeoLocation] = useState({ lat: 43.65107, lng: -79.347015 });
  const [encodedPath, setEncodedPath] = useState<string | null>(null);
  const [resultCount, setResultCount] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(false);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [lastPage, setLastPage] = useState<number>(0);
  const [cameraState, setCameraState] = useState<MapCameraProps>(INITIAL_CAMERA_STATE);

  const { setErrorShow, setMessage } = useMessageContext();
  const { state: filterState, setProperty: setFilterProperty } = useFilterContext();

  const handleCameraChange = useCallback((ev: MapCameraChangedEvent) => {
    setCameraState(ev.detail);
  }, []);

  const getCityPolyLine = async (city: string) => {
    try {
      const response = await axios.get(`/api/get-city-polygon/${city}`);
      const polygonData = response.data.data.polygon;
      const latitude = Number(polygonData.lat);
      const longitude = Number(polygonData.lon);

      if (polygonData.geojson.type !== "Polygon") {
        const [lat, lng] = polygonData.geojson.coordinates;
        const encodedPolyline = polyline.encode([lat, lng]);
        return { position: { center: { lat: latitude, lng: longitude } }, polyline: encodedPolyline };
      } else {
        const cityPolyline = polygonData.geojson.coordinates[0].map(([lng, lat]) => [lat, lng]);
        const encodedPolyline = polyline.encode(cityPolyline);
        return { position: { center: { lat: latitude, lng: longitude } }, polyline: encodedPolyline };
      }
    } catch (err) {
      console.error('Error fetching city polygon:', err.message);
    }
  };

  const fetchProperties = async () => {
    if (!filterState.search || filterState.search.trim() === '') return;

    const source = axios.CancelToken.source();
    setLoading(true);

    try {
      const response = await axios.get(API_URL, {
        cancelToken: source.token,
        params: {
          range: 10000000000,
          ...(filterState.min_price && { min_price: filterState.min_price }),
          ...(filterState.max_price && { max_price: filterState.max_price }),
          ...(filterState.home_type && { property_type: filterState.home_type }),
          ...(filterState.bedroom && { bedrooms: filterState.bedroom }),
          ...(filterState.bathroom && { bathrooms: filterState.bathroom }),
          ...(filterState.sortBy && { sortBy: filterState.sortBy }),
          ...(filterState.sortDir && { sortDir: filterState.sortDir }),
          city: filterState.search,
          ...(filterState.sale_or_lease && { sale_or_lease: filterState.sale_or_lease }),
          ...(filterState.property_style && { property_style: filterState.property_style }),
          latitude: geoLocation.lat,
          longitude: geoLocation.lng,
          page: currentPage,
        },
      });

      const propertyData = response.data;
      if (propertyData.data.length > 0) {
        setLastPage(propertyData.meta.last_page);
        setProperty(propertyData.data);
        const geoLocationData = await getCityPolyLine(filterState.search);
        if (geoLocationData) {
          setCameraState(prev => ({
            ...prev,
            zoom: 10.8,
            center: geoLocationData.position.center,
          }));
          setEncodedPath(geoLocationData.polyline);
        }
      } else {
        setErrorShow(true);
        setMessage(`Couldn't find any property in ${filterState.search}`);
      }
    } catch (error) {
      console.error('Error fetching properties:', error.message);
    } finally {
      setTimeout(() => setLoading(false), 12000);
    }

    return () => source.cancel('API call cancelled by cleanup');
  };

  useEffect(() => {
    fetchProperties();
  }, [filterState, geoLocation.lat, geoLocation.lng, currentPage]);

  useEffect(() => {
    const getLatLng = async () => {
      try {
        const data = await getLocation();
        if (filterState.search === '' && data) {
          setGeoLocation({ lat: data[0], lng: data[1] });
        }
      } catch (e) {
        setFilterProperty('search', 'toronto');
        console.error('Error getting location:', e.message);
      }
    };
    getLatLng();
  }, []);

  const handlePageChange = (pageIndex: { selected: number }) => {
    setCurrentPage(pageIndex.selected + 1);
    window.scrollTo(0, 0);
  };

  return {
    encodedPath,
    property,
    resultCount,
    loading,
    cameraState,
    currentPage,
    lastPage,
    handleCameraChange,
    handlePageChange,
  };
}
