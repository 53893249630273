/** @format */

import { useLocation, useNavigate } from 'react-router-dom';
import ConvertToCanadianNumber from '../../../../../utils/New_construction_details/ConvertNumberToDollar.util';
import { ImageContainer, ListingContainer } from './Listing.style';
export default function ListingComponent({ data }) {
  const Navigate = useNavigate();
  const urlPath = useLocation();
  const handleNavigation = () => {
    if (urlPath.pathname.includes('home')) {
      Navigate(`../idxListing/idxView/${data.unique_id}`);
    } else {
      Navigate(`idxView/${data.unique_id}`);
    }
  };
  return (
    <ListingContainer onClick={handleNavigation}>
      <div className="left_section">
        <ImageContainer>
          <img
            src={data?.photos?.length > 0 && data.photos[0]}
            alt="presentation of homes"
          />
        </ImageContainer>
        <div className="property_info">
          <p className="price">${ConvertToCanadianNumber(data.list_price)}</p>
          <p className="address">
            {data.address},{data.city},{data.zip}
          </p>
        </div>
      </div>
      <div className="right_section">
        <div className="l_up_section">
          <p>{data.sale_or_lease}</p>
        </div>
        <div className="l_down_section">
          <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
              d="M15.832 5.83341H12.082C10.4712 5.83341 9.16536 7.13925 9.16536 8.75008V8.75008C9.16536 10.3609 7.85953 11.6667 6.2487 11.6667H5.4987C3.84184 11.6667 2.4987 10.3236 2.4987 8.66675V5.00008C2.4987 4.53984 2.1256 4.16675 1.66536 4.16675V4.16675C1.20513 4.16675 0.832031 4.53984 0.832031 5.00008V15.8334C0.832031 16.2937 1.20513 16.6667 1.66536 16.6667V16.6667C2.1256 16.6667 2.4987 16.2937 2.4987 15.8334V15.4167C2.4987 14.7264 3.05834 14.1667 3.7487 14.1667H16.2487C16.9391 14.1667 17.4987 14.7264 17.4987 15.4167V15.8334C17.4987 16.2937 17.8718 16.6667 18.332 16.6667V16.6667C18.7923 16.6667 19.1654 16.2937 19.1654 15.8334V9.16675C19.1654 8.28269 18.8142 7.43485 18.1891 6.80973C17.5639 6.1846 16.7161 5.83341 15.832 5.83341ZM5.83203 10.8334C6.49507 10.8334 7.13096 10.57 7.5998 10.1012C8.06864 9.63234 8.33203 8.99646 8.33203 8.33341C8.33203 7.67037 8.06864 7.03449 7.5998 6.56565C7.13096 6.09681 6.49507 5.83341 5.83203 5.83341C5.16899 5.83341 4.5331 6.09681 4.06426 6.56565C3.59542 7.03449 3.33203 7.67037 3.33203 8.33341C3.33203 8.99646 3.59542 9.63234 4.06426 10.1012C4.5331 10.57 5.16899 10.8334 5.83203 10.8334Z"
              fill="#13131A"
            />
          </svg>
          <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
              d="M17.918 11.6667C17.6878 11.6667 17.5013 11.8533 17.5013 12.0834V12.5001C17.5013 13.8119 16.8956 14.9822 15.9455 15.7451C15.587 16.0329 15.3716 16.4882 15.4835 16.9341L15.5825 17.3288C15.7105 17.8389 15.3248 18.3334 14.7988 18.3334V18.3334C14.4281 18.3334 14.1049 18.0811 14.015 17.7214L13.9407 17.4242C13.8294 16.979 13.4294 16.6667 12.9705 16.6667H7.03208C6.57321 16.6667 6.17323 16.979 6.06194 17.4242L5.98764 17.7214C5.89772 18.0811 5.57454 18.3334 5.20378 18.3334V18.3334C4.67783 18.3334 4.29208 17.8389 4.4201 17.3288L4.51897 16.9348C4.63095 16.4886 4.41504 16.0331 4.05623 15.7452C3.67489 15.4392 3.34891 15.0669 3.09496 14.6443C2.70587 13.9968 2.50062 13.2555 2.5013 12.5001V12.0834C2.5013 11.8533 2.31475 11.6667 2.08464 11.6667V11.6667C1.85452 11.6667 1.66797 11.4802 1.66797 11.2501V10.8334C1.66797 10.3732 2.04106 10.0001 2.5013 10.0001H15.668C16.2203 10.0001 16.668 9.55237 16.668 9.00008V4.16675C16.668 3.94573 16.5802 3.73377 16.4239 3.57749C16.2676 3.42121 16.0556 3.33341 15.8346 3.33341C15.65 3.33341 15.485 3.38904 15.3498 3.48361C15.0348 3.70395 15.2032 4.14135 15.4187 4.45964C15.5039 4.58541 15.577 4.71944 15.6372 4.86001C15.8544 5.36779 15.3869 5.83341 14.8346 5.83341H11.8346C11.2824 5.83341 10.8169 5.36853 11.032 4.85985C11.1563 4.56592 11.3368 4.29568 11.5669 4.06565C12.0357 3.59681 12.6716 3.33341 13.3346 3.33341V3.33341C13.4195 3.33341 13.4945 3.27953 13.5273 3.20123C13.9038 2.30358 14.7934 1.66675 15.8346 1.66675C16.4977 1.66675 17.1336 1.93014 17.6024 2.39898C18.0712 2.86782 18.3346 3.50371 18.3346 4.16675V11.2501C18.3346 11.4802 18.1481 11.6667 17.918 11.6667V11.6667Z"
              fill="#13131A"
            />
          </svg>
          <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
              d="M15.8346 15.8334C15.8346 16.2937 15.4615 16.6667 15.0013 16.6667V16.6667C14.5411 16.6667 14.168 16.2937 14.168 15.8334V10.1667C14.168 9.61446 13.7203 9.16675 13.168 9.16675H6.83464C6.28235 9.16675 5.83464 9.61446 5.83464 10.1667V15.8334C5.83464 16.2937 5.46154 16.6667 5.0013 16.6667V16.6667C4.54106 16.6667 4.16797 16.2937 4.16797 15.8334V8.0804C4.16797 7.72155 4.36025 7.3902 4.67183 7.21216L9.50516 4.45026C9.8126 4.27458 10.19 4.27458 10.4974 4.45026L15.3308 7.21216C15.6423 7.3902 15.8346 7.72155 15.8346 8.0804V15.8334ZM6.66797 10.8334C6.66797 10.3732 7.04106 10.0001 7.5013 10.0001H12.5013C12.9615 10.0001 13.3346 10.3732 13.3346 10.8334V10.8334C13.3346 11.2937 12.9615 11.6667 12.5013 11.6667H7.5013C7.04106 11.6667 6.66797 11.2937 6.66797 10.8334V10.8334ZM6.66797 13.3334C6.66797 12.8732 7.04106 12.5001 7.5013 12.5001H12.5013C12.9615 12.5001 13.3346 12.8732 13.3346 13.3334V13.3334C13.3346 13.7937 12.9615 14.1667 12.5013 14.1667H7.5013C7.04106 14.1667 6.66797 13.7937 6.66797 13.3334V13.3334ZM12.5013 15.0001C12.9615 15.0001 13.3346 15.3732 13.3346 15.8334V15.8334C13.3346 16.2937 12.9615 16.6667 12.5013 16.6667H7.5013C7.04106 16.6667 6.66797 16.2937 6.66797 15.8334V15.8334C6.66797 15.3732 7.04106 15.0001 7.5013 15.0001H12.5013Z"
              fill="#13131A"
            />
          </svg>
        </div>
      </div>
    </ListingContainer>
  );
}
