/** @format */

import { useEffect, useState } from 'react';

import axios from 'axios';
import Cookies from 'js-cookie';
import { ConvertTime } from '../../../../utils/timezoneconvertor';
import useConvertIntoExcel from '../../customhooks/useConvertIntoExcel.customhook';
import './BuyersLeads.css';
import BuyersLeadsHeader from './BuyersLeadsHeader/BuyersLeadHeader';
import BuyersLeadsInfo from './BuyersLeadsInfo';
export default function BuyersLeads() {
  const [data, setData] = useState([]);
  const [sort, setSort] = useState('desc');
  const [searchTerm, setSearchTerm] = useState('');
  const COlumnsLength = [
    { wpx: 150 },
    { wpx: 400 },
    { wpx: 600 },
    { wpx: 150 },
    { wpx: 150 },
    { wpx: 150 },
    { wpx: 150 },
    { wpx: 150 },
    { wpx: 150 },
  ];
  const { exportToExcel } = useConvertIntoExcel(COlumnsLength);

  const handleAllData = () => {
    const filterData = data.map((data) => ({
      name: data.name,
      email: data.email,
      message: data.message,
      contact: data.phone ? data.phone : 'N/A',
      status: data.status,
      createdAtTime: ConvertTime(data.createdAt).split('-')[0],
      createdAtDate: ConvertTime(data.createdAt).split('-')[1],
      updatedAtTime: ConvertTime(data.updatedAt).split('-')[0],
      updatedAtDate: ConvertTime(data.updatedAt).split('-')[1],
    }));
    exportToExcel(filterData, 'buyersLeads');
  };
  //search
  const handleSearch = () => {
    fetchData(searchTerm);
  };

  const fetchData = async (searchTerm: string) => {
    const token = Cookies.get('token');
    try {
      const data = await axios.get('/admin/buyer-lead/', {
        headers: {
          'x-access-token': token,
        },
        params: {
          sortOrder: sort,
          search: searchTerm,
        },
      });
      setData(data.data.data);
      console.log(data.data.data);
    } catch (err) {
      throw new Error(err.message);
    }
  };

  //for sort and first time load data
  useEffect(() => {
    fetchData(searchTerm);
  }, [sort]);

  return (
    <div className="Container">
      <BuyersLeadsHeader
        fetchData={fetchData}
        setSearchTerm={setSearchTerm}
        Search={handleSearch}
        setSort={setSort}
        downloadAll={handleAllData}
      />
      <div className="buyers_leader">
        <div className=" buyers_leader_header">
          <p className="heading">name</p>
          <p className="heading bar">email</p>
          <p className="heading bar">contact</p>
          <p className="heading bar">date&time</p>
          <p className="heading bar"> View</p>
          <p className="heading bar">mark status</p>
        </div>
        <div className="buyers_info_list">
          {data.length !== 0 ? (
            data.map((data) => (
              <BuyersLeadsInfo
                Data={data}
                key={data._id}
              />
            ))
          ) : (
            <p style={{ color: 'white', padding: '10px' }}>
              No leads data yet.
            </p>
          )}
        </div>
      </div>
    </div>
  );
}
