/** @format */

import styled from 'styled-components';

export const IdxListingContainer = styled.div`
  margin: 4rem 0;
  p,
  h1,
  button,
  a {
    font-family: 'Tenor Sans';
  }
  .peginate_class {
    width: min(95%, 50rem);
    margin: 0 auto;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    border-radius: 0.625rem;
    flex-wrap: wrap;
    .page,
    .break {
      list-style-type: none;
      color: ${(props) => props.theme.colors.primary};
      border: 1px solid gray;
      /* padding: 0.25rem 1rem; */
      height: 2.2rem;
      flex: 1;
      text-align: center;
      transition: all 200ms;
      display: flex;
      justify-content: center;
      align-items: center;
      a {
        display: block;
        width: 100%;
      }
      &:hover {
        transition: all 200ms;
        color: ${(props) => props.theme.colors.text};
        background-color: ${(props) => props.theme.colors.primary};
      }
    }
    .selected {
      transition: all 200ms;
      color: ${(props) => props.theme.colors.text};
      background-color: ${(props) => props.theme.colors.primary};
    }
    .previous,
    .next {
      list-style-type: none;
      flex: 1;
      text-align: center;
      padding: 0.25rem 1rem;
      border: 1px solid gray;
      color: ${(props) => props.theme.colors.primary};
      a {
        color: ${(props) => props.theme.colors.primary};
        text-decoration: none;
      }
    }
    .previous {
      border-bottom-left-radius: 0.345rem;
      border-top-left-radius: 0.345rem;
      cursor: pointer;
    }
    .next {
      cursor: pointer;
      border-bottom-right-radius: 0.345rem;
      border-top-right-radius: 0.345rem;
    }
    /* .previous_link {
      color: ${(props) => props.theme.colors.primary};
    }
    .next_link {
      color: ${(props) => props.theme.colors.primary};
    } */
  }
`;

export const CardsContainer = styled.div`
  width: min(115rem, 94%);
  display: grid;
  grid-template-columns: repeat(auto-fit, minMax(288px, 1fr));
  justify-content: center;
  justify-items: center;
  margin: 3rem auto;
  gap: 1rem;
  @media screen and (min-width: 1281px) {
    width: min(95rem, 95%);
    grid-template-columns: repeat(auto-fit, minMax(352px, 1fr));
  }
`;

export const FeatureCard = styled.div`
  background-color: ${(props) => props.theme.colors.background};
  position: relative;
  padding-bottom: 1rem;
  width: 100%;
  box-shadow: 5px 5px 10px 0 rgba(0, 0, 0, 0.5);
  .image_wrapper {
    height: 17.681rem;
    background-color: gray;
    display: flex;
    justify-content: center;
    align-items: center;
    .image_box {
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
    .svg_box {
      width: 2rem;
      height: 2rem;
    }
  }

  /* img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  } */
  p {
    color: ${(props) => props.theme.colors.primary};
    text-align: center;
    font-size: 1.063rem;
    margin: 0;
  }
  .view_button {
    position: absolute;
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0;
    a {
      display: block;
      padding: 0.25rem 0.75rem;
      text-decoration: none;
      border: 1px solid ${(props) => props.theme.colors.primary};
      color: ${(props) => props.theme.colors.primary};
    }
  }
  .for_sale {
    position: absolute;
    right: 0;
    background-color: black;

    padding: 0.25rem;
    text-transform: uppercase;
    p {
      font-size: clamp(0.25rem, 1vw, 1rem);
      color: ${(props) => props.theme.colors.text};
    }
  }
  &:hover {
    &::before {
      content: '';
      position: absolute;
      height: 100%;
      width: 100%;
      background-color: ${(props) => props.theme.colors.background};
      opacity: 0.8;
    }
    .view_button {
      opacity: 1;
    }
  }
`;

export const Nevigation = styled.div`
  display: flex;
  gap: 1rem;
  align-items: center;
  justify-content: flex-end;
  p {
    text-transform: uppercase;
  }
  .bar {
    height: 1rem;
    width: 0.15rem;
    background-color: ${(props) => props.theme.colors.background};
  }
`;

export const SearchFilterContainer = styled.div`
  padding: 1rem 1.5rem;
  display: flex;
  /* justify-content: space-between; */
  gap: 1rem;
  align-items: center;
  /* flex-wrap: wrap; */
  .search_filter {
    display: flex;
    align-items: center;
    gap: 1rem;
    flex-wrap: wrap;
  }
  .browse_homes {
    margin-left: auto;
  }
  @media screen and (max-width: 1035px) {
    flex-direction: column;
  }
`;

export const LoadingContainer = styled.div`
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  .loader {
    border-top: 2px solid ${(props) => props?.theme?.colors?.primary};
  }
`;

export const AllowLocationContainer = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: min(25rem, 95%);
  height: 20rem;
  background-color: white;
  border-radius: 0.3125rem;
  .buttons {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
    .cancel_button,
    .Allow {
      padding-block: 0.35rem;
      padding-inline: 1rem;
      border: none;
      outline: none;
      border-radius: 0.3125rem;
    }
    .cancel_button {
      background-color: white;
      color: ${(props) => props.theme.colors.primary};
      border: 1px solid ${(props) => props.theme.colors.primary};
    }
    .Allow {
      background-color: ${(props) => props.theme.colors.primary};
      color: white;
      border: 1px solid white;
    }
  }
`;
