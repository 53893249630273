/** @format */

import styled from 'styled-components';

export const BlogCardContainer = styled.div`
  max-width: 25.5rem;
  min-height: 19.375rem;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  gap: 1rem;
  border-radius: 1.25rem;
  padding: 1.5rem;
  p {
    margin: 0;
  }
  .blog_image_container {
    border-radius: 0.625rem;
    img {
      width: 100%;
      height: 100%;
      object-fit: fill;
    }
  }
  .heading {
    font-family: Syncopate;
    font-size: 1.5rem;
    font-size: clamp(1rem, 1.5vw, 1.5rem);
    font-weight: 400;
    line-height: 2.2231rem;
    color: ${({ theme: { colors } }) => colors.primary};
     overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
  }
  .description {
    font-family: 'Cormorant Garamond';
    color: ${({ theme: { colors } }) => colors?.TextGrey};
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.3181rem;
    text-align: left;
    width: 100%;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
  }
  .desc_container {
    display: flex;
    justify-content: left;
    align-items: center;
    gap: 1.5rem;
    .redirect {
      cursor: pointer;
      width: 3.125rem;
      height: 3.125rem;
      border-radius: 2.48rem;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: ${({ theme: { colors } }) => colors.text};
    }
    .read_time {
      font-family: 'Cormorant Garamond';
      font-size: 1.125rem;
      font-size: clamp(0.9rem, 1.125vw, 1.25rem);
      font-weight: 500;
      line-height: 1.3181rem;
      text-align: left;
      color: ${({ theme: { colors } }) => colors.primary};
    }
    .date {
      font-family: 'Cormorant Garamond';
      font-size: 0.75rem;
      font-weight: 400;
      line-height: 0.8788rem;
      color: ${({ theme: { colors } }) => colors.primary};
    }
  }
  &:hover {
    background-color: ${({ theme: { colors } }) => colors.text};
  }
`;
