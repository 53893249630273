/** @format */

import styled from 'styled-components';

export const Container = styled.div`
  width: min(80rem, 95%);
  margin: 6rem auto;
  h1 {
    font-family: Syncopate;
    font-size: 3rem;
    font-size:clamp(1.4rem , 3vw , 3rem);
    font-weight: 700;
    line-height: 3.1231rem;
    text-align: center;
    color: #000000;
  }
`;

export const BlogContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(360px, 1fr));
  gap: 1rem;
`;
