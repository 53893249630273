/** @format */

import { AnimatePresence } from 'framer-motion';
import { useState } from 'react';

//style
import { Container } from './DomainLinking.style';

//component

import BuyingDomain from './components/BuyingDomain/BuyingDomain.component';
import ConnectInstruction from './components/ConnectInstruction/ConnectInstruction.component';
import DomainDescription from './components/DomainDescription/DomainDescription.component';
import DomainInstruction from './components/DomainInstruction/DomainInstruction.component';
import LinkingForm from './components/LinkingForm/LinkingForm.component';

export default function DomainLinking() {
  const [step, setStep] = useState(0);
  const [domain, setDomain] = useState(null);
  return (
    <Container>
      <AnimatePresence>
        <DomainInstruction
          key={0}
          setStep={setStep}
          step={step}
        />
        {step >= 1 && step <= 2 && (
          <BuyingDomain
            key={1}
            setStep={setStep}
            step={step}
            setDomain={setDomain}
            domain={domain}
          />
        )}
        {step === 2 && (
          <DomainDescription
            key={2}
            setStep={setStep}
            domain={domain}
          />
        )}
        {step === 3 && (
          <LinkingForm
            key={3}
            setStep={setStep}
          />
        )}
        {step === 4 && <ConnectInstruction key={4} />}
      </AnimatePresence>
    </Container>
  );
}
