/** @format */

import styled from 'styled-components';

export const OurTeamCardContainer = styled.div`
  max-width: 18.75rem;
  max-height: 25rem;
  width: 18.75rem;
  height: 24rem;
  padding: 0.9375rem;
  border-radius: 0.5rem;
  margin: 0 auto;
  background: ${({ theme: { colors } }) => colors.text};
  overflow: hidden;
  transition: transform 0.3s ease;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 0.5rem;
  }

  .team_member_name,
  .profession {
    transition: transform 0.3s ease, color 0.3s ease;
    text-align: center;
  }

  .team_member_name {
    font-family: Syncopate;
    margin-top: 0.35rem;
    font-size: 1.5rem;
    // font-family: clamp(1rem, 1.5vw, 1.5vw);
    font-weight: 400;
    line-height: 1.5rem;
    color: ${({ theme: { colors } }) => colors.primary};
  }

  .profession {
    font-family: Syncopate;
    // font-family: clamp(1rem, 1.125vw, 1.125rem);
    font-weight: 300;
    color: ${({ theme: { colors } }) => colors.primary};
  }

  &:hover {
    .team_member_name {
      transform: translateY(-10px); 
      color: ${({ theme: { colors } }) => colors.secondary}; 
    }

    .profession {
      transform: translateY(-5px); 
      color: ${({ theme: { colors } }) => colors.secondary}; 
    }
  }
`;
