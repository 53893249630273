



import { PropertySetImageBanner } from "./propertyCardSetImage"

import ConvertToCanadianNumber from "./ConvertNumberToDollar.util"

export const DifferentModel = (Data) => {
    return Data.diffrent_floor_plans?.filter((item) => !item?.is_parent)
        .map((item) => {
            return {
                ...item,
                img:PropertySetImageBanner(item)[0].file_url,
                list_price: item?.list_price
                    ? ConvertToCanadianNumber(item.list_price)
                    : "",
            }
        })

}