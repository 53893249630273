/** @format */

import styled from 'styled-components';

import { Container } from '../DomainInstruction/DomainInstruction.style';

export const BuyingContainer = styled(Container)`
  margin-top: 3rem;
  .domains {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    .wrapper {
        position: relative;
      &.inactive::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: #000000CC;
      }
      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
  }
`;
