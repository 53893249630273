/** @format */

import styled from 'styled-components';

export const BlogContainer = styled.div`
  max-width: 29.3337rem;
  max-height: 30.25rem;
  cursor: pointer;
  .heading {
    color: #000000;
    font-family: Garamond;
    font-size: clamp(1rem, 2.5vw, 2.5rem);
    font-weight: 400;
    line-height: 2.8125rem;
    text-align: left;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
  .description {
    color: #000000;
    font-family: Garamond;
    font-size: clamp(1rem, 1.5vw, 1.5rem);
    font-weight: 400;
    line-height: 1.6875rem;
    text-align: justified;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
  }
`;
