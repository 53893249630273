/** @format */

import styled from 'styled-components';

import { Container } from '../DomainInstruction/DomainInstruction.style';

export const FormContainer = styled(Container)`
  height: 100%;
  position: relative;
`;
export const Form = styled.form`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 16px;
  margin-top: 3rem;
  .domain_input_form {
    flex: 0.6;
    background: #474747;
    padding: 0.9375rem;
    gap: 0.625rem;
    border-radius: 0.75rem;
    color: #d9d9d9;
    font-family: Roboto;
    font-size: 1.125rem;
    font-weight: 400;
    border: none;
    outline: none;
    &:focus {
      border: none;
      outline: none;
    }
  }
  .submit_button {
    flex: 0.1;
    background: linear-gradient(94.98deg, #d9d9d9 0%, #ffffff 100%);
    padding: 0.9375rem 1.875rem 0.9375rem 1.875rem;
    gap: 0.625rem;
    border-radius: 0.75rem;
    font-family: Roboto;
    font-size: 1.125rem;
    font-weight: 400;
    line-height: 1.3181rem;
    border: none;
    outline: none;
  }
`;

export const ErrorBox = styled.div`
  width: 25rem;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 1rem;
  padding: 1rem;
  background: linear-gradient(94.98deg, #d9d9d9 0%, #ffffff 100%);
  .svg_wrap{
    width: fit-content;
    margin-left: auto;
    cursor: pointer;
  }
  .error{
    margin-top: 1rem;
    color:#474747;
    font-family: Roboto;
    font-size: 1.125rem;
    font-weight: 400;
  }
`;
