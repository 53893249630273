/** @format */

import { AnimatePresence, motion } from 'framer-motion';
import React, { useState } from 'react';
import { Button, SearchContainer } from './search.style';
export default function SearchDashBoard({ placeholder, value, handleChange }) {
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.stopPropagation();
    setOpen((prev) => !prev);
  };
  return (
    <SearchContainer
      onClick={handleOpen}
      initial={{ backgroundColor: 'transparent' }}
      animate={{ backgroundColor: open ? '#232325' : 'transparent' }}
      exit={{ backgroundColor: 'transparent' }}
      transition={{ duration: 1, ease: 'easeInOut' }}>
      <AnimatePresence>
        {open && (
          <motion.input
            key={1}
            initial={{ opacity: 0, width: 0 }}
            animate={{ opacity: 1, width: '100%' }}
            exit={{ opacity: 0, width: 0 }}
            transition={{ duration: 0.5, ease: 'easeInOut' }}
            className="search_box"
            type="text"
            placeholder={placeholder}
            value={value}
            onChange={handleChange}
          />
        )}
        <Button>
          <div
            className="wrapper"
            onClick={(e) => handleClose(e)}>
            {open ? (
              <svg
                key={2}
                width="30"
                height="31"
                viewBox="0 0 30 31"
                fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <rect
                  y="0.5"
                  width="30"
                  height="30"
                  rx="5"
                  fill="#18181A"
                />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M8.25918 7.86603L14.9996 14.6062L21.7139 7.89214L21.7203 7.88532C21.8038 7.79648 21.9044 7.7254 22.016 7.67636C22.1266 7.62777 22.2457 7.60181 22.3665 7.59998C22.6401 7.60089 22.9023 7.70997 23.0958 7.90349C23.2902 8.09782 23.3992 8.36139 23.3992 8.63622L23.3995 8.64762C23.4017 8.76649 23.3797 8.88457 23.3346 8.99461C23.2896 9.10465 23.2226 9.20433 23.1376 9.28753L16.341 15.9966L23.1333 22.7886L23.138 22.7932C23.2952 22.947 23.3884 23.1544 23.3993 23.3737C23.3963 23.6445 23.2875 23.9035 23.0958 24.0952C22.9015 24.2895 22.6379 24.3987 22.363 24.3987H22.3506L22.3382 24.3992C22.2127 24.4044 22.0876 24.3835 21.9707 24.3377C21.8541 24.2921 21.7483 24.2228 21.6598 24.1342L21.6591 24.1335L15.0024 17.3897L8.27548 24.1162L8.26874 24.1232C8.18558 24.2091 8.08624 24.2776 7.97645 24.3249C7.86775 24.3717 7.75094 24.3968 7.63263 24.3987C7.35906 24.3977 7.0969 24.2887 6.9034 24.0952C6.70905 23.9008 6.59998 23.6373 6.59998 23.3624L6.59977 23.351C6.59751 23.2322 6.61956 23.1141 6.66458 23.004C6.7096 22.894 6.77663 22.7943 6.86157 22.7111L13.6582 16.0021L6.86596 9.21003L6.86126 9.20543C6.70406 9.05164 6.61082 8.84428 6.59994 8.62491C6.60289 8.35418 6.71172 8.09516 6.9034 7.90349C7.09684 7.71005 7.35891 7.60098 7.63239 7.59998C7.86705 7.60387 8.09242 7.69928 8.25918 7.86603Z"
                  fill="#FAFAFC"
                />
              </svg>
            ) : (
              <svg
                key={3}
                width="30"
                height="31"
                viewBox="0 0 30 31"
                fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <rect
                  y="0.5"
                  width="30"
                  height="30"
                  rx="5"
                  fill="#18181A"
                />
                <path
                  d="M19.0223 18.4598H18.1994L17.9078 18.1786C18.5587 17.4223 19.0345 16.5315 19.301 15.5698C19.5675 14.6082 19.6182 13.5996 19.4494 12.6161C18.9598 9.72025 16.5432 7.40775 13.6265 7.05358C12.6011 6.92386 11.5596 7.03043 10.5817 7.36513C9.60386 7.69984 8.7155 8.25381 7.98466 8.98466C7.25381 9.7155 6.69984 10.6039 6.36513 11.5817C6.03043 12.5596 5.92386 13.6011 6.05358 14.6265C6.40775 17.5432 8.72025 19.9598 11.6161 20.4494C12.5996 20.6182 13.6082 20.5675 14.5698 20.301C15.5315 20.0345 16.4223 19.5587 17.1786 18.9078L17.4598 19.1994V20.0223L21.8869 24.4494C22.314 24.8765 23.0119 24.8765 23.439 24.4494C23.8661 24.0223 23.8661 23.3244 23.439 22.8973L19.0223 18.4598ZM12.7723 18.4598C10.1786 18.4598 8.08483 16.3661 8.08483 13.7723C8.08483 11.1786 10.1786 9.08483 12.7723 9.08483C15.3661 9.08483 17.4598 11.1786 17.4598 13.7723C17.4598 16.3661 15.3661 18.4598 12.7723 18.4598Z"
                  fill="#FAFAFC"
                />
              </svg>
            )}
          </div>
          {!open && (
            <motion.p
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 0.5, ease: 'easeInOut' }}
              className="search">
              search
            </motion.p>
          )}
        </Button>
      </AnimatePresence>
    </SearchContainer>
  );
}
