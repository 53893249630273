import { useEffect, useRef } from 'react';
import Viewer from 'viewerjs';
import 'viewerjs/dist/viewer.css';
import { Container } from './ImageViewer.style';
export const ImageViewer = ({ Images }) => {
    const viewerRef = useRef(null);
    useEffect(() => {
        if (viewerRef.current) {
            const viewer = new Viewer(viewerRef.current, {
                // Options for Viewer.js (if needed)
                // For example: options for zooming, navigation, etc.
            });

            return () => {
                viewer.destroy();
            };
        }
    }, [Images]);

    return (
        <Container ref={viewerRef} id='Floor_plan'>
            <h1 className='layouts_heading'>Layouts</h1>
            <div className="layouts_container">
                {Images?.map((data, index) => (
                    <img src={`https://dev.redbuyers.com${data.file_url}`} alt={`${index + 1}`} key={index} />
                ))}
            </div>
        </Container>
    );
};