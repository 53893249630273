/** @format */

export const PrevButton = (currentStep, setCurrentStep, steps) => {
  const first = currentStep === 0;
  return (
    <button
      className="Button_react_Tour"
      disabled={first}
      onClick={() => {
        setCurrentStep((s) => s - 1);
      }}
      style={first ? { outline: 'none', cursor: 'not-allowed' } : {}}>
      <div className="svg_wrapper">
        <svg
          width="6"
          height="10"
          viewBox="0 0 6 10"
          fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M0.229065 4.45975C0.0823948 4.6006 -2.49171e-07 4.79161 -2.57877e-07 4.99077C-2.66582e-07 5.18993 0.0823948 5.38094 0.229065 5.52179L4.65489 9.7707C4.72706 9.84244 4.81339 9.89966 4.90884 9.93902C5.0043 9.97839 5.10696 9.9991 5.21084 9.99997C5.31472 10.0008 5.41774 9.98183 5.51389 9.94407C5.61004 9.9063 5.69739 9.85053 5.77085 9.78001C5.84431 9.70949 5.9024 9.62563 5.94174 9.53332C5.98108 9.44102 6.00087 9.34211 5.99997 9.24238C5.99907 9.14266 5.97749 9.0441 5.93648 8.95246C5.89548 8.86083 5.83588 8.77795 5.76115 8.70866L1.88846 4.99077L5.76115 1.27288C5.90367 1.13122 5.98253 0.941496 5.98074 0.744562C5.97896 0.547629 5.89668 0.359247 5.75162 0.219989C5.60657 0.0807304 5.41034 0.00173852 5.20521 2.76218e-05C5.00008 -0.00168328 4.80245 0.0740222 4.65489 0.210838L0.229065 4.45975Z"
            fill="black"
          />
        </svg>
      </div>
      Back
    </button>
  );
};
