/** @format */

import PricingbillingHeader from '../Pricing&billingHeader/Pricing&billingHeader';
import AddOn from './Images/add_on.svg';
import RightArrow from './Images/arrow-right.svg';
import Crown from './Images/crown.svg';
import Icon from './Images/Ellipse.svg';
import Hero from './Images/hero.png';
import Quotes from './Images/quotes.svg';
import './PricingBilling.css';
import BillingHistory from './pricingcomponents/BillingHistory';
import CurrentPlan from './pricingcomponents/CurrentPlan';

export default function PricingBiling() {
  const primiumcard = (
    <div className="Card">
      <div className="plan_name d-flex gap-3">
        <div className="wrapper">
          <img
            src={Crown}
            alt="crown"
          />
        </div>
        <div className="plan_details">
          <p className="heading_detail">Premium</p>
          <p className="heading_second">For the professionals</p>
        </div>
      </div>
      <div className="bar"></div>
      <div className="price">
        <p>
          $28<span className="heading_second lower_case">/month</span>
        </p>
      </div>
      <div className="list">
        <div className="points">
          <div className="wrapper">
            <img
              src={Icon}
              alt="icon"
            />
          </div>
          <p>Everything in Standard Plan </p>
        </div>
        <div className="points">
          <div className="wrapper">
            <img
              src={Icon}
              alt="icon"
            />
          </div>
          <p>
            Home valuation <span>(With comparable homes)</span>
          </p>
        </div>
        <div className="points">
          <div className="wrapper">
            <img
              src={Icon}
              alt="icon"
            />
          </div>
          <p>CRM Integration </p>
        </div>
        <div className="points">
          <div className="wrapper">
            <img
              src={Icon}
              alt="icon"
            />
          </div>
          <p>Sold Listing & New Construction Data </p>
        </div>
        <div className="points">
          <div className="wrapper">
            <img
              src={Icon}
              alt="icon"
            />
          </div>
          <p>Advanced Template customization </p>
        </div>
      </div>
      <button>
        Choose Plan
        <img
          src={RightArrow}
          alt="rightarrow"
        />
      </button>
    </div>
  );
  const addoncard = (
    <div className="Card">
      <div className="plan_name d-flex gap-3">
        <div className="wrapper">
          <img
            src={AddOn}
            alt="addon"
          />
        </div>
        <div className="plan_details">
          <p className="heading_detail">Add-ons</p>
          <p className="heading_second">For the professionals</p>
        </div>
      </div>
      <div className="bar"></div>
      <div className="price">
        <p>
          $20<span className="heading_second lower_case">/month</span>
        </p>
      </div>
      <div className="list">
        <div className="points up">
          <div className="wrapper">
            <img
              src={Icon}
              alt="icon"
            />
          </div>
          <div className="pointwrapper">
            <p>Social Media Marketing </p>
            <span className="block">Facebook</span>
            <span className="block">Instagram</span>
            <span className="block">Tiktok</span>
            <span className="block">YouTube</span>
          </div>
        </div>
        <div className="points">
          <div className="wrapper">
            <img
              src={Icon}
              alt="icon"
            />
          </div>
          <p>Google Ads and SEO</p>
        </div>
        <div className="points">
          <div className="wrapper">
            <img
              src={Icon}
              alt="icon"
            />
          </div>
          <p>CRM Integration </p>
        </div>
      </div>
      <button>
        Choose Plan
        <img
          src={RightArrow}
          alt="rightarrow"
        />
      </button>
    </div>
  );
  return (
    <div className="Container pricing_billing_container">
      <PricingbillingHeader />
      <div className="main_container">
        <div className="up_section ">
          <div className="currentplan_container ">
            <CurrentPlan />
          </div>
          <div className="billing_history ">
            <BillingHistory />
          </div>
        </div>
        <div className="down_section">
          <p className="heading">Upgrade your current plan:</p>
          <div className="plan_container d-flex justify-content-between grid-pricing-layout">
            {primiumcard}
            {addoncard}
            <div className="image_section">
              <img
                src={Hero}
                alt="Hero"
              />
              <div className="content">
                <img
                  src={Quotes}
                  alt=""
                />
                <h1>
                  Lorem ipsum dolor sit amet consectetur. <br></br> Pulvinar
                  amet ornare in et. Faucibus morbi.
                </h1>
                <p>Hank Hill</p>
                <h2 className="heading_second">Hank Real Estates</h2>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
