/** @format */

import { useNavigate } from 'react-router-dom';
import ImageNotFound from '../../../../images/image-not-found-icon.svg';
import { ConvertTime } from '../../../../utils/timezoneconvertor';
import { BlogCardContainer } from './blogcard.style';
type BlogCardProps = {
  data?: Blog;
};
type Blog = {
  coverImage: string;
  createdAt: string;
  description: string;
  keywords: string;
  metaDescription: string;
  metaTitle: string;
  slug: string;
  title: string;
  updatedAt: string;
  userId: string;
  _id: string;
};
export default function BlogCardT3({ data }: BlogCardProps) {
  const navigate = useNavigate();
  const Redirect = (id) => {
    if (id) {
      navigate(`../blog_list/blogview/?id=${id}`);
    }
  };

  return (
    <BlogCardContainer>
      <div className="blog_image_container">
        <img
          src={
            data?.coverImage?.length > 0
              ? `/public/${data?.coverImage}`
              : ImageNotFound
          }
          alt="presentation"
        />
      </div>
      <p className="heading">{data?.title}</p>
      <p
        className="description"
        dangerouslySetInnerHTML={{ __html: data?.description }}></p>
      <div className="desc_container">
        <div
          className="redirect"
          onClick={() => Redirect(data?._id)}>
          <svg
            width="22"
            height="22"
            viewBox="0 0 22 22"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
              d="M18 19.1667H4C3.35833 19.1667 2.83333 18.6417 2.83333 18V4C2.83333 3.35833 3.35833 2.83333 4 2.83333H9.83333C10.475 2.83333 11 2.30833 11 1.66667C11 1.025 10.475 0.5 9.83333 0.5H2.83333C2.21449 0.5 1.621 0.745833 1.18342 1.18342C0.745833 1.621 0.5 2.21449 0.5 2.83333V19.1667C0.5 20.45 1.55 21.5 2.83333 21.5H19.1667C20.45 21.5 21.5 20.45 21.5 19.1667V12.1667C21.5 11.525 20.975 11 20.3333 11C19.6917 11 19.1667 11.525 19.1667 12.1667V18C19.1667 18.6417 18.6417 19.1667 18 19.1667ZM13.3333 1.66667C13.3333 2.30833 13.8583 2.83333 14.5 2.83333H17.5217L6.87 13.485C6.65186 13.7031 6.52931 13.999 6.52931 14.3075C6.52931 14.616 6.65186 14.9119 6.87 15.13C7.08814 15.3481 7.384 15.4707 7.6925 15.4707C8.001 15.4707 8.29686 15.3481 8.515 15.13L19.1667 4.47833V7.5C19.1667 8.14167 19.6917 8.66667 20.3333 8.66667C20.975 8.66667 21.5 8.14167 21.5 7.5V1.66667C21.5 1.025 20.975 0.5 20.3333 0.5H14.5C13.8583 0.5 13.3333 1.025 13.3333 1.66667Z"
              fill="#2C3647"
            />
          </svg>
        </div>
        <div className="blog_info">
          <p className="read_time">4 min Read</p>
          <p className="date">{ConvertTime(data?.updatedAt)}</p>
        </div>
      </div>
    </BlogCardContainer>
  );
}
