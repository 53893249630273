/** @format */

import styled from 'styled-components';
import { setLightness } from 'polished';
type TypesProps = {
  check?: boolean;
};

export const FilterContainer = styled.div`
  flex: 1;
  width: 100%;
  p.Heading_mobile {
    color: ${(props) =>
      props?.theme?.colors?.primary
        ? props?.theme?.colors?.primary
        : 'var(--primary)'};
    font-family: Inter;
    font-size: 1rem;
    font-size: clamp(0.75rem, 1rem + 1vw, 1.2rem);
    font-weight: 500;
    display: none;
    gap: 0.2rem;
    align-items: center;
    span {
      color: var(--Text-Grey, #6e6e73);
      font-family: Inter;
      font-size: 0.875rem;
      font-weight: 500;
      line-height: 1.0588rem;
      text-align: left;
    }
    @media screen and (max-width: 1035px) {
      display: flex;
    }
  }
  p {
    margin: 0;
  }
  .close_button {
    background: ${(props) =>
      props.theme.colors.primary
        ? setLightness(0.6, props.theme.colors.primary)
        : 'grey'};
    width: 1.75rem;
    height: 1.75rem;
    padding: 0.5613rem 0.5619rem 0.5637rem 0.5613rem;
    border-radius: 0.3125rem;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
  }
  .SortingContainers {
    display: flex;
    gap: 1rem;
    max-width: 120rem;
    margin: 0 auto;
    .SortingContainer {
      position: relative;
    }
    @media screen and (max-width: 1035px) {
      justify-content: space-between;
    }
  }
  .Filters {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.7rem;
    width: fit-content;
    border-radius: 0.3125rem;
    padding: 0.9375rem;
    /* box-shadow: 1px 1px 1px 1px rgba(0, 0, 0, 0.5); */

    p {
      color: ${(props) =>
        props?.theme?.colors?.primary
          ? props?.theme?.colors?.primary
          : 'var(--primary)'};
      font-family: Inter;
      font-size: 0.875rem;
      font-weight: 600;
    }
    &:hover {
      cursor: pointer;
      background: #f2f2f2;
    }
    svg {
      path {
        /* stroke: ${(props) =>
          props?.theme?.colors?.primary
            ? props?.theme?.colors?.primary
            : 'var(--primary)'}; */
        fill:${(props) =>
    props?.theme?.colors?.primary
      ? props?.theme?.colors?.primary
      : 'var(--primary)'};
      }
    }
  }
  .FilterContainer {
    border-radius: 0rem 0rem 0.9375rem 0.9375rem;
    background: #fff;
    box-shadow: 0px 0px 2px 0px #00000040;
    /* box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1); */
    padding: 1rem;
    width: min(100vw - 1.5rem, 35rem);
    position: absolute;
    top: 120%;
    left: 0;
    z-index: 1000;
    h1 {
      color: #6e6e73;
      font-family: Inter;
      font-size: 0.875rem;
      font-weight: 500;
    }
    .Filter {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .button_group_filter {
        display: flex;
        align-items: center;
        gap: 1rem;
        @media screen and (max-width: 1035px) {
          width: 100%;
          justify-content: space-between;
        }
      }
      .Heading {
        color: ${(props) =>
          props?.theme?.colors?.primary
            ? props?.theme?.colors?.primary
            : 'var(--primary)'};
        font-family: Inter;
        font-size: 1rem;
        font-size: clamp(0.75rem, 1rem + 1vw, 1.2rem);
        font-weight: 500;
        display: flex;
        gap: 0.2rem;
        align-items: center;
        span {
          color: var(--Text-Grey, #6e6e73);
          font-family: Inter;
          font-size: 0.875rem;
          font-weight: 500;
          line-height: 1.0588rem;
          text-align: left;
        }
        @media screen and (max-width: 1035px) {
          display: none;
        }
      }
    }
    .Types {
      display: flex;
      justify-content: flex-start;
      flex-wrap: wrap;
      align-items: center;
      gap: 0.2rem;
      margin-top: 0.75rem;
    }
    .Combo-bedBath,
    .PriceRange,
    .BuildingStatus,
    .HouseType {
      margin-top: 2rem;
    }
    .Combo-bedBath {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    .Reset {
      text-decoration: underline;
      cursor: pointer;
      color: ${(props) =>
        props?.theme?.colors?.primary
          ? props?.theme?.colors?.primary
          : 'var(--primary)'};
    }
  }
  .SortBy {
    background: #fff;
    box-shadow: 0px 0px 2px 0px #00000040;
    padding: 1rem;
    position: absolute;
    top: 120%;
    right: 0;
    width: max-content;
    z-index: 1000;
    .close_sortBy {
      margin-left: 85%;
      margin-bottom: 0.25rem;
    }
    .sortByContainer {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 0.75rem;
    }
  }
  .ShareButton {
    border-radius: 0.9375rem;
    background: ${(props) =>
      props?.theme?.colors?.primary
        ? props?.theme?.colors?.primary
        : 'var(--primary)'};
    /* box-shadow: 1px 1px 4px 0px rgba(0, 97, 223, 0.3); */
    border: none;
    padding: 0.75rem;
    color: #ffffff;
    margin-left: auto;
    margin-right: 3rem;
    cursor: pointer;
  }
  @media screen and (max-width: 536px) {
    width: 100%;
    .SortingContainers {
      justify-content: space-between;
      align-items: center;
      .Filters {
        p {
          display: none;
        }
        .drop-down {
          display: none;
        }
      }
      .browse_homes {
        margin: 0;
        order: 2;
      }
      .sorte {
        order: 3;
      }
      /* .filter {
        order: 1;
      } */
      .SortBy {
        right: -1.5rem;
        width: 22rem;
      }
      .SortingContainer {
        .FilterContainer {
          left: -1.5rem;
          width: 100vw;
        }
      }
    }
  }
`;
export const PriceSelector = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.7rem;
`;

export const SortByElements = styled.button<TypesProps>`
  background-color: ${(props) =>
    props.check
      ? props?.theme?.colors?.primary || 'var(--primary)'
      : '#f2f2f2'};
  color: ${(props) =>
    props.check ? '#fff' : props?.theme?.colors?.primary || 'var(--primary)'};
  font-family: Inter;
  font-size: 1.125rem;
  font-size: clamp(0.75rem, 1rem + 1vw, 1rem);
  font-weight: 500;
  border: none;
  outline: none;
  padding: 0.31rem 1.25rem;
`;

export const Types = styled.button<TypesProps>`
  background: #f2f2f2;
  background-color: ${(props) =>
    props.check
      ? props?.theme?.colors?.primary || 'var(--primary)'
      : '#f2f2f2'};
  color: ${(props) =>
    props.check ? '#fff' : props?.theme?.colors?.primary || 'var(--primary)'};
  font-family: Inter;
  font-size: 1.125rem;
  font-size: clamp(0.75rem, 1rem + 1vw, 1rem);
  font-weight: 500;
  border: none;
  outline: none;
  padding: 0.31rem 1.25rem;
`;
