/** @format */

import './Pricing&billingHeader.css';
export default function PricingbillingHeader() {
  return (
    <nav className="d-flex align-items-center">
      <h1 className="heading">Pricing</h1>
      <div className="PricingContainer d-flex gap-3 pricing-header-right">
        <div className="pricing d-flex align-items-center gap-2">
          <div className="wrapper">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="25"
              height="25"
              viewBox="0 0 25 25"
              fill="none">
              <path
                d="M20.833 4.1665H4.16634C3.01009 4.1665 2.09342 5.09359 2.09342 6.24984L2.08301 18.7498C2.08301 19.9061 3.01009 20.8332 4.16634 20.8332H20.833C21.9893 20.8332 22.9163 19.9061 22.9163 18.7498V6.24984C22.9163 5.09359 21.9893 4.1665 20.833 4.1665ZM19.7913 18.7498H5.20801C4.63509 18.7498 4.16634 18.2811 4.16634 17.7082V12.4998H20.833V17.7082C20.833 18.2811 20.3643 18.7498 19.7913 18.7498ZM20.833 8.33317H4.16634V7.2915C4.16634 6.71859 4.63509 6.24984 5.20801 6.24984H19.7913C20.3643 6.24984 20.833 6.71859 20.833 7.2915V8.33317Z"
                fill="#FAFAFC"
              />
            </svg>
          </div>
          <p>Pay bill now</p>
        </div>
        <div className="upgrade d-flex align-items-center gap-2">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="25"
            height="25"
            viewBox="0 0 25 25"
            fill="none">
            <path
              d="M9.575 9.6875C9.74069 9.6875 9.875 9.82181 9.875 9.9875V16.7C9.875 16.8657 10.0093 17 10.175 17H12.825C12.9907 17 13.125 16.8657 13.125 16.7V9.9875C13.125 9.82181 13.2593 9.6875 13.425 9.6875H17.2016C17.4786 9.6875 17.6076 9.34414 17.3991 9.16173L11.6976 4.17286C11.5844 4.07389 11.4156 4.07389 11.3024 4.17286L5.60088 9.16173C5.39241 9.34414 5.52143 9.6875 5.79843 9.6875H9.575Z"
              fill="#FAFAFC"
            />
            <path
              d="M18 21.0332V18.7443C18 18.5787 17.8657 18.4443 17.7 18.4443H5.3C5.13431 18.4443 5 18.5787 5 18.7443V21.0332C5 21.1989 5.13431 21.3332 5.3 21.3332H17.7C17.8657 21.3332 18 21.1989 18 21.0332Z"
              fill="#FAFAFC"
            />
            <path
              d="M18.0068 14.558C17.8176 14.1097 17.1824 14.1097 16.9932 14.558L16.0951 16.6868C16.0896 16.7001 16.0785 16.7103 16.0649 16.7148L13.7748 17.4782C13.2733 17.6454 13.2733 18.3546 13.7748 18.5218L16.0649 19.2852C16.0785 19.2897 16.0896 19.2999 16.0951 19.3132L16.9932 21.442C17.1824 21.8903 17.8176 21.8903 18.0068 21.442L18.9049 19.3132C18.9104 19.2999 18.9215 19.2897 18.9351 19.2852L21.2252 18.5218C21.7267 18.3546 21.7267 17.6454 21.2252 17.4782L18.9351 16.7148C18.9215 16.7103 18.9104 16.7001 18.9049 16.6868L18.0068 14.558Z"
              fill="#FAFAFC"
              stroke="#18181A"
              stroke-width="0.5"
            />
          </svg>
          <p>Upgrade</p>
        </div>
      </div>
    </nav>
  );
}
