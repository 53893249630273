

/**
 * Converts a timestamp string into a formatted time and date string.
 * @param timestamp - The timestamp string to convert.
 * @returns A string containing the formatted time and date.
 */
export const ConvertTime = (timestamp: string) => {
    // Create a Date object from the timestamp
    const dateObj = new Date(timestamp);

    // Format the time
    const hours = dateObj.getUTCHours();
    const minutes = dateObj.getUTCMinutes();
    const formattedTime = `${hours % 12 || 12}:${minutes < 10 ? '0' : ''}${minutes} ${hours >= 12 ? 'PM' : 'AM'}`;

    // Format the date with type assertion in a single line
    const formattedDate = dateObj.toLocaleDateString('en-US', { month: 'long', day: 'numeric', year: 'numeric' } as Intl.DateTimeFormatOptions);

    // Combine the formatted time and date
    const finalResult = `${formattedTime} - ${formattedDate}`;

    return finalResult;
}
