/** @format */

import { transparentize } from 'polished';
import Slider from 'react-slick';
import styled from 'styled-components';

export const SoldPropertyError = styled.div`
  position: absolute;
  top: 34%;
  left: 0;
  width: 100%;
  height: 50%;
  z-index: 10;
  display: flex;
  justify-content: center;
  align-items: center;

  .info_content_sold_property {
    background-color: black;
    padding-block: 1rem;
    width: 94%;
    margin: 0 auto;
    border-radius: 0.3125rem;
    text-align: center;
  }

  .icon_lock {
    font-size: 1.5rem;
    color: black;
    margin-bottom: 0.5rem;
  }

  .error_info {
    background-color: rgba(0, 0, 0, 0.8);
    color: white;
    padding: 0.5rem;
    border-radius: 0.3125rem;
    margin: auto 0.5rem;
  }
`;

export const SliderContainer = styled(Slider)`
  .slick-dots {
    li {
      width: 7px;
      margin: 0 2px;
      button::before {
        color: #eef0f4;
        opacity: 1;
      }
    }
  }
  .slick-prev {
    left: 7px;
    z-index: 8;
  }
  .slick-next {
    right: 7px;
  }
  .slick-dots {
    bottom: 13px;
  }
  .slick-track {
    padding: 0;
    .slick-slide {
      height: 17.6875rem;
      div {
        height: 100%;
      }
    }
  }
`;

export const PropertyInformation = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  .price_address {
    .price {
      font-size: 2.375rem;
      font-size: clamp(1rem, 2vw, 2.375rem);
      font-weight: 700;
      line-height: 2.8744rem;
      margin-bottom: 0.25rem;
    }
    .company {
      border-top: 1px solid black;
      border-bottom: 1px solid black;
      padding-left: 0.25rem;
      .property_name {
        margin: 0;
        font-family: Inter;
        font-size: 18px;
        font-size: clamp(0.8rem, 1vw, 1.125rem);
        font-weight: 500;
        line-height: 21.78px;
        text-align: left;
        color: ${(props) => props.theme?.colors?.primary};
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
      }
      .property_company {
        margin: 0;
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
      }
    }
    .address {
      font-size: 1.125rem;
      font-size: clamp(0.8rem, 1vw, 1.125rem);
      font-weight: 400;
      line-height: 1.3613rem;
      overflow: hidden;
      width: 100%;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      margin: 0;
    }
  }
  .search_icon {
    display: none;
    min-height: 4.5rem;
    min-width: 4.5rem;
    border-radius: 100%;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    &:hover {
      background: ${({ theme: { colors } }) => colors?.primary};
      svg {
        path {
          fill: white;
        }
      }
    }
  }
`;

export const PropertyInfoContainer = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  margin-top: 0;
  /* margin-inline: 0.25rem; */
  .property_info {
    margin: 0;
    font-size: 18px;
    font-size: clamp(0.8rem, 1vw, 1.125rem);
    font-weight: 500;
    line-height: 21.78px;
    text-align: left;
  }
  .bed,
  .bath,
  .gar {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    svg {
      display: none;
    }
  }
`;

export const HoverSection = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  z-index: 100;
  p {
    border: 1px solid
      ${(props) =>
        props?.theme?.colors?.primary
          ? props.theme?.colors?.primary
          : 'var(--primary)'};
    color: ${(props) =>
      props?.theme?.colors?.primary
        ? props?.theme.colors?.primary
        : 'var(--primary)'};
    text-decoration: none;
    align-items: center;
    padding-block: 0.55rem;
    padding-inline: 0.65rem;
    z-index: 100;
  }
`;

export const BuilderPropertyCompany = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: ${(props) =>
    props?.theme?.colors?.primary &&
    transparentize(0.5, props.theme.colors.primary)};
  padding: 0.625rem 0.75rem 0.625rem 0.75rem;
  gap: 1rem;
  .Heading {
    font-family: Inter;
    font-size: 18px;
    font-size: clamp(0.8rem, 1vw, 1.125rem);

    font-weight: 500;
    line-height: 21.78px;
    text-align: left;
    margin-bottom: 0;
    color: ${(props) => props?.theme?.colors?.primary};
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
  .SubHeading {
    font-family: Inter;
    font-size: 18px;
    font-size: clamp(0.8rem, 1vw, 1.125rem);

    font-weight: 400;
    /* line-height: 21.78px;*/
    text-align: left;
    margin-bottom: 0;
    color: white;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
  .builder_price {
    font-family: Inter;
    font-size: 2.375rem;
    font-size: clamp(1rem, 2vw, 2.375rem);
    font-weight: 400;
    /* line-height: 21.78px;*/
    text-align: left;
    margin-bottom: 0;
    color: white;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
  .left_section {
    flex: 0.3;
  }
  .divider {
    width: 0.11rem;
    height: 3.0625rem;
    gap: 0rem;
    border-radius: 0.625rem;
    background-color: white;
    display: block;
  }
  .right_section {
    flex: 0.7;
  }
`;

export const SoldPropertyInfoContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-inline: 1rem;
  padding-block: 0.45rem;
  background-color: ${(props) =>
    props?.theme?.colors?.primary &&
    transparentize(0.8, props.theme.colors.primary)};
  .container_stat {
    .sold_stat_header {
      color: ${(props) => props?.theme?.colors?.primary};
      font-weight: bold;
    }
    .sold_stat {
      color: black;
    }
  }
`;
