/** @format */

import { ChangeEvent, useEffect, useState } from "react";

import { InputBoxContainer, SearchCityContainer } from "./citysearch.styl";

import { useLocation } from "react-router";
import { useFilterContext } from "../../../context/filter.context";

import { useNavigate } from "react-router-dom";

import DropDownBox from "./components/Dropdown/dropdown.component";
import useSearchDropDown from "./useSearchDropDown.customhook";

type CitysearchProps = {
  lease_sale?: true | false;
  cities?: [{ id: string; city: string }] | null;
  types: "idx" | "sold" | "builder_deals" | "template3";
};

export default function Citysearch({
  lease_sale = true,
  cities = null,
  types,
}: CitysearchProps) {
  //get city
  // const[city,setCity]=useState<{city:string}[]>(null);
  // const[community,setCommunity]=useState<{community:string}[]>(null)
  // const [filterCity, setFilterCity] = useState([])
  // const [filterCommunity, setFilterCommunity] = useState([])
  // const [items, setItems] = useState([]);
  // const [inputValue, setInputValue] = useState<string>('');
  // const [filteredItems, setFilteredItems] = useState([]);
  // useEffect(() => {
  //   const source = axios.CancelToken.source();
  //   const getCity = async () => {
  //     try {
  //       const city = await axios.get(
  //         'https://valuation.santhoshmathew.com/api/v1/cities',
  //         {
  //           cancelToken: source.token,
  //         }
  //       );
  //       let concatenatedArray:{city:string}[] = [].concat(...city.data.data);
  //       setCity(concatenatedArray);
  //     } catch (e) {
  //       throw new Error(e.message);
  //     }
  //   };
  //   getCity();
  //   return () => {
  //     source.cancel('Api call got canceled by cleanup.');
  //   };
  // }, []);

  // //get community
  // useEffect(() => {
  //   const source = axios.CancelToken.source();
  //   const getCommunity = async () => {
  //     try {
  //       const city = await axios.get(
  //         'https://valuation.santhoshmathew.com/api/v1/communities',
  //         {
  //           cancelToken: source.token,
  //         }
  //       );
  //       let concatenatedArray:{community:string}[] = [].concat(...city.data.data);
  //       setCommunity(concatenatedArray);
  //     } catch (e) {
  //       throw new Error(e.message);
  //     }
  //   };
  //   getCommunity();
  // }, []);
  // const debouncedHandleInputChange = useCallback(
  //   _.debounce((value) => {
  //     handleFilterCity(value)
  //     handleFilterCommunity(value)
  //   }, 1500),
  //   [city,community]
  // );
  // const handleFilterCity = (search: string) => {
  //   const CheckThisString = search.toLowerCase();
  //   if (CheckThisString?.length > 0) {
  //     const filterData = city.filter((item: { city: string }) =>
  //       item.city.toLowerCase().includes(CheckThisString)
  //     );
  //     // if (filterData?.length > 0) {
  //       setFilterCity(filterData)
  //     // }
  //   }else{
  //     setFilterCity([])
  //   }
  // };

  // const handleFilterCommunity = (search: string) => {
  //   const CheckThisString = search.toLowerCase();
  //   if (CheckThisString?.length > 0) {
  //     const filterData = community.filter((item: { community: string }) =>
  //       item.community.toLowerCase().includes(CheckThisString)
  //     );
  //     if (filterData?.length > 0) {
  //       setFilterCommunity(filterData);
  //     }
  //   }else{
  //     setFilterCommunity([])
  //   }
  // };
  // const handleSubmitForm = (e) => {
  //   let cityCode = 0;
  //   if (filteredItems.length === 1) {
  //     cityCode = filteredItems[0].id;
  //   }
  //   handleItemClick(inputValue, cityCode, e);
  // };
  const { data, search, onChange, filterCity, filterCommunity, loader } =
    useSearchDropDown();
  const [showDropdown, setShowDropdown] = useState<boolean>(true);
  const { setProperty } = useFilterContext();
  const location = useLocation();
  const navigate = useNavigate();

  const getQueryParams = () => {
    return new URLSearchParams(location.search);
  };

  const params = getQueryParams();
  const { state } = useFilterContext();

  const handleNavigation = (e: any, item: string, citycode?: number): void => {
    e.preventDefault();
    if (item === "No Data") {
      return;
    }
    if (filterCity?.length === 0 && filterCommunity?.length === 0) {
      setProperty("streetSearch", item);
      // handleOnChange(item);
      return;
    }
    if (types !== "template3") {
      if (cities !== null) {
        setProperty("city", citycode);
      }
      setProperty("search", item);
      handleOnChange(item);
      setProperty("streetSearch", null);
    } else {
      navigate(`../idxlisting/?city=${item}`);
    }
  };

  useEffect(() => {
    const City = params.get("city");
    if (City) {
      setProperty("search", City);
    }
  }, []);

  const handleOnChange = (e: string) => {
    onChange(e);
  };

  const handleInputFocus = () => {
    setShowDropdown(true);
  };
  const handleInputBlur = () => {
    setShowDropdown(false);
  };
  return (
    <SearchCityContainer
      className="search-container"
      onSubmit={(e) => handleNavigation(e, search)}
      onMouseEnter={handleInputFocus}
      onMouseLeave={handleInputBlur}
    >
      <InputBoxContainer>
        <div className="svg_wrapper">
          <svg
            width="19"
            height="19"
            viewBox="0 0 19 19"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M9.49935 9.50016C9.93477 9.50016 10.3076 9.345 10.618 9.03466C10.9278 8.72486 11.0827 8.35225 11.0827 7.91683C11.0827 7.48141 10.9278 7.10854 10.618 6.7982C10.3076 6.4884 9.93477 6.3335 9.49935 6.3335C9.06393 6.3335 8.69132 6.4884 8.38152 6.7982C8.07118 7.10854 7.91602 7.48141 7.91602 7.91683C7.91602 8.35225 8.07118 8.72486 8.38152 9.03466C8.69132 9.345 9.06393 9.50016 9.49935 9.50016ZM9.49935 17.12C9.39379 17.12 9.28824 17.1002 9.18268 17.0606C9.07713 17.021 8.98477 16.9682 8.9056 16.9022C6.97921 15.2002 5.54102 13.6203 4.59102 12.1625C3.64102 10.7043 3.16602 9.34183 3.16602 8.07516C3.16602 6.096 3.80278 4.51926 5.07631 3.34495C6.34931 2.17065 7.82365 1.5835 9.49935 1.5835C11.175 1.5835 12.6494 2.17065 13.9224 3.34495C15.1959 4.51926 15.8327 6.096 15.8327 8.07516C15.8327 9.34183 15.3577 10.7043 14.4077 12.1625C13.4577 13.6203 12.0195 15.2002 10.0931 16.9022C10.0139 16.9682 9.92157 17.021 9.81602 17.0606C9.71046 17.1002 9.6049 17.12 9.49935 17.12Z"
              fill="#13131A"
            />
          </svg>
        </div>
        <input
          type="text"
          value={search}
          // onChange={handleInputChange}
          onChange={(e) => handleOnChange(e.target.value)}
          placeholder="Search..."
          className="input_box"
        />
        <div className="window_button">
          {lease_sale && (
            <select
              className="select_option"
              value={state.sale_or_lease}
              onChange={(e) => setProperty("sale_or_lease", e.target.value)}
            >
              <option value="Sale">Buy</option>
              <option value="Lease">Rent</option>
            </select>
          )}

          <button className="search_city" type="submit">
            Search
          </button>
          <div className="search_icon">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="32"
              height="33"
              viewBox="0 0 32 33"
              fill="none"
            >
              <path
                d="M12.9143 4.5C15.2785 4.5 17.5459 5.43918 19.2176 7.11093C20.8894 8.78269 21.8286 11.0501 21.8286 13.4143C21.8286 15.4928 21.1116 17.4133 19.9178 18.9356C19.7918 19.0962 19.8004 19.3266 19.9447 19.471C20.0182 19.5444 20.1178 19.5857 20.2217 19.5857H20.6011C20.948 19.5857 21.2806 19.7235 21.5259 19.9688L26.9714 25.4143C27.5395 25.9824 27.5395 26.9034 26.9714 27.4714C26.4034 28.0395 25.4824 28.0395 24.9143 27.4714L19.4688 22.0259C19.2235 21.7806 19.0857 21.448 19.0857 21.1011V20.7215C19.0857 20.6177 19.0445 20.5182 18.9711 20.4448C18.8268 20.3005 18.5962 20.2921 18.4357 20.4183C16.8641 21.6536 14.9202 22.3284 12.9143 22.3286C10.5501 22.3286 8.28269 21.3894 6.61093 19.7176C4.93918 18.0459 4 15.7785 4 13.4143C4 11.0501 4.93918 8.78269 6.61093 7.11093C8.28269 5.43918 10.5501 4.5 12.9143 4.5ZM12.9143 7.24286C9.48571 7.24286 6.74286 9.98571 6.74286 13.4143C6.74286 16.8429 9.48571 19.5857 12.9143 19.5857C16.3429 19.5857 19.0857 16.8429 19.0857 13.4143C19.0857 9.98571 16.3429 7.24286 12.9143 7.24286Z"
                fill="#0061DF"
              />
              <path
                d="M12.9143 4.5C15.2785 4.5 17.5459 5.43918 19.2176 7.11093C20.8894 8.78269 21.8286 11.0501 21.8286 13.4143C21.8286 15.4928 21.1116 17.4133 19.9178 18.9356C19.7918 19.0962 19.8004 19.3266 19.9447 19.471C20.0182 19.5444 20.1178 19.5857 20.2217 19.5857H20.6011C20.948 19.5857 21.2806 19.7235 21.5259 19.9688L26.9714 25.4143C27.5395 25.9824 27.5395 26.9034 26.9714 27.4714C26.4034 28.0395 25.4824 28.0395 24.9143 27.4714L19.4688 22.0259C19.2235 21.7806 19.0857 21.448 19.0857 21.1011V20.7215C19.0857 20.6177 19.0445 20.5182 18.9711 20.4448C18.8268 20.3005 18.5962 20.2921 18.4357 20.4183C16.8641 21.6536 14.9202 22.3284 12.9143 22.3286C10.5501 22.3286 8.28269 21.3894 6.61093 19.7176C4.93918 18.0459 4 15.7785 4 13.4143C4 11.0501 4.93918 8.78269 6.61093 7.11093C8.28269 5.43918 10.5501 4.5 12.9143 4.5ZM12.9143 7.24286C9.48571 7.24286 6.74286 9.98571 6.74286 13.4143C6.74286 16.8429 9.48571 19.5857 12.9143 19.5857C16.3429 19.5857 19.0857 16.8429 19.0857 13.4143C19.0857 9.98571 16.3429 7.24286 12.9143 7.24286Z"
                stroke="#0061DF"
              />
            </svg>
          </div>
        </div>
      </InputBoxContainer>
      {/* {showDropdown && (
        <ul className="dropdown-list">
          {filteredItems.map((item, index: number) => (
            <li
              key={index}
              onClick={(e) => handleItemClick(item.city || item?.community, item?.id, e)}>
              {item.city || item?.community}
            </li>
          ))}
        </ul>
      )} */}
      {(filterCity?.length > 0 ||
        filterCommunity?.length > 0 ||
        data?.listing?.length > 0) &&
        showDropdown && (
          <DropDownBox
            data={data}
            filterCity={filterCity}
            filterCommunity={filterCommunity}
            handleNavigation={handleNavigation}
            onBlur={handleInputBlur}
            onFocus={handleInputFocus}
            loader={loader}
          />
        )}
    </SearchCityContainer>
  );
}
