/** @format */

import React, { useState } from 'react';
import { ErrorBox, Form, FormContainer } from './LinkingForm.style';
import Spinner from '../../../../../../utils/spinner/spinner.component';
import axios from 'axios';
import Cookies from 'js-cookie';
import { IoMdClose } from 'react-icons/io';
export default function LinkingForm({ setStep }) {
  const [domain, setDomain] = useState('');
  const [loader, setLoader] = useState(false);
  const [error, setError] = useState(false);
  const handleSubmitForm = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const token = Cookies.get('token');
    setLoader(true);
    try {
      const PostDomain = await axios.post(
        '/admin/add-agent-domain',
        {
          domain: domain,
        },
        {
          headers: {
            'x-access-token': token,
          },
        }
      );
      if (PostDomain.status === 200) {
        setStep(4);
      }
    } catch (err) {
      throw new Error(err);
    }finally{
      setLoader(false);
      setError(true)
    }
  };
  return (
    <FormContainer
      initial={{ opacity: 0, y: 100 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: -1000 }}
      transition={{ easeIn: 'easeOut', duration: 0.5, delay: 0.5 }}>
      {!loader ? (
        <div className="Instructions">
          <p className="instruction_heading">Connect your Domain</p>
          <p className="heading">Please enter your domain address</p>
          <Form onSubmit={(e) => handleSubmitForm(e)}>
            <input
              type="text"
              placeholder="Ex: www.santhoshmathew.com"
              className="domain_input_form"
              value={domain}
              required={true}
              onChange={(e) => setDomain(e.target.value)}
            />
            <button className="submit_button">Submit</button>
          </Form>
        </div>
      ) : (
        <Spinner />
      )}
      {error && (
        <ErrorBox>
          <div className="svg_wrap" onClick={()=>setError(false)}>
            <IoMdClose size={25} />
          </div>
          <p className='error'>Something went wrong try again letter</p>
        </ErrorBox>
      )}
    </FormContainer>
  );
}
