/** @format */

import { useEffect, useState } from 'react';
import { Outlet, useParams } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import { useMessageContext } from '../../../context/Message.context';
import GetTemplateData from '../../../utils/apis/GetTemplateData.api';
import NavbarT3 from '../Components/navbar/navbar.t3';
import FooterT3 from '../footer/footer.t3';

export default function IdxWrapperT3() {
  const [data, setData] = useState(null);
  const { preview } = useParams();
  const { setTheme } = useMessageContext();
  useEffect(() => {
    const FetchTemplateData = async () => {
      const data = await GetTemplateData(preview, 3, 1);
      if (data) {
        setData(data);
        setTheme({
          primary_color: data.styles[0].css.primary,
          secondary_color: data.styles[0].css.secondary,
          borderRadius: '16px',
          warning_message: {
            fontFamily: 'Oswald',
            fontSize: '20px',
            fontWeight: '400',
            lineHeight: '23.4px',
          },
          warning_text: {
            fontFamily: 'Roboto',
            fontSize: '18px',
            fontWeight: '400',
            lineHeight: '22.32px',
            textAlign: 'center',
          },
          button: {
            fontFamily: 'Roboto',
            fontSize: '18px',
            fontWeight: '400',
            lineHeight: '23.4px',
          },
        });
      }
    };
    FetchTemplateData();
  }, []);
  const theme = {
    colors: {
      primary: data?.styles[0].css.primary,
      text: data?.styles[0].css.secondary,
      //common
      TextGrey: '#6E6E73',
      white: '#FDFDFE',
      InactiveGrey: '#b2b2b2',
      text2: '#000000',
      background: '#f8f9fa',
      // linear_gradient_hover: lighten(0.25, '#2c3647')
    },
    fonts: {
      primary_font: 'Oswald',
      secondary_font: 'Roboto',
    },
    not_found_page: {
      warning_number: {
        first_letter: '0',
        second_letter: -20,
        third_letter: -18,
        stroke: 2,
        weight: 400,
      },
      button: {
        border_radius: 0.5,
      },
    },
  };
  return (
    <ThemeProvider theme={theme}>
      <NavbarT3 />
      <Outlet />
      <FooterT3 data={data} />
    </ThemeProvider>
  );
}
