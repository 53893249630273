import styled from "styled-components";

export const Container = styled.div`
    width:min(120rem , 100%);
    margin: 0 auto;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
`
export const WarningBox = styled.div`
    min-height: 4rem;
    min-width: 4rem;
    display: flex;
    justify-content: center;
    align-items: center;
    p{
        font-size:clamp(1rem , 2vw , 2rem);
        color: black;
        margin: 0;
    }
`