import CrownFlash from '../Images/crownFlash.svg';
import Ellipse from '../Images/Ellipse.svg';
export default function CurrentPlan() {
  return (
     <div className="currentPlan d-flex justify-content-between">
          <div className="leftSide d-flex gap-3 justify-content-start flex-column">
            <p className="heading" style={{marginLeft: "0px", marginBottom: "0px"}}>Your Current plan:</p>
            <div className="plan d-flex gap-3">
              <div className="wrapper">
                <img
                  src={CrownFlash}
                  alt="CrownFlash"
                />
              </div>
              <div className="planDetails">
                <h1 className="heading_detail">Standard</h1>
                <p className="heading_second">For the professionals</p>
              </div>
            </div>
            <div className="next_billing">
              <p className="heading" style={{marginLeft: "0px"}}>Next billing on:</p>
              <h1 className="heading_detail">December 24th, 2023 </h1>
            </div>
          </div>
          <div className="bar"></div>
          <div className="rightSide">
            <h1 className="heading">What’s included</h1>
            <div className="list">
              <div className="points">
                <img
                  src={Ellipse}
                  alt="Ellipse"
                />
                <p>
                  Website <span>(Without Domain)</span>
                </p>
              </div>
              <div className="points">
                <img
                  src={Ellipse}
                  alt="Ellipse"
                />
                <p>Real Estate Calculators</p>
              </div>
              <div className="points">
                <img
                  src={Ellipse}
                  alt="Ellipse"
                />
                <p>IDX Home Search Integration</p>
              </div>
              <div className="points">
                <img
                  src={Ellipse}
                  alt="Ellipse"
                />
                <p>Limited Template Customization</p>
              </div>
              <div className="points">
                <img
                  src={Ellipse}
                  alt="Ellipse"
                />
                <p>Schedule your Appointments</p>
              </div>
              <div className="points">
                <img
                  src={Ellipse}
                  alt="Ellipse"
                />
                <p>Blogs</p>
              </div>
            </div>
          </div>
        </div>
  )
}
