/** @format */

import React, { useEffect, useState } from 'react';
import { DepositeDescription } from '../../../../utils/New_construction_details/DepositeDescription';
import { DifferentModel } from '../../../../utils/New_construction_details/DifferentModel';
import { ConvertStringToHtml } from '../../../../utils/New_construction_details/ConvertDepositeToHtml';
import { Propertyname } from '../../../../utils/New_construction_details/customePropertyName';
// import FeatureFacts from '../../utilComponent/Details/Components/Feature&Facts/Feature_Facts.component';
// import FloorPlanCard from '../../utilComponent/Details/Components/FloorPlan/FloorPlanCard.component';
import AppointmentForm from '../../Details/Components/appointment/Appointment.component';
import NavbarDetail from './components/detailnavbar/navbar.components';
import {
    LeftContainer,
    OverView,
    Amenities,
    PropertyDetails,
    MobileDepositePremium,
    AvailableFloorPlans,
    DepositeStructure,
    RightContainer,
    Appointment,
    DepositePremium,
    AppointmentContainer,
    Map,
    Container,
    NavContainer,
    Tab,
    DropDown
} from './builderdetails.style';

//images
import BusStand from './Images/fluent-emoji_bus-stop.svg';
import Banks from './Images/fluent-emoji_bank.svg';
import Cinemas from './Images/fluent-emoji_movie-camera.svg';
import Parks from './Images/fluent-emoji_evergreen-tree.svg';
import Shopping from './Images/fluent-emoji_shopping-bags.svg';
import Food from './Images/fluent-emoji_pot-of-food.svg';
import Hospital from './Images/fluent-emoji_medical-symbol.svg';
import Attractions from './Images/fluent-emoji_ferris-wheel.svg';
import Sunset from './Images/fluent-emoji_sunset.svg';
import BulletPoints from './Images/BulletsPoints.svg'
import House from './Images/fluent-emoji_house-with-garden.svg'
import RequestForm from './components/requestform/requestForm.component';
// import VideoPlayer from '../../VideoPlayer/VideoPlayer.compoent';
import FloorPlanCard from '../../Details/Components/FloorPlan/FloorPlanCard.component';
import { ImageViewer } from '../../ImageViewer/ImageViewer.component';

export default function BuilderDetails({ Data }) {
    const [readMore, setReadMore] = useState<boolean>(true);
    const [appointment, setAppointment] = useState<boolean>(false);
    const [depositeDescriptoin, setDescription] = useState<string>('');
    const [differentModels, setDifferentModel] = useState([]);
    const [dropDown,setDropDown]=useState<{price:boolean}>({
        price:false,
    })
    const handleReadme = () => {
        setReadMore(!readMore);
    };
    useEffect(() => {
        const newData = DepositeDescription(Data?.property);
        setDescription(newData);
    }, [Data]);
    useEffect(() => {
        setDifferentModel(DifferentModel(Data));
    }, [Data]);
    const handleDropDown = (type:string)=>{
        setDropDown(prev => ({ ...prev, [type]: !prev[type] }));
    }

    return (
        <>
            <Container>
                <LeftContainer>
                    <NavbarDetail/>
                    <OverView
                        id="Overview"
                        Check={readMore}
                        $descriptionSize={Data?.property.plan_properties[0].description.length >= 300}
                        >
                        <h1>Why {Propertyname(Data?.property)} ?</h1>
                        <div className="InfoContainer">
                            <div className="Info">
                                <h1>Type</h1>
                                <h3>
                                    {Data?.property?.home_type === null
                                        ? 'No data'
                                        : Data?.property?.home_type}
                                </h3>
                            </div>
                            <div className="Info">
                                <h1>Bedroom</h1>
                                <h3>
                                    {Data?.property.bedroom === null ? 0 : Data?.property.bedroom}
                                </h3>
                            </div>
                            <div className="Info">
                                <h1>Square Foot</h1>
                                <h3>
                                    {Data?.property.sqft_area === null
                                        ? 'No Data'
                                        : Data?.property.sqft_area}
                                </h3>
                            </div>
                            <div className="Info">
                                <h1>Bathroom</h1>
                                <h3>
                                    {Data?.property.bathroom === null
                                        ? 0
                                        : Data?.property.bathroom}
                                </h3>
                            </div>
                            <div className="Info">
                                <h1>Garage</h1>
                                <h3>
                                    {Data?.property.garage === null ? 0 : Data?.property.garage}
                                </h3>
                            </div>
                            <div className="Info">
                                <h1>Status</h1>
                                <h3>
                                    {Data?.property.building_status === null
                                        ? 'No data'
                                        : Data?.property.building_status}
                                </h3>
                            </div>
                            {/* <div className="Info">
                                <h1>Age</h1>
                                <h3>No Data</h3>
                            </div> */}
                            {/* <div className="Info">
                                <h1>Frontage</h1>
                                <h3>No Data</h3>
                            </div> */}
                            <div className="Info">
                                <h1>Expected completion</h1>
                                <h3>{Data?.property?.completion_year !== null ? Data?.property?.completion_year :'N/a'}</h3>
                            </div>
                        </div>
                        {Data?.property.plan_properties[0].description && (
                            <div
                                className="Description"
                                dangerouslySetInnerHTML={{
                                    __html: Data?.property.plan_properties[0].description,
                                }}></div>
                        )}
                        {Data?.property.plan_properties[0].description.length >= 300 && (
                            <div
                                className="Wrapper"
                                onClick={handleReadme}>
                                <p>Read more about the property</p>

                                <svg
                                    className="SvgWrapper"
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="10"
                                    height="5"
                                    viewBox="0 0 10 5"
                                    fill="none">
                                    <path
                                        d="M0 0L5 5L10 0H0Z"
                                        fill="white"
                                    />
                                </svg>
                            </div>
                        )}
                    </OverView>
                    {(Data?.property?.property_images.length > 0) && (
                        <ImageViewer Images={Data?.property?.property_images} />
                    )}
                    <Map id="Map">
                        <h1>Explore Neighborhood - Map View</h1>
                        <div className="MapContainer">
                            <div className="leftSection">
                                <iframe
                                    title="Map"
                                    src={`https://maps.google.com/maps?q= ${Data?.property.street_address} &hl=en&z=15&output=embed&`}
                                    allowFullScreen={true}
                                    loading="lazy"
                                    referrerPolicy="no-referrer-when-downgrade"
                                    style={{ border: '0px' }}></iframe>
                            </div>
                            <div className="rightSection">
                                <div className="BulletPoints">
                                    <div className="wrapper">
                                        <img
                                            src={BusStand}
                                            alt=""
                                        />
                                    </div>
                                    <p>Commute</p>
                                </div>
                                <div className="BulletPoints">
                                    <div className="wrapper">
                                        <img
                                            src={Banks}
                                            alt=""
                                        />
                                    </div>
                                    <p>Banks</p>
                                </div>
                                <div className="BulletPoints">
                                    <div className="wrapper">
                                        <img
                                            src={Cinemas}
                                            alt=""
                                        />
                                    </div>
                                    <p>Cinemas</p>
                                </div>
                                <div className="BulletPoints">
                                    <div className="wrapper">
                                        <img
                                            src={Parks}
                                            alt=""
                                        />
                                    </div>
                                    <p>Parks</p>
                                </div>
                                <div className="BulletPoints">
                                    <div className="wrapper">
                                        <img
                                            src={Shopping}
                                            alt=""
                                        />
                                    </div>
                                    <p>Shopping</p>
                                </div>
                                <div className="BulletPoints">
                                    <div className="wrapper">
                                        <img
                                            src={Food}
                                            alt=""
                                        />
                                    </div>
                                    <p>Food</p>
                                </div>
                                <div className="BulletPoints">
                                    <div className="wrapper">
                                        <img
                                            src={Hospital}
                                            alt=""
                                        />
                                    </div>
                                    <p>Hospitals</p>
                                </div>
                                <div className="BulletPoints">
                                    <div className="wrapper">
                                        <img
                                            src={Attractions}
                                            alt=""
                                        />
                                    </div>
                                    <p>Attractions</p>
                                </div>
                            </div>
                        </div>
                    </Map>
                    {Data?.property.plan_properties[0].amenities && (
                        <Amenities>
                            <h1>Amenities</h1>
                            <div className="AmenitiesContainer">
                                {Data?.property.plan_properties[0].amenities?.map((data, i) => (
                                    <div
                                        className="Amenities"
                                        key={i}>
                                        <div className="wrapper">
                                            <img
                                                src={Sunset}
                                                alt=""
                                            />
                                        </div>
                                        <p>{data}</p>
                                    </div>
                                ))}
                            </div>
                        </Amenities>
                    )}
                    {/* <NearBySchool /> */}
                    <PropertyDetails id="Property_Details">
                        <h1>Property Details</h1>
                        <div className="DetailsContainer">
                            <div className="Details">
                                <div className="Wrapper">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="8" height="9" viewBox="0 0 8 9" fill="none">
                                        <circle cx="4" cy="4.5" r="4" fill="#0061DF" />
                                    </svg>
                                </div>
                                <p>
                                    Building Type :{' '}
                                    <span>
                                        {' '}
                                        {Data?.property.home_type === null
                                            ? 'N/A'
                                            : Data?.property?.home_type}
                                    </span>
                                </p>
                            </div>
                            <div className="Details">
                                <div className="Wrapper">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="8" height="9" viewBox="0 0 8 9" fill="none">
                                        <circle cx="4" cy="4.5" r="4" fill="#0061DF" />
                                    </svg>
                                </div>
                                <p>
                                    Selling Status :{' '}
                                    <span>
                                        {' '}
                                        {Data?.property.is_active === 1 ? 'Active' : 'No Active'}
                                    </span>
                                </p>
                            </div>
                            <div className="Details">
                                <div className="Wrapper">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="8" height="9" viewBox="0 0 8 9" fill="none">
                                        <circle cx="4" cy="4.5" r="4" fill="#0061DF" />
                                    </svg>
                                </div>
                                <p>
                                    Building Status :{' '}
                                    <span>
                                        {' '}
                                        {Data?.property?.plan_properties[0]?.building_status ===
                                            null
                                            ? 'N/A'
                                            : Data?.property?.plan_properties[0]?.building_status}
                                    </span>
                                </p>
                            </div>
                            <div className="Details">
                                <div className="Wrapper">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="8" height="9" viewBox="0 0 8 9" fill="none">
                                        <circle cx="4" cy="4.5" r="4" fill="#0061DF" />
                                    </svg>
                                </div>
                                <p>
                                    Builder(s) :{' '}
                                    <span>
                                        {' '}
                                        {Data?.property?.plan_properties[0]?.seller === null
                                            ? 'N/A'
                                            : Data?.property?.plan_properties[0]?.seller}
                                    </span>
                                </p>
                            </div>
                            <div className="Details">
                                <div className="Wrapper">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="8" height="9" viewBox="0 0 8 9" fill="none">
                                        <circle cx="4" cy="4.5" r="4" fill="#0061DF" />
                                    </svg>
                                </div>
                                <p>
                                    Architect(s) :{' '}
                                    <span>
                                        {' '}
                                        {Data?.property?.plan_properties[0]?.architect === null
                                            ? 'N/A'
                                            : Data?.property?.plan_properties[0]?.architect}
                                    </span>
                                </p>
                            </div>
                            {/* <div className="Details">
                                <div className="Wrapper">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="8" height="9" viewBox="0 0 8 9" fill="none">
                                        <circle cx="4" cy="4.5" r="4" fill="#0061DF" />
                                    </svg>
                                </div>
                                <p>
                                    Basement :{' '}
                                    <span>
                                        {' '}
                                        {Data?.property.basement === null
                                            ? 'N/A'
                                            : Data?.property.basement}
                                    </span>
                                </p>
                            </div> */}
                            <div className="Details">
                                <div className="Wrapper">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="8" height="9" viewBox="0 0 8 9" fill="none">
                                        <circle cx="4" cy="4.5" r="4" fill="#0061DF" />
                                    </svg>
                                </div>
                                <p>
                                    Completed In :{' '}
                                    <span>
                                        {Data?.property?.completion_year !== null ? Data?.property?.completion_year : 'N/a'}
                                    </span>
                                </p>
                            </div>
                        </div>
                    </PropertyDetails>

                    <DropDown onClick={() => handleDropDown('price')} id='Pricing'>
                        <div className="drop_down_up_section">
                            <p className="heading">
                                Pricing
                            </p>
                            <div className="svg_wrapper">
                                <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M13.2044 15.707C13.0169 15.8944 12.7626 15.9998 12.4974 15.9998C12.2322 15.9998 11.9779 15.8944 11.7904 15.707L6.13341 10.05C6.0379 9.95773 5.96172 9.84739 5.90931 9.72538C5.8569 9.60338 5.82932 9.47216 5.82816 9.33938C5.82701 9.2066 5.85231 9.07492 5.90259 8.95202C5.95287 8.82913 6.02713 8.71747 6.12102 8.62358C6.21491 8.52969 6.32656 8.45544 6.44946 8.40515C6.57236 8.35487 6.70404 8.32957 6.83681 8.33073C6.96959 8.33188 7.10081 8.35947 7.22282 8.41188C7.34482 8.46428 7.45517 8.54047 7.54741 8.63598L12.4974 13.586L17.4474 8.63598C17.636 8.45382 17.8886 8.35302 18.1508 8.3553C18.413 8.35758 18.6638 8.46275 18.8492 8.64816C19.0346 8.83357 19.1398 9.08438 19.1421 9.34658C19.1444 9.60877 19.0436 9.86137 18.8614 10.05L13.2044 15.707Z" fill="#13131A" />
                                </svg>

                            </div>
                        </div>
                        {dropDown.price && <MobileDepositePremium>
                            {Data?.property.elevation_premium && (
                                <div className="premium_section">
                                    <h4>Elevation Premium</h4>
                                    <div
                                        dangerouslySetInnerHTML={{
                                            __html: ConvertStringToHtml(
                                                Data?.property.elevation_premium
                                            ),
                                        }}></div>
                                </div>
                            )}
                            {Data?.property.description && (
                                <div className="premium_section">
                                    <h4>Lot Premium</h4>
                                    <div
                                        dangerouslySetInnerHTML={{
                                            __html: ConvertStringToHtml(Data?.property.description),
                                        }}></div>
                                </div>
                            )}
                            <DepositeStructure>
                                <h2>Deposite Structure</h2>
                                <div
                                    className="DepositeStructure"
                                    dangerouslySetInnerHTML={{ __html: depositeDescriptoin }}></div>
                            </DepositeStructure>
                        </MobileDepositePremium>}
                  </DropDown>
                    {Data?.property?.plan_properties.length !== 0 && (
                        <AvailableFloorPlans>
                            <h1>Different Models</h1>
                            <div className="FloorPlansContainer">
                                {differentModels?.map((data) => (
                                    <FloorPlanCard
                                        key={data.id}
                                        Data={data}
                                    />
                                ))}
                            </div>
                        </AvailableFloorPlans>
                    )}
                    {/* <FeatureFacts Data={Data?.property} /> */}
                    {/* <VideoPlayer Data={Data?.property} /> */}
                </LeftContainer>
                <RightContainer>
                    {/* <Appoinment>
                        <div className="animation">
                            <div
                                className="wrapper"
                                onClick={() => setAppointment(!appointment)}>
                                <img
                                    src={House}
                                    alt="House"
                                />
                            </div>
                        </div>
                        <div className="appointment_content">
                            <p>
                                Hurry Up! <br /> Click here to schedule an <br /> appoinment!
                            </p>
                        </div>
                    </Appoinment> */}
                    {/* <div className="ACard">
                        <div className="Content">
                            <h1>Down Payment Assistance for your Home?</h1>
                            <p>Transform your down payment into
                                20% <br /> or more with the help from BuilderMatch</p>
                        </div>
                        <img src={MoneyWithWings} alt="MoneyWithWings" />
                    </div>
                    <div className="ACard">
                        <div className="Content">
                            <h1>Co-investment at 1/8th Cost!</h1>
                            <p>Min ROI 25%. Click to learn more!</p>
                        </div>
                        <img src={HomeWithPeople} alt="HomeWithPeople" />
                    </div> */}
                    {/* {
                        <DepositePremium>
                            {Data?.property.elevation_premium && (
                                <div className="premium_section">
                                    <h4>Elevation Premium</h4>
                                    <div
                                        dangerouslySetInnerHTML={{
                                            __html: ConvertStringToHtml(
                                                Data?.property.elevation_premium
                                            ),
                                        }}></div>
                                </div>
                            )}
                            {Data?.property.description && (
                                <div className="premium_section">
                                    <h4>Lot Premium</h4>
                                    <div
                                        dangerouslySetInnerHTML={{
                                            __html: ConvertStringToHtml(Data?.property.description),
                                        }}></div>
                                </div>
                            )}
                        </DepositePremium>
                    } */}
                    <RequestForm Data={Data}/>
                </RightContainer>
                {appointment && (
                    <AppointmentContainer onClick={() => setAppointment(!appointment)}>
                    {/* <AppointmentForm Data={Data} /> */}
                        <RequestForm Data={Data} />
                   </AppointmentContainer>
    
                )}
                <Appointment className="mobileAnimation">
                    <button className="submit_button" onClick={()=>setAppointment(!appointment)}>
                        <svg width="31" height="30" viewBox="0 0 31 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g clip-path="url(#clip0_4995_16201)">
                                <g filter="url(#filter0_d_4995_16201)">
                                    <path d="M7.8311 25.7142C7.22872 25.7142 6.71286 25.5042 6.28353 25.0842C5.8542 24.6642 5.6399 24.1599 5.64063 23.5714V8.57136C5.64063 7.98208 5.85529 7.47743 6.28463 7.05743C6.71396 6.63743 7.22945 6.42779 7.8311 6.4285H8.92634V5.35708C8.92634 5.05351 9.03148 4.79886 9.24177 4.59315C9.45205 4.38743 9.71199 4.28493 10.0216 4.28565C10.3319 4.28565 10.5922 4.38851 10.8025 4.59422C11.0128 4.79993 11.1175 5.05422 11.1168 5.35708V6.4285H19.8787V5.35708C19.8787 5.05351 19.9839 4.79886 20.1941 4.59315C20.4044 4.38743 20.6644 4.28493 20.974 4.28565C21.2843 4.28565 21.5446 4.38851 21.7549 4.59422C21.9651 4.79993 22.0699 5.05422 22.0692 5.35708V6.4285H23.1644C23.7668 6.4285 24.2827 6.6385 24.712 7.0585C25.1413 7.4785 25.3556 7.98279 25.3549 8.57136V23.5714C25.3549 24.1606 25.1402 24.6653 24.7109 25.0853C24.2816 25.5053 23.7661 25.7149 23.1644 25.7142H7.8311ZM7.8311 23.5714H23.1644V12.8571H7.8311V23.5714ZM15.0323 21.5356L14.1287 19.5535L12.1025 18.6696C11.9017 18.5803 11.8013 18.4285 11.8013 18.2142C11.8013 17.9999 11.9017 17.8481 12.1025 17.7589L14.1287 16.8749L15.0323 14.8928C15.1236 14.6964 15.2787 14.5981 15.4978 14.5981C15.7168 14.5981 15.872 14.6964 15.9632 14.8928L16.8668 16.8749L18.893 17.7589C19.0938 17.8481 19.1942 17.9999 19.1942 18.2142C19.1942 18.4285 19.0938 18.5803 18.893 18.6696L16.8668 19.5535L15.9632 21.5356C15.872 21.7321 15.7168 21.8303 15.4978 21.8303C15.2787 21.8303 15.1236 21.7321 15.0323 21.5356Z" fill="white" />
                                </g>
                            </g>
                            <defs>
                                <filter id="filter0_d_4995_16201" x="3.64062" y="2.28564" width="27.7109" height="29.4285" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                                    <feFlood flood-opacity="0" result="BackgroundImageFix" />
                                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                                    <feOffset dx="2" dy="2" />
                                    <feGaussianBlur stdDeviation="2" />
                                    <feComposite in2="hardAlpha" operator="out" />
                                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0.380392 0 0 0 0 0.87451 0 0 0 0.1 0" />
                                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_4995_16201" />
                                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_4995_16201" result="shape" />
                                </filter>
                                <clipPath id="clip0_4995_16201">
                                    <rect width="30" height="30" fill="white" transform="translate(0.5)" />
                                </clipPath>
                            </defs>
                        </svg>
                        Schedule Tour
                    </button>
                    {/* <div className="animation">
                        <div
                            className="wrapper"
                            onClick={() => setAppointment(!appointment)}>
                            <img
                                src={House}
                                alt="House"
                            />
                        </div>
                    </div> */}
                </Appointment>
            </Container>
        </>
    );
}
