/** @format */

import React, { useState } from 'react';
import { NavContainer, Tab } from './navbar.style';
export default function NavbarDetail() {
  const [active, setActive] = useState<string>('Overview');
  const scrollToId = (e, id: string) => {
    e.preventDefault();
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth', block: 'center' });
      setActive(id);
    }
  };
  const NavSetting = {
    dots: false,
    arrows: false,
    speed: 500,
    // letMode: true,
    slidesToShow: 7,
    slidesToScroll: 1,
    infinite: true,
    centerMode:false,
    swipeToSlide: true,
    responsive: [
      {
        breakpoint: 1690,
        settings: {
          centerMode: true,
          slidesToShow: 5,
        },
      },
      {
        breakpoint: 1250,
        settings: {
          centerMode: true,
          slidesToShow: 4,
        },
      },
      {
        breakpoint: 742,
        settings: {
          centerMode: true,
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 662,
        settings: {
          centerMode: true,
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 459,
        settings: {
          centerMode: true,
          slidesToShow: 1,
        },
      },
    ],
  };
  const handleActive = (type: string) => {
    setActive(type);
  };
  return (
    <NavContainer {...NavSetting}>
      <Tab
        $active={active === 'Overview'}
        onClick={(e) => scrollToId(e, 'Overview')}>
        <div className="svg_wrapper">
          <svg
            width="25"
            height="24"
            viewBox="0 0 25 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
              d="M11.9297 13C11.9297 12.7348 12.035 12.4804 12.2226 12.2929C12.4101 12.1054 12.6645 12 12.9297 12C13.1949 12 13.4493 12.1054 13.6368 12.2929C13.8243 12.4804 13.9297 12.7348 13.9297 13V16C13.9297 16.2652 13.8243 16.5196 13.6368 16.7071C13.4493 16.8946 13.1949 17 12.9297 17C12.6645 17 12.4101 16.8946 12.2226 16.7071C12.035 16.5196 11.9297 16.2652 11.9297 16V13ZM12.9297 7.5C12.5319 7.5 12.1503 7.65804 11.869 7.93934C11.5877 8.22064 11.4297 8.60218 11.4297 9C11.4297 9.39782 11.5877 9.77936 11.869 10.0607C12.1503 10.342 12.5319 10.5 12.9297 10.5C13.3275 10.5 13.709 10.342 13.9903 10.0607C14.2717 9.77936 14.4297 9.39782 14.4297 9C14.4297 8.60218 14.2717 8.22064 13.9903 7.93934C13.709 7.65804 13.3275 7.5 12.9297 7.5ZM2.92969 12C2.92969 9.34784 3.98326 6.8043 5.85862 4.92893C7.73398 3.05357 10.2775 2 12.9297 2C15.5819 2 18.1254 3.05357 20.0008 4.92893C21.8761 6.8043 22.9297 9.34784 22.9297 12C22.9297 14.6522 21.8761 17.1957 20.0008 19.0711C18.1254 20.9464 15.5819 22 12.9297 22C10.2775 22 7.73398 20.9464 5.85862 19.0711C3.98326 17.1957 2.92969 14.6522 2.92969 12ZM12.9297 4C10.808 4 8.77312 4.84285 7.27283 6.34315C5.77254 7.84344 4.92969 9.87827 4.92969 12C4.92969 14.1217 5.77254 16.1566 7.27283 17.6569C8.77312 19.1571 10.808 20 12.9297 20C15.0514 20 17.0863 19.1571 18.5865 17.6569C20.0868 16.1566 20.9297 14.1217 20.9297 12C20.9297 9.87827 20.0868 7.84344 18.5865 6.34315C17.0863 4.84285 15.0514 4 12.9297 4Z"
              fill="#323232"
            />
          </svg>
        </div>
        <p className="sub_heading">Overview</p>
      </Tab>
      <Tab
        $active={active === 'Floor_plan'}
        onClick={(e) => scrollToId(e, 'Floor_plan')}
        // onClick={() => handleActive('Layout')}
      >
        <div className="svg_wrapper">
          <svg
            width="25"
            height="24"
            viewBox="0 0 25 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
              d="M21.1496 2H4.0067C3.22098 2 2.57812 2.64286 2.57812 3.42857V20.5714C2.57812 21.3571 3.22098 22 4.0067 22H14.721V20.5714C14.721 18.5714 16.2924 17 18.2924 17V15.5714C15.5067 15.5714 13.2924 17.7857 13.2924 20.5714H11.1496V17.7143H9.72098V20.5714H4.0067V3.42857H9.72098V13.4286H11.1496V9.85714H14.0067V8.42857H11.1496V3.42857H21.1496V8.42857H18.2924V9.85714H21.1496V20.5714H18.2924V22H21.1496C21.9353 22 22.5781 21.3571 22.5781 20.5714V3.42857C22.5781 2.64286 21.9353 2 21.1496 2Z"
              fill="#323232"
            />
          </svg>
        </div>
        <p className="sub_heading">Layout</p>
      </Tab>
      <Tab
        $active={active === 'Map'}
        onClick={(e) => scrollToId(e, 'Map')}
        // onClick={() => handleActive('Map')}
        href="#Map">
        <div className="svg_wrapper">
          <svg
            width="25"
            height="24"
            viewBox="0 0 25 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="no_fill">
            <path
              d="M9.64062 6.639V20.5M15.6406 3V17M3.64062 8.71C3.64062 7.236 3.64062 6.5 4.03362 6.07C4.17271 5.91703 4.34192 5.79447 4.53063 5.71C5.06263 5.474 5.76163 5.707 7.15963 6.173C8.22663 6.529 8.75963 6.707 9.29963 6.688C9.49772 6.68149 9.69468 6.65536 9.88762 6.61C10.4126 6.485 10.8806 6.173 11.8166 5.55L13.1986 4.628C14.3986 3.828 14.9976 3.428 15.6856 3.337C16.3736 3.244 17.0576 3.472 18.4246 3.928L19.5896 4.316C20.5796 4.646 21.0746 4.811 21.3576 5.204C21.6406 5.597 21.6406 6.119 21.6406 7.162V15.291C21.6406 16.764 21.6406 17.501 21.2476 17.931C21.1083 18.0832 20.9392 18.2051 20.7506 18.289C20.2186 18.526 19.5196 18.293 18.1216 17.827C17.0546 17.471 16.5216 17.293 15.9816 17.312C15.7835 17.3185 15.5866 17.3446 15.3936 17.39C14.8686 17.515 14.4006 17.827 13.4646 18.45L12.0826 19.372C10.8826 20.172 10.2836 20.572 9.59562 20.663C8.90762 20.756 8.22363 20.528 6.85663 20.072L5.69163 19.684C4.70163 19.354 4.20662 19.189 3.92362 18.796C3.64062 18.403 3.64062 17.88 3.64062 16.838V8.71Z"
              stroke="#323232"
              stroke-width="1.6"
              fill="none"
            />
          </svg>
        </div>
        <p className="sub_heading">Map</p>
      </Tab>
      <Tab
        $active={active === 'Property_Details'}
        onClick={(e) => scrollToId(e, 'Property_Details')}
        // onClick={() => handleActive('Property Details')}
        href="#Property_Details">
        <div className="svg_wrapper">
          <svg
            width="25"
            height="24"
            viewBox="0 0 25 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
              d="M16.25 13C16.25 12.8011 16.171 12.6103 16.0303 12.4697C15.8897 12.329 15.6989 12.25 15.5 12.25H9.5C9.30109 12.25 9.11032 12.329 8.96967 12.4697C8.82902 12.6103 8.75 12.8011 8.75 13C8.75 13.1989 8.82902 13.3897 8.96967 13.5303C9.11032 13.671 9.30109 13.75 9.5 13.75H15.5C15.6989 13.75 15.8897 13.671 16.0303 13.5303C16.171 13.3897 16.25 13.1989 16.25 13ZM16.25 17C16.25 16.8011 16.171 16.6103 16.0303 16.4697C15.8897 16.329 15.6989 16.25 15.5 16.25H9.5C9.30109 16.25 9.11032 16.329 8.96967 16.4697C8.82902 16.6103 8.75 16.8011 8.75 17C8.75 17.1989 8.82902 17.3897 8.96967 17.5303C9.11032 17.671 9.30109 17.75 9.5 17.75H15.5C15.6989 17.75 15.8897 17.671 16.0303 17.5303C16.171 17.3897 16.25 17.1989 16.25 17Z"
              fill="#323232"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M7.5 2.25C6.77065 2.25 6.07118 2.53973 5.55546 3.05546C5.03973 3.57118 4.75 4.27065 4.75 5V19C4.75 19.7293 5.03973 20.4288 5.55546 20.9445C6.07118 21.4603 6.77065 21.75 7.5 21.75H17.5C18.2293 21.75 18.9288 21.4603 19.4445 20.9445C19.9603 20.4288 20.25 19.7293 20.25 19V7.968C20.25 7.587 20.126 7.217 19.896 6.913L16.898 2.945C16.7349 2.72912 16.5239 2.554 16.2817 2.43341C16.0395 2.31282 15.7726 2.25004 15.502 2.25H7.5ZM6.25 5C6.25 4.31 6.81 3.75 7.5 3.75H14.75V8.147C14.75 8.561 15.086 8.897 15.5 8.897H18.75V19C18.75 19.69 18.19 20.25 17.5 20.25H7.5C6.81 20.25 6.25 19.69 6.25 19V5Z"
              fill="#323232"
            />
          </svg>
        </div>
        <p className="sub_heading">Property Details</p>
      </Tab>
      <Tab
        $active={active === 'Facts & Features'}
        onClick={(e) => scrollToId(e, 'Facts & Features')}
        // onClick={() => handleActive('Facts & Features')}
      >
        <div className="svg_wrapper">
          <svg
            width="25"
            height="24"
            viewBox="0 0 25 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="no_fill">
            <path
              d="M12.3602 17.75L6.18819 20.995L7.36719 14.122L2.36719 9.25495L9.26719 8.25495L12.3532 2.00195L15.4392 8.25495L22.3392 9.25495L17.3392 14.122L18.5182 20.995L12.3602 17.75Z"
              stroke="white"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M8.94368 8.23594L11.5417 3.00394C11.6173 2.85253 11.7336 2.72517 11.8776 2.63616C12.0215 2.54715 12.1874 2.5 12.3567 2.5C12.5259 2.5 12.6918 2.54715 12.8358 2.63616C12.9797 2.72517 13.0961 2.85253 13.1717 3.00394L15.7697 8.23594L21.5777 9.07994C21.7452 9.10317 21.9028 9.17303 22.0326 9.28155C22.1623 9.39007 22.2589 9.53288 22.3113 9.69367C22.3638 9.85446 22.37 10.0268 22.3292 10.1909C22.2884 10.355 22.2023 10.5044 22.0807 10.6219L17.8787 14.6919L18.8707 20.4419C18.9977 21.1799 18.2177 21.7419 17.5507 21.3939L12.3567 18.6779L7.16168 21.3939C6.49568 21.7429 5.71568 21.1799 5.84268 20.4409L6.83468 14.6909L2.63268 10.6209C2.51167 10.5033 2.42608 10.3541 2.38565 10.1903C2.34521 10.0264 2.35156 9.85451 2.40395 9.69409C2.45635 9.53367 2.5527 9.39116 2.68205 9.28277C2.8114 9.17439 2.96857 9.10446 3.13568 9.08094L8.94368 8.23594Z"
              stroke="#323232"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </div>
        <p className="sub_heading">Facts & Features</p>
      </Tab>
      <Tab
        $active={active === 'Virtual_Tour'}
        onClick={(e) => scrollToId(e, 'Virtual_Tour')}>
        <div className="svg_wrapper">
          <svg
            width="25"
            height="24"
            viewBox="0 0 25 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="no_fill">
            <path
              d="M3.21875 15.75V8.25C3.21875 7.71957 3.42946 7.21086 3.80454 6.83579C4.17961 6.46071 4.68832 6.25 5.21875 6.25H13.7188C14.2492 6.25 14.7579 6.46071 15.133 6.83579C15.508 7.21086 15.7188 7.71957 15.7188 8.25V15.75C15.7188 16.2804 15.508 16.7891 15.133 17.1642C14.7579 17.5393 14.2492 17.75 13.7188 17.75H5.21875C4.68832 17.75 4.17961 17.5393 3.80454 17.1642C3.42946 16.7891 3.21875 16.2804 3.21875 15.75ZM20.3867 6.991L16.3867 10.554C16.334 10.6008 16.2918 10.6582 16.2629 10.7225C16.2339 10.7868 16.2189 10.8565 16.2188 10.927V12.705C16.2189 12.7755 16.2339 12.8452 16.2629 12.9095C16.2918 12.9738 16.334 13.0312 16.3867 13.078L20.3867 16.641C20.4588 16.705 20.5478 16.7468 20.6431 16.7614C20.7384 16.776 20.8358 16.7627 20.9237 16.7232C21.0117 16.6837 21.0863 16.6196 21.1386 16.5386C21.1909 16.4577 21.2188 16.3634 21.2188 16.267V7.365C21.2188 7.26862 21.1909 7.17429 21.1386 7.09335C21.0863 7.01242 21.0117 6.94834 20.9237 6.90882C20.8358 6.86931 20.7384 6.85604 20.6431 6.87062C20.5478 6.8852 20.4588 6.927 20.3867 6.991Z"
              stroke="#323232"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </div>
        <p className="sub_heading">Virtual Tour</p>
      </Tab>
      <Tab
        $active={active === 'Pricing'}
        onClick={(e) => scrollToId(e, 'Pricing')}
        href="#Pricing">
        <div className="svg_wrapper">
          <svg
            width="25"
            height="24"
            viewBox="0 0 25 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="no_fill">
            <path
              d="M12.0703 6V18M15.0703 9.5C15.0703 8.12 13.7273 7 12.0703 7C10.4133 7 9.07031 8.12 9.07031 9.5C9.07031 10.88 10.4133 12 12.0703 12C13.7273 12 15.0703 13.12 15.0703 14.5C15.0703 15.88 13.7273 17 12.0703 17C10.4133 17 9.07031 15.88 9.07031 14.5"
              stroke="#323232"
              stroke-width="1.5"
              stroke-linecap="round"
            />
            <path
              d="M7.07031 3.33801C8.58983 2.45891 10.3148 1.99731 12.0703 2.00001C17.5933 2.00001 22.0703 6.47701 22.0703 12C22.0703 17.523 17.5933 22 12.0703 22C6.54731 22 2.07031 17.523 2.07031 12C2.07031 10.179 2.55731 8.47001 3.40831 7.00001"
              stroke="#323232"
              stroke-width="1.5"
              stroke-linecap="round"
            />
          </svg>
        </div>
        <p className="sub_heading">Pricing</p>
      </Tab>
    </NavContainer>
  );
}
