/** @format */

export default function ConvertToCanadianNumber(
  inputStr: number | null | undefined | string
): string {
  let InputNumber = 0;
  if (typeof inputStr === 'string') {
    InputNumber = Number(inputStr);
  } else if (typeof inputStr === 'number') {
    InputNumber = inputStr;
  } else {
    return 'NaN';
  }

  if (isNaN(InputNumber)) {
    return 'NaN';
  }

  const canadianNumber: string = InputNumber.toLocaleString('en-CA', {
    style: 'decimal',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  });
  return canadianNumber;
}