/** @format */

import { AboutContainer } from './aboutushome.style';

//images
import LiveEditImageHighLighter from '../../../../liveEditHighlighterstyles/liveEditImageHighLighter.component';
import LiveEdithTextHighLighter from '../../../../liveEditHighlighterstyles/liveEdithTextHighLighter.component';

type AboutHome = {
  data: Record<string, string>;
};

export default function Aboutushome({ data }: AboutHome) {
  return (
    <AboutContainer
      id="sec1"
      $color={data.color}
      className="sec1">
      <div className="HomeContainer">
        <div className="leftSection">
          <LiveEdithTextHighLighter
            section="sec1"
            type="text">
            <div
              className="Text"
              dangerouslySetInnerHTML={{ __html: data.text }}></div>
          </LiveEdithTextHighLighter>
          <LiveEdithTextHighLighter
            section="sec1"
            type="description">
            <div
              className="description"
              dangerouslySetInnerHTML={{ __html: data.description }}></div>
          </LiveEdithTextHighLighter>
        </div>
        <img
          src={`/public/${data?.image}`}
          alt="home"
          className="backgroundImage"
          style={{ filter: 'brightness(70%)' }}
        />
      </div>
    </AboutContainer>
  );
}
