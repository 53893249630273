/** @format */

import { Route, Routes } from 'react-router-dom';
import FilterContextProvider from '../context/filter.context';
import { useMessageContext } from '../context/Message.context';
import ErrorBox from '../pages/Components/Errorbox/Errorbox.component';
import PageNotFound from '../pages/Components/pageNotFound/pageNotFound.component';
import Previewwrapper from '../pages/Components/previewwrapper/previewwrapper.component';
import Successbox from '../pages/Components/Successbox/Successbox.component';
import IDXWrappertemplateone from '../pages/Template1/IDXWrapper/IDXWrapper.t1';
import Template1 from '../pages/Template1/Template1';
import Template1PG2 from '../pages/Template1/Template1pg2';
import Template1pg3 from '../pages/Template2/contactus/contact.t2';
import Tools from '../pages/Template1/tools/tools.component';
import BlogView from '../pages/utilComponent/BlogView/BlogView';
import Builderdeals from '../pages/utilComponent/builderdeals/builderdeals.component';
import BuilderdealsPropertyDetails from '../pages/utilComponent/builderdealsPropertyDetails/builderdealsPropertyDetails.t1';
import IdxListing from '../pages/utilComponent/idxListing/IdxListing.utilComponent';
import PropertyView from '../pages/utilComponent/idxView/idxView.component';
import { PROPERTY_TYPE_CLASSES } from '../pages/utilComponent/PropertyCard/PropertyCard.utilComponent';
import SoldListing from '../pages/utilComponent/soldListing/soldListing.utilcomponent';
import ListingMap from '../pages/utilComponent/Maps/ListingMap.untilComponent';

export default function Template1Route() {
  const { state } = useMessageContext();
  return (
    <>
      <Routes>
        <Route
          path=":preview?"
          element={<Previewwrapper />}>
          <Route
            path="home"
            element={<Template1 {...{ templateData: null }} />}
          />
          <Route
            path="about"
            element={<Template1PG2 {...{ templateData: null }} />}
          />
          <Route
            path="contact"
            element={
              <Template1pg3
                templateId={1}
                {...{ templateData: null }}
              />
            }
          />
          <Route
            path="tools"
            element={<IDXWrappertemplateone />}>
            <Route
              index
              element={<Tools />}
            />
          </Route>
          <Route
            path="blogview"
            element={<IDXWrappertemplateone />}>
            <Route
              index
              element={<BlogView />}
            />
          </Route>
          <Route
            path="builderdeals"
            element={
              <FilterContextProvider>
                <IDXWrappertemplateone />
              </FilterContextProvider>
            }>
            <Route
              index
              element={
                <Builderdeals
                  cardType={PROPERTY_TYPE_CLASSES.propertycard_temp3}
                />
              }
            />
            <Route
              path="builder_view/:propertyId"
              element={
                <BuilderdealsPropertyDetails
                  cardType={PROPERTY_TYPE_CLASSES.propertycard_temp3}
                />
              }
            />
          </Route>
          <Route
            path="idxlisting"
            element={
              <FilterContextProvider>
                <IDXWrappertemplateone />
              </FilterContextProvider>
            }>
            <Route
              index
              element={
                <IdxListing
                  type="template1"
                  cardType={PROPERTY_TYPE_CLASSES.propertycard_temp3}
                />
              }
            />
            <Route
              path="idxView/:propertyId"
              element={
                <PropertyView
                  cardType={PROPERTY_TYPE_CLASSES.propertycard_temp3}
                />
              }
            />
          </Route>
          <Route
            path="map"
            element={
              <FilterContextProvider>
                <IDXWrappertemplateone />
              </FilterContextProvider>
            }>
            <Route
              index
              element={
                <ListingMap
                  cardType={PROPERTY_TYPE_CLASSES.propertycard_temp3}
                />
              }
            />
            <Route
              path="idxView/:propertyId"
              element={
                <PropertyView
                  cardType={PROPERTY_TYPE_CLASSES.propertycard_temp3}
                />
              }
            />
          </Route>
          <Route
            path="soldlisting"
            element={
              <FilterContextProvider>
                <IDXWrappertemplateone />
              </FilterContextProvider>
            }>
            <Route
              index
              element={
                <SoldListing
                  cardType={PROPERTY_TYPE_CLASSES.propertycard_temp3}
                />
              }
            />
            <Route
              path=":type/soldView/:propertyId"
              element={
                <PropertyView
                  cardType={PROPERTY_TYPE_CLASSES.propertycard_temp3}
                />
              }
            />
          </Route>
          <Route
            path="*"
            element={<IDXWrappertemplateone />}>
            <Route
              path="*"
              element={<PageNotFound />}
            />
          </Route>
        </Route>
      </Routes>
      {state.ErrorShow && <ErrorBox />}
      {state.SuccessShow && <Successbox />}
    </>
  );
}
