import axios from "axios";

export const options = {
  enableHighAccuracy: true,
  timeout: 5000,
  maximumAge: 0,
}


const Location = () => {
  if (navigator.geolocation) {
    return new Promise((resolve, reject) => {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          resolve([latitude, longitude]);
        },
        (error) => {
          reject(error);
        },
        options
      );
    });
  }
}

export const getLocation = async () => {
  try {
    const Data = await Location()

    const response = await axios.get(`https://maps.googleapis.com/maps/api/geocode/json?latlng=${Data[0]},${Data[1]}&key=AIzaSyABlrsdtJlypejWdegbPBnSPwYvInJUGT0`)
    const data = await response.data
    console.log(data)
    // const city = data.results[0].address_components.find(component => component.types.includes('locality')).long_name;
    // return city
    return [Data[0], Data[1]]
  } catch (e) {
    throw new Error(e.message)
  }
}
