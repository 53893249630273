import axios from 'axios'
import Cookies from 'js-cookie';
export const NextButton = (currentStep, setCurrentStep, steps, setIsOpen, finish = false) => {
  const last = currentStep === steps.length - 1;
  const token = Cookies.get('token');
  let config;
  config = {
    headers: {
      'x-access-token': token,
    },
  };
  const UpdateUserTutorStatus = async () => {
    try {
      await axios.post('/admin/update-template-status', {
        "status": true
      }, config)
    } catch (err) {
      throw new Error(err.message)
    }
  }

  return (
    <button
      className="Button_react_Tour"
      onClick={() => {
        if (last) {
          if (finish) {
            UpdateUserTutorStatus()
          }
          setIsOpen(false)
        } else {
          setCurrentStep((s) => s + 1);
        }
      }}
    >
      {last ? 'Finish' : 'Next'}
      <div className="svg_wrapper">
        <svg width="6" height="10" viewBox="0 0 6 10" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M5.77093 5.54025C5.91761 5.3994 6 5.20839 6 5.00923C6 4.81007 5.91761 4.61906 5.77093 4.47821L1.34511 0.229299C1.27294 0.157562 1.18661 0.100342 1.09116 0.0609786C0.995705 0.0216148 0.893043 0.000895016 0.789161 2.83929e-05C0.68528 -0.000838231 0.582259 0.0181658 0.486109 0.0559312C0.38996 0.0936966 0.302607 0.149467 0.229149 0.219989C0.15569 0.290511 0.0975976 0.374371 0.0582597 0.466677C0.0189218 0.558983 -0.000873192 0.657887 2.95066e-05 0.757616C0.000932206 0.857345 0.0225147 0.955903 0.0635176 1.04754C0.10452 1.13917 0.164122 1.22205 0.238846 1.29134L4.11154 5.00923L0.238846 8.72712C0.0963319 8.86878 0.0174741 9.0585 0.0192566 9.25544C0.0210392 9.45237 0.103319 9.64075 0.248376 9.78001C0.393432 9.91927 0.589659 9.99826 0.794792 9.99997C0.999925 10.0017 1.19755 9.92598 1.34511 9.78916L5.77093 5.54025Z" fill="black" />
        </svg>
      </div>
    </button>
  );
};
