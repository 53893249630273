/** @format */

import axios from 'axios';
import Cookies from 'js-cookie';
import React, { useEffect, useState } from 'react';
import { ConvertTime } from '../../../../utils/timezoneconvertor';
import './BuyersLeadsInfo.css';
import View from './Images/view.svg';
import Preview from './preview';

export default function BuyersLeadsInfo({ Data }) {
  const [data, setData] = useState(Data);
  const [selectedValue, setSelectedValue] = useState(Data.status);
  const [loading, setLoading] = useState(false);
  const [preview, setPreview] = useState('close');

  useEffect(() => {
    const fetchData = async () => {
      const token = Cookies.get('token');
      let source = axios.CancelToken.source();
      let raw_data = {
        buyer_lead_id: data._id,
        status: selectedValue, //['New', 'Interested now', 'Interested later', 'Call Later', 'Not Interested', 'Do not contact', 'Meeting', 'Closed']
      };
      try {
        await axios.post('/admin/update-buyer-lead-status', raw_data, {
          cancelToken: source.token,
          headers: {
            'x-access-token': token,
          },
        });
      } catch (error) {
        if (axios.isCancel(error)) {
          console.log('Request canceled:', error.message);
        } else {
          console.error('Error:', error.message);
        }
      } finally {
        setLoading(false);
        await Buyersinfo();
      }
    };
    fetchData();
  }, [selectedValue]);

  const Buyersinfo = async () => {
    const token = Cookies.get('token');
    try {
      const getData = await axios.get(`/admin/buyer-lead/${data._id}`, {
        headers: {
          'x-access-token': token,
        },
      });
      setData(getData.data.data);
    } catch (error) {
      console.error(error);
    }
  };

  const handleOnChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setLoading(true);
    setSelectedValue(event.target.value);
  };

  return (
    <>
      <div className="buyers_leader_info">
        <p>{data.name}</p>
        <p>{data.email}</p>
        {/* <p>
          {data.message.slice(0, 159)}
          {data.message.length > 159 && '....'}
        </p> */}
        <p>{Data.phone ? Data.phone : 'N/A'}</p>
        <p>
          {data.updatedAt === null
            ? ConvertTime(data.createdAt)
            : ConvertTime(data.updatedAt)}
        </p>
        <div className="buyer_view_svg">
          <img
            onClick={() => setPreview('idle')}
            src={View}
            alt="View"
          />
        </div>
        <div className="buyer_selector">
          {loading === false ? (
            <select
              name=""
              id=""
              onChange={handleOnChange}
              value={data.status}>
              <option value="New">New</option>
              <option value="Interested now">Interested now</option>
              <option value="Interested late">Interested later</option>
              <option value="Call Later">Call Later</option>
              <option value="Not Interested">Not Interested</option>
              <option value="Do not contact">Do not contact</option>
              <option value="Meeting">Meeting</option>
              <option value="Closed">Closed</option>
            </select>
          ) : (
            <div className="loader"></div>
          )}
        </div>
      </div>
      {preview === 'idle' && (
        <div
          className="seller_preview_container"
          onClick={() => setPreview('close')}>
          <Preview
            Data={data}
            setPreview={setPreview}
          />
        </div>
      )}
    </>
  );
}
