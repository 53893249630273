export const tours_styles = {
    maskWrapper: (base) => ({
        ...base,
        backgroundColor: 'transparent',
        opacity: '0'

    }),
    highlightedArea: (base, { x, y }) => ({
        ...base,
        x: x + 10,
        y: y + 10,
    }),
    badge: (base) => ({
        ...base,
        color: 'white',
        backgroundColor: 'transparent',
        left: 10,
        top: 10,
        fontSize: 'clamp(1rem, 1.5vw , 1.5rem)',
        boxShadow: 'none',
        letterSpacing: '0.2em'
    }),
    popover: (base) => ({
        ...base,
        '--reactour-accent': '#FFFFFF',
        borderRadius: 25,
        backgroundColor: '#18181A',
        marginTop: 1,
        color: '#FFFFFF',
        padding: 30,
        paddingTop: 70,
        maxWidth: '453px',
        width: 'min(30rem,25rem)',
        fontSize: 'clamp(0.875rem, 1.125vw, 1.125rem)',
        fontWeight: 'medium',
        fontStyle: 'Montserrat'
    }),
    controls: (base) => ({ ...base, marginTop: 70 }),
    popoverContent: (base) => ({
        ...base,
        marginTop: 4,
    }),
};