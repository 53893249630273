/** @format */

import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { Container as HeroSection } from '../herosectionhome/herosectionhome.style';

export const Container = styled.div`
  font-family: 'Syncopate';
  width: min(100%, 120rem);
  margin: 0 auto;  
  p {
    font-family: 'Syncopate';
  }
`;

export const EmailContainer = styled(HeroSection)`
.leftSection {
  position: absolute;
  z-index: 1;
  width: 45%;
  padding: 2rem;
  top: 0; 
  left: 50%; 
  transform: translateX(-50%); 
   .Text {
      margin: 0.5rem 0; 
      font-size: clamp(1rem, 3vw, 1.5rem);
    }
}
  .input_container_email {
    flex-wrap: wrap;
    width: fit-content;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: ${(props) => props.theme.colors.background};
    padding: 1rem;
    width: 100%;
    gap: 0.45rem;
    .head_text * {
      color: ${(props) => props.$color};
      font-family: 'Syncopate';
    }
    .wrapper {
      flex: 1;
      display: flex;
      width: 100%;
      padding-block: 0.55rem;
      padding-inline: 0.25rem;
      gap: 0.5rem;
      svg {
        width: 24.5px;
        height: 24.5px;
        path {
          fill: black;
        }
      }
      .email_input {
        outline: none;
        border: none;
        background-color: transparent;
        flex: 1;
        text-transform: capitalize;
      }
    }
    .email_button {
      padding: 1rem;
      background-color: transparent;
      font-size: clamp(0.75rem, 1vw, 1.25rem);
      text-transform: capitalize;
      color: #fff;
      border-color: #fff;
      cursor: pointer;
    }
      .email_button:hover {
      background-color: #ffffff30;
    }
    @media screen and (max-width: 1503px) {
      flex-direction: column;
      background-color: transparent;
      .wrapper {
        background-color: ${(props) => props.theme.colors.background};
      }
      .email_button {
        margin-top: 0.45rem;
      }
    }
  }

  @media screen and (max-width: 929px) {
    display: flex;
    flex-direction: column;
    .email_container_content {
      flex: 1;
      transform: none;
      width: 100%;
      height: 100%;
      padding: 2rem;
      h1 {
        color: ${(props) => props.theme.colors.text2};
        margin-top: 2rem;
        margin-bottom: 1rem;
      }
      .input_container_email {
        background-color: transparent;
        .wrapper {
          width: 100%;
          background-color: ${(props) => props.theme.colors.background};
          border: 1px solid black;
        }
      }
    }
  }

  .leftSection {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center; 
    padding: 3rem 0.5rem; 
    margin: 0; 
    text-align: center;

    .Text {
      margin: 0.5rem 0; 
    }

    .input_container_email {
      width: 100%;
    }
  }
`;

export const FooterContainer = styled.div`
  width: min(120rem , 100%);
  margin: 0 auto;
  background-color: ${(props) => props.theme.colors.primary};
  display: flex;
  justify-content: space-between;
  /* padding-block: clamp(1rem, 2vw, 2rem);
  padding-inline: clamp(1rem, 4vw, 4rem); */
  position: relative;
  flex-direction: column;
  .upper_section {
    display: flex;
    justify-content: space-between;
    padding-block: clamp(1rem, 2vw, 2rem);
    padding-inline: clamp(1rem, 4vw, 4rem);
    .leftSection {
      flex: 1;
      .description {
        font-size: 0.75rem;
        margin-top: 1rem;
        width: min(95%, 20rem);
        word-break: break-word;
        word-wrap: break-word;
        color: white;
        text-align: left;
      }
    }
    .rightSection {
      display: flex;
      gap: 2rem;
      flex-wrap: wrap;
      flex: 2;
      justify-content: flex-end;
    }
    .agentshades_trademark {
      position: absolute;
      bottom: 5%;
      left: 2.5%;
      p {
        color: white;
      }
    }
    @media screen and (max-width: 459px) {
      flex-direction: column;
      .rightSection {
        margin-top: 2rem;
      }
    }
  }
  .down_section {
    border-top: 1px solid white;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    .footer_mark {
      font-family: Inter;
      font-size: 1rem;
      font-weight: 400;
      line-height: 1.3331rem;
      text-align: center;
      color: white;
    }
    @media screen and (max-width: 532px) {
      justify-content: center;
    }
  }
`;

export const NavigationContainer = styled.div`
  .footer_link_header {
    font-family: Syncopate;
    font-size: 0.9rem;
    font-weight: 500;
    line-height: 2rem;
    letter-spacing: -0.02em;
    text-align: left;
    color: #fff8f2;
    text-transform: capitalize;
  }
`;

export const FooterLinks = styled(Link)`
  display: block;
  text-decoration: none;
  color: ${(props) => props.theme.colors.text};
  font-family: Syncopate;
  font-size: 0.7rem;
  font-weight: 400;
  line-height: 2rem;
  text-align: left;
`;

export const FooterSocialMediaLinksContainer = styled.div`
  margin-top: 2rem;
  .image_wrapper {
    margin: 1rem;
  }
`;
