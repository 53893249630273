/** @format */

import { motion, useCycle } from 'framer-motion';
import { useRef } from 'react';
import MenuToggle from './menuetoggle.component';
import { NavLink, NavbarContainer, Sidebar } from './navbar.style';
import { useDimensions } from './use-dimentions';

const variants = {
  open: {
    transition: { staggerChildren: 0.07, delayChildren: 0.2 },
  },
  closed: {
    transition: { staggerChildren: 0.05, staggerDirection: -1 },
  },
};
const liVariants = {
  open: {
    y: 0,
    opacity: 1,
    transition: {
      y: { stiffness: 1000, velocity: -100 },
    },
  },
  closed: {
    y: 50,
    opacity: 0,
    transition: {
      y: { stiffness: 1000 },
    },
  },
};
const sidebar = {
  open: (height = 1000) => ({
    clipPath: `circle(${height * 2 + 200}px at 85% 40px)`,
    transition: {
      type: 'spring',
      stiffness: 20,
      restDelta: 2,
    },
  }),
  closed: {
    clipPath: 'circle(30px at 85% 40px)',
    transition: {
      delay: 0.5,
      type: 'spring',
      stiffness: 400,
      damping: 40,
    },
  },
};
export default function NavbarT3() {
  const [isOpen, toggleOpen] = useCycle(false, true);
  const containerRef = useRef(null);
  const { height } = useDimensions(containerRef);
  return (
    <NavbarContainer>
      <div className="links_container">
        <NavLink to={'../home'}>Home</NavLink>
        <NavLink to={'../idxlisting'}>Properties</NavLink>
        <NavLink to={'../about_us'}>About Us</NavLink>
        <NavLink to={'../contact_us'}>Contact</NavLink>
        <NavLink to={'../map'}>Map</NavLink>
      </div>
      <Sidebar
        initial={false}
        animate={isOpen ? 'open' : 'closed'}
        custom={height}
        ref={containerRef}>
        <motion.div
          className="background"
          variants={sidebar}>
          <motion.ul variants={variants}>
            <motion.li
              variants={liVariants}
              className="text-theme_red">
              <NavLink to={'../home'}>Home</NavLink>
            </motion.li>
            <motion.li
              variants={liVariants}
              className="text-theme_red">
              <NavLink to={'../idxlisting'}>Properties</NavLink>
            </motion.li>
            <motion.li
              variants={liVariants}
              className="text-theme_red">
              <NavLink to={'../about_us'}>About Us</NavLink>
            </motion.li>
            <motion.li
              variants={liVariants}
              className="text-theme_red">
              <NavLink to={'../contact_us'}>Contact</NavLink>
            </motion.li>
            <motion.li
              variants={liVariants}
              className="text-theme_red">
              <NavLink to={'../map'}>Map</NavLink>
            </motion.li>
          </motion.ul>
          <MenuToggle toggle={() => toggleOpen()} />
        </motion.div>
      </Sidebar>
    </NavbarContainer>
  );
}
