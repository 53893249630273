/** @format */

import styled from "styled-components";
import { motion } from "framer-motion";
import { transparentize } from "polished";
export const Container = styled.div`
  height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  z-index: 1000;
  top: 0;
  left: 0;
`;

type ErrorType = {
  $theme: any;
};

export const ErrorBoxContainer = styled(motion.div)<ErrorType>`
  width: min(27.8125rem, 95%);
  margin: 0 auto;
  background: ${(props) => props?.$theme?.primary_color};
  padding: 2rem;
  border-radius: ${(props) =>
    props?.$theme?.borderRadius ? props?.$theme?.borderRadius : "0px"};
  .svg_wrapper {
    text-align: center;
  }
  .try_again_message {
    font-family: ${({ $theme }) =>
      $theme?.warning_text?.fontFamily || "default-font-family"};
    font-size: ${({ $theme }) =>
      $theme?.warning_text?.fontSize || "default-font-size"};
    font-weight: ${({ $theme }) =>
      $theme?.warning_text?.fontWeight || "default-font-weight"};
    line-height: ${({ $theme }) =>
      $theme?.warning_text?.lineHeight || "default-line-height"};
    text-align: center;
    margin: 0;
    margin-top: 0.25rem;
    color: white;
  }
  .message {
    font-family: ${({ $theme }) =>
      $theme?.warning_message?.fontFamily || "defaultFontFamily"};
    font-size: ${({ $theme }) =>
      $theme?.warning_message?.fontSize || "defaultFontSize"};
    font-weight: ${({ $theme }) =>
      $theme?.warning_message?.fontWeight || "defaultFontWeight"};
    line-height: 1.2656rem;
    text-align: center;
    color: white;
  }
  .close_button {
    background: #ffffff;
    display: block;
    width: fit-content;
    padding: 0.9375rem 1.25rem 0.9375rem 1.25rem;
    margin: 0 auto;
    text-align: center;
    text-align: left;
    border: none;
    outline: none;
    font-family: ${({ $theme }) =>
      $theme?.warning_text?.fontFamily || "default-font-family"};
    font-size: ${({ $theme }) =>
      $theme?.warning_text?.fontSize || "default-font-size"};
    font-weight: ${({ $theme }) =>
      $theme?.warning_text?.fontWeight || "default-font-weight"};
    line-height: ${({ $theme }) =>
      $theme?.warning_text?.lineHeight || "default-line-height"};
  }
`;
