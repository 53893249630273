/** @format */

import Slider from 'react-slick';
import HeroBlogCard from './hero_blog_card/hero_blog_card.component';
import { BlogHeroContainer } from './hero_blog_container.style';
export default function HeroBlogContainer({ data }) {
  const HeroBlogContainerSetting = {
    arrows: false,
    infinite: true,
    speed: 2000,
    slidesToShow: 1,
    slidesToScroll: 1,
    // autoplay: true,
    // autoplaySpeed: 8000,
    dots: true,
  };
  return (
    <BlogHeroContainer>
      <div className="header_container">
        <p className="heading">Latest articles and blogs</p>
        <p className="sub_heading">Lorem ipsum dolor sit amet consectetur.</p>
      </div>
      <Slider {...HeroBlogContainerSetting}>
        {data.map((data) => (
          <HeroBlogCard
            data={data}
            key={data.id}
          />
        ))}
      </Slider>
    </BlogHeroContainer>
  );
}
