/** @format */

import styled from 'styled-components';

export const HeroBlogCardContainer = styled.div`
  background: ${({ theme: { colors } }) => colors.text};
  border-radius: 1.25rem;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  margin: 0 auto;
  padding: 2.25rem;
  width: min(70.625rem, 95%);
  .left_container_blog {
    flex: 1;
    img {
      width: 550px;
      height: 418px;
      min-width: 100%;
      max-width: 100%;
    }
  }
  .right_container_blog {
    flex: 1;
    .upsection {
      margin-bottom: 2.4rem;
      .header {
        font-family: Syncopate;
        font-size: 2.25rem;
        font-size: clamp(2rem, 2.25vw, 2.25rem);
        font-weight: 400;
        line-height: 3.3344rem;
        color: ${({ theme: { colors } }) => colors.primary};
      }
      .description {
        font-family: Syncopate;
        font-size: 1.125rem;
        font-size: clamp(1rem, 1.125vw, 1.125rem);
        font-weight: 400;
        line-height: 1.3181rem;
        text-align: left;
        color: ${({ theme: { colors } }) => colors.primary};
        overflow: hidden;
        width: 100%;
        display: -webkit-box;
        -webkit-line-clamp: 10;
        -webkit-box-orient: vertical;
      }
    }
    .downSection {
      display: flex;
      align-items: center;
      gap: 1rem;
      .svg_wrapper {
        width: 3.9375rem;
        height: 3.9375rem;
        border-radius: 3.125rem;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
      }
      .blog_read_info {
        .min_info {
          color: ${({ theme: { colors } }) => colors.primary};
          font-family: Syncopate;
          font-size: 1.5rem;
          font-size: clamp(0.75rem, 1vw, 1.5rem);
          font-weight: 500;
          line-height: 1.7581rem;
          text-align: left;
          margin: 0;
        }
        .blog_date {
          color: ${({ theme: { colors } }) => colors.primary};
          font-size: 1.125rem;
          font-size: clamp(1rem, 1.125vw, 1.125rem);
          font-weight: 400;
          line-height: 1.3181rem;
          text-align: left;
        }
      }
    }
  }
  @media screen and (max-width: 873px) {
    flex-direction: column;
  }
`;
