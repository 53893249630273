
export default function CrmsettingHeader() {
  return (
    <div>
      <nav
        className="d-flex justify-content-between align-items-center">
        <p className="header">CRM Integration</p>
      </nav>
    </div>
  )
}
