/** @format */

import styled from 'styled-components';

export const MapContainer = styled.div`
  width: min(120rem, 100%);
  margin: 0 auto;
  margin-bottom: 7rem;
  position: relative;
  overflow: hidden;
  .marker-cluster-property {
    font-size: 6rem;
    cursor: pointer;
  }

  .marker-clustering-control-panel .attribution {
    font-size: 0.75em;
  }
`;
export const IdxListing = styled.div`
  flex: 0.4;
  width: 100%;
  height: 100%;
  overflow-y: scroll;
  /* position: relative; */
  .idxContainer {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    gap: 0.45rem;
    margin-bottom: 5rem;
  }
  .peginate_class {
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 1000;
    background-color: white;
    padding-left: 0;
    padding-inline: 1rem;
    width: 100%;
    margin: 0 auto;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-wrap: wrap;
    .page,
    .break {
      list-style-type: none;
      color: ${(props) => props.theme.colors.primary};
      border: 1px solid gray;
      /* padding: 0.25rem 1rem; */
      height: 2.2rem;
      flex: 1;
      text-align: center;
      transition: all 200ms;
      display: flex;
      justify-content: center;
      align-items: center;
      a {
        display: block;
        width: 100%;
      }
      &:hover {
        transition: all 200ms;
        color: ${(props) => props.theme.colors.text};
        background-color: ${(props) => props.theme.colors.primary};
      }
    }
    .selected {
      transition: all 200ms;
      color: ${(props) => props.theme.colors.text};
      background-color: ${(props) => props.theme.colors.primary};
    }
    .previous,
    .next {
      list-style-type: none;
      flex: 1;
      text-align: center;
      padding: 0.25rem 1rem;
      border: 1px solid gray;
      color: ${(props) => props.theme.colors.primary};
      a {
        color: ${(props) => props.theme.colors.primary};
        text-decoration: none;
      }
    }
    .previous {
      border-bottom-left-radius: 0.345rem;
      border-top-left-radius: 0.345rem;
      cursor: pointer;
    }
    .next {
      cursor: pointer;
      border-bottom-right-radius: 0.345rem;
      border-top-right-radius: 0.345rem;
    }
    /* .previous_link {
      color: ${(props) => props.theme.colors.primary};
    }
    .next_link {
      color: ${(props) => props.theme.colors.primary};
    } */
  }
`;
export const MapListing = styled.div`
  flex: 0.6;
  width: 100%;
  height: 100%;
  .map {
    width: 100%;
    height: 100%;
  }
`;
export const MapIdxContainer = styled.div`
  width: 100%;
  height: calc(100vh - 246px);
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
`;

export const SpinnerContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  backdrop-filter: blur(10px);
`;
