
import { Container, WarningBox } from './tools.style'

export default function Tools() {
    return (
        <Container>
            <WarningBox>
                <p>
                    Coming Soon
                </p>
            </WarningBox>
        </Container>
    )
}
