import axios from "axios";
import { useState } from "react";
import { useMessageContext } from "../../context/Message.context";

type StateProps = {
  name: string;
  newsletteremail: string;
};

export default function useAddUserForNewseletter() {
  const { setErrorShow, setMessage } = useMessageContext();
  const [stateData, setStateData] = useState<StateProps>({
    name: "",
    newsletteremail: "",
  });
  function handleSubscriptionForNewsletter(
    e: React.FormEvent<HTMLFormElement>,
  ): void {
    handleSubscription(e, stateData.name, stateData.newsletteremail);
  }
  const handleSubscription = async (
    e:
      | React.MouseEvent<HTMLButtonElement, MouseEvent>
      | React.FormEvent<HTMLFormElement>,
    name: string,
    newsletteremail: string,
  ) => {
    e.preventDefault();
    const domain = window.location.href.split("/")[2];
    try {
      await axios.post("/api/add-newsletter", {
        domain,
        name,
        newsletteremail,
      });
      setStateData((prev) => ({ ...prev, name: "", newsletteremail: "" }));
    } catch (err) {
      setErrorShow(true);
      setMessage(`Couldn't add your email to the newsletter. Try again later.`);
      setStateData((prev) => ({ ...prev, name: "", newsletteremail: "" }));

      throw new Error(err.message);
    }
  };
  function handleState(state: string, value: string): void {
    setStateData((prev) => ({ ...prev, [state]: value }));
  }

  return {
    handleSubscription,
    stateData,
    handleState,
    handleSubscriptionForNewsletter,
  };
}
