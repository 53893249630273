/** @format */

import React from 'react';
import './Footer.css';
import LiveEdithTextHighLighter from '../../../liveEditHighlighterstyles/liveEdithTextHighLighter.component';

export default function Footer({ footerData }) {
    return (
        <div className="footer" id='footer'>
            <div className="container-xl">
                <div className="row">
                    <div className="col-md-6" style={{paddingLeft:"32px"}}>
                        <h2 style={{ color: footerData.color, fontFamily: 'Syncopate' }}>
                            <LiveEdithTextHighLighter
                                section="footer"
                                type="text">
                                {' '}
                                {footerData.text}
                            </LiveEdithTextHighLighter>
                        </h2>
                    </div>
                    {footerData.columns.map((item, index) => (
                        <div
                            className="col-md-2"
                            key={index} style={{display:"grid"}}>
                            <h4 style={{ color: item.color, fontFamily: 'Syncopate' }}>
                                {item.text}
                            </h4>
                            {item.items.map((link, index) => (
                                <a
                                    href={link.link}
                                    style={{ color: link.color, fontFamily: 'Garamond' }}
                                    key={index}>
                                    {link.text}
                                </a>
                            ))}
                        </div>
                    ))}
                </div>
            </div>
            <div className='agentshades_trademark'>
                <p>Powered by <a target='_blank' href="https://agentshades.com" rel='noreferrer'>agentshades.com</a></p>
            </div>
        </div>
    );
}
