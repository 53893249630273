/** @format */

import { createContext, useReducer, ReactNode, useContext } from "react";

type State = {
  min_price: string;
  max_price: string;
  home_type: string;
  building_status: string;
  city: number;
  bedroom: string;
  bathroom: string;
  sortBy: string;
  sortDir: string;
  paginate: string;
  page: string;
  search: string;
  sale_or_lease: string;
  property_style: string;
  property_type: string;
  sortDirByName: string;
  streetSearch:string | null;
};

type Action =
  | {
      type: "Set_Property";
      payload: { property: string; value: string | number };
    }
  | { type: "peginate"; payload: string }
  | { type: "page"; payload: string }
  | { type: "Reset" };

type ContextValue = {
  state: State;
  dispatch: React.Dispatch<Action>;
  setProperty: (property: string, value: string | number) => void;
  paginate: (value: string) => void;
  setPage: (value: string) => void;
  reset: () => void;
};

const initialState: State = {
  min_price: "",
  max_price: "",
  home_type: "",
  building_status: "",
  city: 0,
  bedroom: "",
  bathroom: "",
  sortBy: "",
  sortDir: "desc",
  paginate: "19",
  page: "1",
  search: "",
  sale_or_lease: "Sale",
  property_style: "",
  property_type: "",
  sortDirByName: "Most Recent",
  streetSearch:null,
};

const FilterReducer = (state: State, action: Action): State => {
  switch (action.type) {
    case "Set_Property":
      return { ...state, [action.payload.property]: action.payload.value };
    case "peginate":
      return { ...state, paginate: action.payload };
    case "page":
      return { ...state, page: action.payload };
    case "Reset":
      return initialState;
    default:
      return state;
  }
};

const FilterContext = createContext<ContextValue | undefined>(undefined);

interface NewConstructionProviderProps {
  children: ReactNode;
}

const FilterContextProvider = ({ children }: NewConstructionProviderProps) => {
  const [state, dispatch] = useReducer(FilterReducer, initialState);

  const setProperty = (property: string, value: string) => {
    dispatch({ type: "Set_Property", payload: { property, value } });
  };

  const paginate = (value: string) => setProperty("paginate", value);
  const setPage = (value: string) => setProperty("page", value);

  const reset = () => dispatch({ type: "Reset" });

  const contextValue: ContextValue = {
    state,
    dispatch,
    setProperty,
    paginate,
    setPage,
    reset,
  };

  return (
    <FilterContext.Provider value={contextValue}>
      {children}
    </FilterContext.Provider>
  );
};

//custom Hook

export function useFilterContext() {
  const constext = useContext(FilterContext);
  if (!constext) {
    throw new Error(
      "FilterContext must be used within a FilterContextProvider",
    );
  }
  return constext;
}

export default FilterContextProvider;
