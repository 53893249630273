/** @format */

import { createContext, useReducer, useContext, ReactNode } from 'react';

type State = {
    section: string;
    type: string;
    liveEditActive: 'active' | 'inactive' | null;
    loader?: boolean;
};

type Action = {
    type: 'setActiveSection';
    payload: {
        section?: string;
        type?: string;
        liveEditActive?: 'active' | 'inactive' | null;
    };
} | {
    type: 'sectActiveSectionImage';
    payload: {
        section: string;
        type: string;
        liveEditActive: 'active' | 'inactive' | null;
        loader: boolean
    }
};

type ContextValue = {
    state: State;
    setHighLightedSection: (
        section: string,
        type: string,
        liveEditActive: 'active' | 'inactive' | null) => void
    sectActiveSectionImage: (
        section: string,
        type: string,
        liveEditActive: 'active' | 'inactive' | null,
        loader: boolean
    ) => void
};

const initialState: State = {
    section: '',
    type: '',
    liveEditActive: null,
    loader: false
};

const LiveEditorHighLightReducer = (state: State, action: Action): State => {
    switch (action.type) {
        case 'setActiveSection':
            return {
                ...state,
                section: action.payload.section,
                type: action.payload.type,
                liveEditActive: action.payload.liveEditActive,
            };
        case 'sectActiveSectionImage':
            return {
                ...state,
                section: action.payload.section,
                type: action.payload.type,
                liveEditActive: action.payload.liveEditActive,
                loader: action.payload.loader
            }
    }
};

const LiveEditorHighLighterContext = createContext<ContextValue | undefined>(undefined)

type NewLiveEditorHighLighterProviderProps = {
    children: ReactNode
}

const LiveEditorHighLighterProvider = ({ children }: NewLiveEditorHighLighterProviderProps) => {
    const [state, dispatch] = useReducer(LiveEditorHighLightReducer, initialState)

    const setHighLightedSection = (section: string, type: string, liveEditActive: 'active' | 'inactive' | null) => {
        dispatch({ type: 'setActiveSection', payload: { section, type, liveEditActive } })
    }
    const sectActiveSectionImage = (section: string, type: string, liveEditActive: 'active' | 'inactive' | null, loader: boolean) => {
        dispatch({ type: 'sectActiveSectionImage', payload: { section, type, liveEditActive, loader } })
    }
    const contextValue: ContextValue = {
        state,
        setHighLightedSection,
        sectActiveSectionImage
    }
    return (
        <LiveEditorHighLighterContext.Provider value={contextValue}>
            {children}
        </LiveEditorHighLighterContext.Provider>
    )
}

//custom Hook 

export function useLiveEditorHighLighter() {
    const context = useContext(LiveEditorHighLighterContext)
    if (!context) {
        console.log('FilterContext must be used within a FilterContextProvider')
    }
    return context
}

export default LiveEditorHighLighterProvider