/** @format */

import { useEffect, useState } from 'react';
import {
  Amenities,
  Appoinment,
  AppointmentContainer,
  Container,
  Detail,
  HomeValue,
  LeftContainer,
  Map,
  OverView,
  PropertyDetails,
  RightContainer,
} from './Details.style';
import BulletPoints from './Images/BulletsPoints.svg';
import Banks from './Images/fluent-emoji_bank.svg';
import BusStand from './Images/fluent-emoji_bus-stop.svg';
import Parks from './Images/fluent-emoji_evergreen-tree.svg';
import Attractions from './Images/fluent-emoji_ferris-wheel.svg';
import House from './Images/fluent-emoji_house-with-garden.svg';
import Hospital from './Images/fluent-emoji_medical-symbol.svg';
import Cinemas from './Images/fluent-emoji_movie-camera.svg';
import Food from './Images/fluent-emoji_pot-of-food.svg';
import Shopping from './Images/fluent-emoji_shopping-bags.svg';
import Sunset from './Images/fluent-emoji_sunset.svg';
// import VideoPlayer from '../VideoPlayer/VideoPlayer.compoent';
import { useParams } from 'react-router-dom';
import { ConvertTime } from '../../../utils/timezoneconvertor';
import FeatureFacts from './Components/Feature&Facts/Feature_Facts.component';
import NearBySchool from './Components/NearBySchool/NearBySchool.component';

import { useMessageContext } from '../../../context/Message.context';
import AppointmentForm from './Components/appointment/Appointment.component';
import moment from 'moment';
export default function Details({ Data }) {
  const [readMore, setReadMore] = useState(true);
  const [appointment, setAppointment] = useState(false);
  const [lowPrice, setLowPrice] = useState(null);
  const [highPrice, setHighPrice] = useState(null);
  const [redBuyersPrice, setRedBuyersPrice] = useState(null);
  const { type } = useParams();
  const {
    state: { SuccessShow, ErrorShow },
  } = useMessageContext();
  const handleRedmore = () => {
    setReadMore(!readMore);
  };
  function Rounded(num) {
    return (Math.round(num * 100) / 100).toFixed();
  }
  const convertToValue = (num) => {
    const RoundedValue = Rounded(num);
    return RoundedValue.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  };

  useEffect(() => {
    const property_price_home_value = () => {
      if (Data?.list_price) {
        let real_value = Data?.list_price;
        let home_value;
        const price_discount = Math.floor(Math.random() * 5) + 1;
        const price_symbol_array = ['+', '-'];
        const price_symbol =
          price_symbol_array[
            Math.floor(Math.random() * price_symbol_array.length)
          ];
        const discounted_amount = (price_discount / 100) * real_value;
        if (price_symbol === '-') {
          home_value = real_value - discounted_amount;
        } else {
          home_value = real_value + discounted_amount;
        }
        let round = (Math.round(home_value * 100) / 100).toFixed();
        // lease block
        if (Data?.sale_or_lease?.toLowerCase() === 'lease') {
          if (real_value >= 15000) {
            const price_discount_new = Math.floor(Math.random() * 7) + 1;
            const discounted_amount_new =
              (price_discount_new / 100) * real_value;
            setLowPrice(home_value - discounted_amount_new);
            setHighPrice(home_value + discounted_amount_new);
          } else {
            const price_discount_new = Math.floor(Math.random() * 5) + 1;
            const discounted_amount_new =
              (price_discount_new / 100) * real_value;
            setLowPrice(home_value - discounted_amount_new);
            setHighPrice(home_value + discounted_amount_new);
          }
        } else {
          // Sale block
          if (real_value >= 1800000) {
            setLowPrice(home_value - 150000);
            setHighPrice(home_value + 150000);
          } else {
            setLowPrice(home_value - 100000);
            setHighPrice(home_value + 100000);
          }
        }
        setRedBuyersPrice(
          round.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
        );
      }
    };
    property_price_home_value();
  }, [Data?.list_price]);
  useEffect(() => {
    setAppointment(false);
  }, [SuccessShow, ErrorShow]);
  const SoldInDays = (listedDay, soldDay) => {
    if (!listedDay || !soldDay) {
      return null;
    }
    let start = moment(listedDay);
    let end = moment(soldDay);

    return end.diff(start, 'days');
  };
  return (
    <>
      <Container>
        <LeftContainer>
          <OverView
            id="Overview"
            Check={readMore}>
            <h1>{`Why ${Data?.address},${Data?.city} ?`}</h1>
            <div className="InfoContainer">
              <div className="Info">
                <h1>Type</h1>
                <h3>
                  {Data?.property_type === null
                    ? 'No data'
                    : Data?.property_type}
                </h3>
              </div>
              <div className="Info">
                <h1>Bedroom</h1>
                <h3>{Data?.bedrooms === null ? 0 : Data?.bedrooms}</h3>
              </div>
              <div className="Info">
                <h1>Square Foot</h1>
                <h3>
                  {type === 'sold' ? (
                    <>
                      <h3>
                        {JSON.parse(Data?.property_details).square_footage ===
                          '' ||
                        !JSON.parse(Data?.property_details).square_footage
                          ? 'No Data'
                          : !JSON.parse(Data?.property_details).square_footage}
                      </h3>
                      <p>Square Foot</p>
                    </>
                  ) : (
                    <>
                      <h3>
                        {Data?.property_details.Sqft === '' ||
                        !Data?.property_details.sqft
                          ? 'No Data'
                          : Data?.property_details.Sqft}
                      </h3>
                      <p>Square Foot</p>
                    </>
                  )}
                </h3>
              </div>
              <div className="Info">
                <h1>Bathroom</h1>
                <h3>{!Data?.bathrooms ? 0 : Data?.bathrooms}</h3>
              </div>
              <div className="Info">
                <h1>Garage</h1>
                <h3>{!Data?.garage_spaces ? 0 : Data?.garage_spaces}</h3>
              </div>
              <div className="Info">
                <h1>Status</h1>
                <h3>{!Data?.Status ? 'No data' : Data?.Status}</h3>
              </div>
              <div className="Info">
                <h1>Age</h1>
                <h3>
                  {
                    Data?.propertyDetails?.find((data) => data.name === 'Age')
                      .value
                  }
                </h3>
              </div>
              <div className="Info">
                <h1>Frontage</h1>
                <h3>No Data</h3>
              </div>
              <div className="Info">
                <h1>Frontage</h1>
                <h3>No Data</h3>
              </div>
              {type === 'sold' && (
                <div className="Info">
                  <h1>Sold On</h1>
                  <h3>{Data?.sold_date?.split(' ')?.[0]}</h3>
                </div>
              )}
              {type === 'sold' && (
                <div className="Info">
                  <h1>Sold In</h1>
                  <h3>
                    {SoldInDays(
                      JSON.parse(Data?.property_details)?.Input_date,
                      JSON.parse(Data?.property_details)?.Unavail_dt
                    )}
                  </h3>
                </div>
              )}
            </div>
            {Data?.property_description && (
              <div
                className="Description"
                dangerouslySetInnerHTML={{
                  __html: Data?.property_description,
                }}></div>
            )}
            {Data?.property_description && (
              <div
                className="Wrapper"
                onClick={handleRedmore}>
                <p className="read_more">Read more about the property</p>

                <svg
                  className="SvgWrapper"
                  xmlns="http://www.w3.org/2000/svg"
                  width="10"
                  height="5"
                  viewBox="0 0 10 5"
                  fill="none">
                  <path
                    d="M0 0L5 5L10 0H0Z"
                    fill="white"
                  />
                </svg>
              </div>
            )}
          </OverView>
          <Map id="Map">
            <h1>Explore Neighborhood - Map View</h1>
            <div className="ContainerMap">
              <div className="leftSection">
                <iframe
                  title="Map"
                  src={`https://maps.google.com/maps?q= ${Data?.address} &hl=en&z=15&output=embed&`}
                  allowfullscreen="allowfullscreen"
                  loading="lazy"
                  referrerpolicy="no-referrer-when-downgrade"
                  style={{ border: '0px' }}></iframe>
              </div>
              <div className="rightSection">
                <div className="BulletPoints">
                  <div className="wrapper">
                    <img
                      src={BusStand}
                      alt=""
                    />
                  </div>
                  <p>Commute</p>
                </div>
                <div className="BulletPoints">
                  <div className="wrapper">
                    <img
                      src={Banks}
                      alt=""
                    />
                  </div>
                  <p>Banks</p>
                </div>
                <div className="BulletPoints">
                  <div className="wrapper">
                    <img
                      src={Cinemas}
                      alt=""
                    />
                  </div>
                  <p>Cinemas</p>
                </div>
                <div className="BulletPoints">
                  <div className="wrapper">
                    <img
                      src={Parks}
                      alt=""
                    />
                  </div>
                  <p>Parks</p>
                </div>
                <div className="BulletPoints">
                  <div className="wrapper">
                    <img
                      src={Shopping}
                      alt=""
                    />
                  </div>
                  <p>Shopping</p>
                </div>
                <div className="BulletPoints">
                  <div className="wrapper">
                    <img
                      src={Food}
                      alt=""
                    />
                  </div>
                  <p>Food</p>
                </div>
                <div className="BulletPoints">
                  <div className="wrapper">
                    <img
                      src={Hospital}
                      alt=""
                    />
                  </div>
                  <p>Hospitals</p>
                </div>
                <div className="BulletPoints">
                  <div className="wrapper">
                    <img
                      src={Attractions}
                      alt=""
                    />
                  </div>
                  <p>Attractions</p>
                </div>
              </div>
            </div>
          </Map>
          {Data?.property?.amenities && (
            <Amenities>
              <h1>Amenities</h1>
              <div className="Container">
                {Data?.property?.amenities?.map((data, i) => (
                  <div
                    className="Amenities"
                    key={i}>
                    <div className="wrapper">
                      <img
                        src={Sunset}
                        alt=""
                      />
                    </div>
                    <p>{data}</p>
                  </div>
                ))}
              </div>
            </Amenities>
          )}
          {Data?.nearby_school?.length && (
            <NearBySchool Data={Data?.nearby_school} />
          )}
          <PropertyDetails id="Property_Details">
            <h1>PropertyDetails</h1>
            <div className="DetailsContainer">
              {Data?.propertyDetails?.map((data) => (
                <Detail key={data.name}>
                  <div className="Wrapper">
                    <img
                      src={BulletPoints}
                      alt="BulletPoints"
                    />
                  </div>
                  <p>
                    {' '}
                    {data.name} :{' '}
                    <span>
                      {' '}
                      {Data?.name === 'Added'
                        ? ConvertTime(data?.value)
                        : data?.value}
                    </span>
                  </p>
                </Detail>
              ))}
            </div>
          </PropertyDetails>
          {/* //feature */}
          <FeatureFacts Data={Data?.factsFeatures} />
          {/* homeValuation  */}
          {type !== 'sold' && (
            <HomeValue
              id="estimated-section"
              className="border-bottom-0 pb-0">
              <div className="estimated-card">
                <h3>Your Estimated Home Value</h3>
                <div className="estimated-card-header">
                  <div className="estimated-card-header">
                    <div className="estimated-property-details">
                      <div className="d-info">
                        <svg
                          width="12"
                          height="16"
                          viewBox="0 0 12 16"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg">
                          <path
                            d="M6.00006 0.5C2.69181 0.5 6.10835e-05 3.19175 6.10835e-05 6.49625C-0.0216889 11.33 5.77206 15.338 6.00006 15.5C6.00006 15.5 12.0218 11.33 12.0001 6.5C12.0001 3.19175 9.30831 0.5 6.00006 0.5ZM6.00006 9.5C4.34256 9.5 3.00006 8.1575 3.00006 6.5C3.00006 4.8425 4.34256 3.5 6.00006 3.5C7.65756 3.5 9.00006 4.8425 9.00006 6.5C9.00006 8.1575 7.65756 9.5 6.00006 9.5Z"
                            fill="black"
                          />
                        </svg>
                        <span>{Data?.address}</span>
                      </div>
                      <div className="d-info">
                        <svg
                          width="28"
                          height="19"
                          viewBox="0 0 28 19"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg">
                          <path
                            d="M22.75 2.75H15.75C14.0931 2.75 12.75 4.09315 12.75 5.75V8.5C12.75 10.1569 11.4069 11.5 9.75 11.5H5.75C4.09315 11.5 2.75 10.1569 2.75 8.5V1.5C2.75 0.809644 2.19036 0.25 1.5 0.25C0.809644 0.25 0.25 0.809644 0.25 1.5V17.75C0.25 18.4404 0.809644 19 1.5 19C2.19036 19 2.75 18.4404 2.75 17.75V17.125C2.75 16.0895 3.58947 15.25 4.625 15.25H23.375C24.4105 15.25 25.25 16.0895 25.25 17.125V17.75C25.25 18.4404 25.8096 19 26.5 19C27.1904 19 27.75 18.4404 27.75 17.75V7.75C27.75 6.42392 27.2232 5.15215 26.2855 4.21447C25.3478 3.27678 24.0761 2.75 22.75 2.75ZM7.75 10.25C8.74456 10.25 9.69839 9.85491 10.4016 9.15165C11.1049 8.44839 11.5 7.49456 11.5 6.5C11.5 5.50544 11.1049 4.55161 10.4016 3.84835C9.69839 3.14509 8.74456 2.75 7.75 2.75C6.75544 2.75 5.80161 3.14509 5.09835 3.84835C4.39509 4.55161 4 5.50544 4 6.5C4 7.49456 4.39509 8.44839 5.09835 9.15165C5.80161 9.85491 6.75544 10.25 7.75 10.25Z"
                            fill="#323232"
                          />
                        </svg>

                        <span>{Data?.bedrooms} Bed</span>
                      </div>
                      <div className="d-info">
                        <svg
                          width="30"
                          height="30"
                          viewBox="0 0 30 30"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg">
                          <path
                            d="M26.875 17.5C26.5298 17.5 26.25 17.7798 26.25 18.125V18.75C26.25 20.858 25.2073 22.7224 23.6037 23.8528C23.228 24.1176 23.0161 24.5757 23.128 25.0215L23.4379 26.2566C23.5964 26.888 23.119 27.5 22.468 27.5H22.0308C21.5719 27.5 21.1719 27.1877 21.0606 26.7425L20.8144 25.7575C20.7031 25.3123 20.3031 25 19.8442 25H10.1558C9.69691 25 9.29693 25.3123 9.18563 25.7575L8.93937 26.7425C8.82807 27.1877 8.42809 27.5 7.96922 27.5H7.53198C6.88103 27.5 6.4036 26.888 6.56205 26.2566L6.87183 25.0222C6.98381 24.576 6.77154 24.1178 6.39536 23.853C5.68775 23.3548 5.08916 22.713 4.64049 21.9664C4.05685 20.9951 3.74898 19.8831 3.75 18.75V18.125C3.75 17.7798 3.47018 17.5 3.125 17.5C2.77982 17.5 2.5 17.2202 2.5 16.875V16C2.5 15.4477 2.94772 15 3.5 15H24C24.5523 15 25 14.5523 25 14V6.25C25 5.91848 24.8683 5.60054 24.6339 5.36612C24.3995 5.1317 24.0815 5 23.75 5C23.3556 5 23.0209 5.16924 22.79 5.43548C22.5381 5.72585 22.6957 6.13914 22.9385 6.43712C23.2529 6.82283 23.4847 7.27331 23.6182 7.76168C23.7639 8.2944 23.3023 8.75 22.75 8.75H17.25C16.6977 8.75 16.237 8.29454 16.3825 7.76178C16.5529 7.13817 16.8832 6.56346 17.3483 6.09835C18.0516 5.39509 19.0054 5 20 5C20.1274 5 20.2398 4.91917 20.289 4.80173C20.8537 3.45526 22.1881 2.5 23.75 2.5C24.7446 2.5 25.6984 2.89509 26.4017 3.59835C27.1049 4.30161 27.5 5.25544 27.5 6.25V16.875C27.5 17.2202 27.2202 17.5 26.875 17.5Z"
                            fill="#323232"
                          />
                        </svg>
                        <span>{Data?.bathrooms} Bath</span>
                      </div>
                      <div className="d-info">
                        <svg
                          width="18"
                          height="19"
                          viewBox="0 0 18 19"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg">
                          <path
                            d="M17.75 18C17.75 18.5523 17.3023 19 16.75 19H16.25C15.6977 19 15.25 18.5523 15.25 18V8.75C15.25 8.19772 14.8023 7.75 14.25 7.75H3.75C3.19772 7.75 2.75 8.19772 2.75 8.75V18C2.75 18.5523 2.30228 19 1.75 19H1.25C0.697715 19 0.25 18.5523 0.25 18V5.83032C0.25 5.47147 0.442286 5.14012 0.753861 4.96208L8.50386 0.533508C8.81129 0.357832 9.18871 0.357832 9.49614 0.533508L17.2461 4.96208C17.5577 5.14012 17.75 5.47147 17.75 5.83032V18ZM4 10C4 9.44771 4.44772 9 5 9H13C13.5523 9 14 9.44771 14 10V10.5C14 11.0523 13.5523 11.5 13 11.5H5C4.44772 11.5 4 11.0523 4 10.5V10ZM4 13.75C4 13.1977 4.44772 12.75 5 12.75H13C13.5523 12.75 14 13.1977 14 13.75V14.25C14 14.8023 13.5523 15.25 13 15.25H5C4.44772 15.25 4 14.8023 4 14.25V13.75ZM13 16.5C13.5523 16.5 14 16.9477 14 17.5V18C14 18.5523 13.5523 19 13 19H5C4.44772 19 4 18.5523 4 18V17.5C4 16.9477 4.44772 16.5 5 16.5H13Z"
                            fill="#323232"
                          />
                        </svg>

                        <span> {Data?.garage_spaces} Garages</span>
                      </div>
                    </div>
                    <div className="redbuyers_info">
                      <small>Redbuyer’s Estimate:</small>
                      <p class="font-weight-bold ">${redBuyersPrice}</p>
                    </div>
                  </div>
                  <div className="sale_range">
                    <small>Estimated Sales Range:</small>
                    <p class="font-weight-bold">
                      ${convertToValue(lowPrice)}-${convertToValue(highPrice)}
                    </p>
                  </div>
                </div>
                <div class="estimated-card-body mr-lg-5">
                  <div class="estimated-slider-wrapper">
                    <div class="slider-bar">
                      <div class="slider-price slider-price--left">
                        ${convertToValue(lowPrice)}
                      </div>
                      <div class="slider-price slider-price--center">
                        ${redBuyersPrice}
                      </div>
                      <div class="slider-price slider-price--right">
                        ${convertToValue(highPrice)}
                      </div>
                      <div class="slider-bar-ball center"></div>
                    </div>
                  </div>
                </div>
                <div class="estimated-card-footer">
                  <p class="mb-2">
                    *Approximate value based on Comparable homes in the area.
                  </p>
                  <p class="mb-0">
                    **An accurate cash offer might be different from the
                    estimate shown above and exact offer will be provided after
                    viewing the property
                  </p>
                </div>
              </div>
            </HomeValue>
          )}
        </LeftContainer>
        <RightContainer>
          <Appoinment onClick={() => setAppointment(!appointment)}>
            <div className="animation">
              <div
                className="wrapper"
                onClick={() => setAppointment(!appointment)}>
                <img
                  src={House}
                  alt="House"
                />
              </div>
            </div>
            <div className="contents">
              <p>
                Click here to schedule an <br /> appoinment!
              </p>
            </div>
          </Appoinment>
          {/* <div className="ACard">
                        <div className="Contents">
                            <h1>Down Payment Assistance for your Home?</h1>
                            <p>
                                Transform your down payment into 20% <br /> or more with the
                                help from BuilderMatch
                            </p>
                        </div>
                        <img
                            src={MoneyWithWings}
                            alt="MoneyWithWings"
                        />
                    </div>
                    <div className="ACard">
                        <div className="Content">
                            <h1>Co-investment at 1/8th Cost!</h1>
                            <p>Min ROI 25%. Click to learn more!</p>
                        </div>
                        <img
                            src={HomeWithPeople}
                            alt="HomeWithPeople"
                        />
                    </div> */}
        </RightContainer>
        <Appoinment className="mobileAnimation">
          <div className="animation">
            <div
              className="wrapper"
              onClick={() => setAppointment(!appointment)}>
              <img
                src={House}
                alt="House"
              />
            </div>
          </div>
        </Appoinment>
      </Container>
      {appointment && (
        <AppointmentContainer onClick={() => setAppointment(!appointment)}>
          <AppointmentForm Data={Data} />
        </AppointmentContainer>
      )}
    </>
  );
}
