/** @format */

import styled from 'styled-components';

export const DashboardNavContainer = styled.div`
  background: #18181a;
  border-radius: 0.9375rem;
  padding: 0.875rem 1.25rem 0.875rem 1.25rem;
  border-radius: 0.9375rem;

  .heading {
    color: #999999;
    font-family: Montserrat;
    font-size: 1.125rem;
    font-weight: 700;
    line-height: 1.3713rem;
    letter-spacing: 0.2em;
    text-transform: uppercase;
    //remove this letter
    padding: 0;
    margin: 0;
  }
`;

export const DashboardButton = styled.button`
  padding: 0.5rem 0.625rem 0.5rem 0.625rem;
  gap: 0.625rem;
  border-radius: 0.5rem;
  background-color: transparent;

  color: #fafafc;
  font-family: Roboto;
  font-size: 0.75rem;
  font-weight: 500;
  line-height: 0.8788rem;
  letter-spacing: 0.2em;
  border: none;
  text-transform: uppercase;
  &:hover {
    background: #232325;
  }
`;

export const SortSection = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.45rem;

  .heading {
    font-family: Montserrat;
    font-size: 0.75rem;
    font-weight: 700;
    line-height: 0.9144rem;
    letter-spacing: 0.2em;
    text-align: left;
    color: #999999;
    text-transform: uppercase;
  }
  .select_box {
    background: #232325;
    width:min(12rem,95%);
    border: none;
    outline: none;
    font-family: Roboto;
    font-size: 0.75rem;
    font-weight: 500;
    line-height: 14.06px;
    letter-spacing: 0.2em;
    text-align: left;
    color: #999999;
    padding: 8px 5px 8px 10px;
    border-radius: 8px;
    text-transform: uppercase;
  }
`;
