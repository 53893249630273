/** @format */

import LiveEdithTextHighLighter from '../../../../../liveEditHighlighterstyles/liveEdithTextHighLighter.component';
import { CardContainer } from './client_review_card.style';

export default function ClientReviewCard({ data, index }) {
  return (
    <CardContainer>
      <div className="svg_wrapper">
        <svg
          width="60"
          height="50"
          viewBox="0 0 60 50"
          fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <path
            d="M27.2805 29.5455V43.1818C27.2805 45.0758 26.6177 46.6856 25.2919 48.0114C23.9661 49.3371 22.3563 50 20.4624 50H6.82599C4.93205 50 3.32221 49.3371 1.99645 48.0114C0.670691 46.6856 0.0078125 45.0758 0.0078125 43.1818V18.1818C0.0078125 15.7197 0.487216 13.37 1.44602 11.1328C2.40483 8.8956 3.70099 6.96023 5.33452 5.3267C6.96804 3.69318 8.90341 2.39702 11.1406 1.43821C13.3778 0.479403 15.7275 0 18.1896 0H20.4624C21.0779 0 21.6106 0.224905 22.0604 0.674716C22.5102 1.12453 22.7351 1.6572 22.7351 2.27273V6.81818C22.7351 7.43371 22.5102 7.96638 22.0604 8.41619C21.6106 8.866 21.0779 9.09091 20.4624 9.09091H18.1896C15.6802 9.09091 13.5376 9.97869 11.7621 11.7543C9.98651 13.5298 9.09872 15.6723 9.09872 18.1818V19.3182C9.09872 20.2652 9.43016 21.0701 10.093 21.733C10.7559 22.3958 11.5608 22.7273 12.5078 22.7273H20.4624C22.3563 22.7273 23.9661 23.3902 25.2919 24.7159C26.6177 26.0417 27.2805 27.6515 27.2805 29.5455ZM59.0987 29.5455V43.1818C59.0987 45.0758 58.4358 46.6856 57.1101 48.0114C55.7843 49.3371 54.1745 50 52.2805 50H38.6442C36.7502 50 35.1404 49.3371 33.8146 48.0114C32.4889 46.6856 31.826 45.0758 31.826 43.1818V18.1818C31.826 15.7197 32.3054 13.37 33.2642 11.1328C34.223 8.8956 35.5192 6.96023 37.1527 5.3267C38.7862 3.69318 40.7216 2.39702 42.9588 1.43821C45.196 0.479403 47.5457 0 50.0078 0H52.2805C52.8961 0 53.4287 0.224905 53.8786 0.674716C54.3284 1.12453 54.5533 1.6572 54.5533 2.27273V6.81818C54.5533 7.43371 54.3284 7.96638 53.8786 8.41619C53.4287 8.866 52.8961 9.09091 52.2805 9.09091H50.0078C47.4983 9.09091 45.3558 9.97869 43.5803 11.7543C41.8047 13.5298 40.9169 15.6723 40.9169 18.1818V19.3182C40.9169 20.2652 41.2483 21.0701 41.9112 21.733C42.5741 22.3958 43.379 22.7273 44.326 22.7273H52.2805C54.1745 22.7273 55.7843 23.3902 57.1101 24.7159C58.4358 26.0417 59.0987 27.6515 59.0987 29.5455Z"
            fill="#2C3647"
          />
        </svg>
      </div>
      <p className="review">
        <LiveEdithTextHighLighter
          section="sec5"
          type={`description-${index}`}>
          {data.description}
        </LiveEdithTextHighLighter>
      </p>
      <p className="reviewer">
        <LiveEdithTextHighLighter
          section="sec5"
          type={`text-${index}`}>
          {data.text}
        </LiveEdithTextHighLighter>
      </p>
    </CardContainer>
  );
}
