import React from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';

interface PrivateRouteProps {
  children: React.ReactNode;
}

const PrivateRoute: React.FC<PrivateRouteProps> = ({ children }) => {
  const token = Cookies.get('token');
  const navigate = useNavigate();

  if (!token) {
    window.location.href = "/register";
    return null;
  }

  return <>{children}</>;
}

export default PrivateRoute;
