/** @format */

import axios from 'axios';
import Cookies from 'js-cookie';

/**
 * Retrieves template data from the server.
 * @param {string} state - The state of the template.
 * @param {number} template - The template number.
 * @param {number} page - The page number.
 * @returns {Promise<any>} - A promise that resolves to the template data.
 * @throws {Error} - If an error occurs during the data retrieval.
 */
const GetTemplateData = async (
  state: string,
  template: number,
  page: number
): Promise<any> => {
  const token = Cookies.get('token');
  let config = {};

  if (state !== 'base') {
    config = {
      headers: {
        'x-access-token': token,
      },
    };
  }
  try {
    const fetchData = await axios.get(
      `/api/get-template?n=${template}&p=${page}`,
      config
    );
    const data = await fetchData.data;
    return data;
  } catch (e) {
    throw new Error(e.message);
  }
};

export default GetTemplateData;
