const round_off = (num) => {
     return (Math.round(num * 100) / 100).toFixed();
   };
   const getDifferenceInDays = (date1, date2) => {
     const diffInMs = Math.abs(date2 - date1);
     return (diffInMs / (1000 * 60 * 60 * 24));
   };
   const getDifferenceInHours = (date1, date2) => {
     const diffInMs = Math.abs(date2 - date1);
     return (diffInMs / (1000 * 60 * 60));
   };
   export const TimeLabel = (lastUpdated: string) => {
     const todays_day = new Date();
     const last_updated_date = new Date(lastUpdated);
     const Difference = round_off(getDifferenceInDays(last_updated_date, todays_day))
     if (
      Difference === '1' ||
      Difference === '0'
     ) {
      const Date = getDifferenceInHours(last_updated_date, todays_day)
       return (
         'Added ' +
         round_off(Date) +
         ' Hours ago'
       );
     } else {
      const Date = getDifferenceInDays(last_updated_date, todays_day)
       return (
         'Added ' +
         round_off(Date) +
         ' Days ago'
       );
     }
   };