/** @format */

import { useEffect, useState } from 'react';

import { ThemeProvider } from 'styled-components';
import AboutInfo from '../components/aboutInfo/aboutInfo.t2';
import Aboutushome from '../components/aboutushome/aboutushome.t2';
import Footer from '../components/footer/footer.t2';
import Navbar from '../components/navbar/navbar.t2';
import OurTeam from '../components/ourteam/ourteam.t2';
import { OurTeamContainer } from './about.style';

import { useLocation, useParams } from 'react-router-dom';
import { useMessageContext } from '../../../context/Message.context';
import LiveEdithTextHighLighter from '../../../liveEditHighlighterstyles/liveEdithTextHighLighter.component';
import GetTemplateData from '../../../utils/apis/GetTemplateData.api';
import SiteBuilder from '../../SiteBuilder/SiteBuilder';
import BlogSlider from '../../Template3/Components/blog_slider/blog_slider.component';
export default function Aboutus({ templateData }) {
  const [data, setData] = useState(null);
  const [temp1, setTemp1] = useState(null);
  const location = useLocation();
  const { preview } = useParams();
  const getQueryParams = () => {
    return new URLSearchParams(location.search);
  };
  const { setTheme } = useMessageContext();
  const params = getQueryParams();

  const siteBuilder = params.get('siteBuilder');

  useEffect(() => {
    if (templateData) {
      setData(templateData);
    }
  }, [templateData]);

  useEffect(() => {
    const getData = async () => {
      if (!templateData) {
        const data = await GetTemplateData(preview, 2, 2);
        if (data) {
          setData(data);
          setTheme({
            primary_color: data.styles[0].css.primary,
            secondary_color: data.styles[0].css.secondary,
            warning_message: {
              fontFamily: 'Tenor Sans',
              fontSize: '20px',
              fontWeight: '400',
              lineHeight: '23.4px',
            },
            warning_text: {
              fontFamily: 'Libre Baskerville',
              fontSize: '18px',
              fontWeight: '400',
              lineHeight: '22.32px',
              textAlign: 'center',
            },
            button: {
              fontFamily: 'Tenor Sans',
              fontSize: '18px',
              fontWeight: '400',
              lineHeight: '23.4px',
            },
          });
        }
      } else {
        setData(templateData);
      }
    };
    getData();
  }, []);
  useEffect(() => {
    const getData = async () => {
      const data = await GetTemplateData(preview, 2, 1);
      if (data) {
        setTemp1(data);
      }
    };
    getData();
  }, []);
  const scrollToDiv = () => {
    const targetDiv = document.getElementById('sec4');
    if (targetDiv) {
      window.scrollTo({
        top: targetDiv.offsetTop,
        behavior: 'smooth',
      });
    }
  };
  if (params.get('scroll') === 'true') {
    scrollToDiv();
  }

  if (!data)
    return (
      <div
        id="loadingScreen"
        className="loading-screen">
        <div className="loader"></div>
      </div>
    );

  const theme = {
    colors: {
      primary: data.styles[0].css.primary,
      text: data.styles[0].css.secondary,
      text2: '#000000',
      background: '#f8f9fa',
    },
    // add more root values as needed
  };
  // const customStyles = `
  //       @import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap')
  //   `;

  const content = (
    <>
      {/* <style>{customStyles}</style> */}
      <ThemeProvider theme={theme}>
        <Navbar data={temp1?.header} />
        {data.sec1.visible && <Aboutushome data={data.sec1} />}
        <AboutInfo data={data} />
        {data.sec4.visible && (
          <OurTeamContainer>
            <h1>
              <LiveEdithTextHighLighter
                section={'sec4'}
                type="text">
                {data?.sec4.text}
              </LiveEdithTextHighLighter>
            </h1>
            <OurTeam
              data={data.sec4.card}
              section={'sec4'}
            />
          </OurTeamContainer>
        )}
        {/* <NewProject id="sec5">
          <h1>{data.sec5.text}</h1>
          <Logos data={temp1?.sec6} />
        </NewProject> */}
        <BlogSlider />
        <Footer data={temp1} />
      </ThemeProvider>
    </>
  );

  return (
    <SiteBuilder
      {...{
        template: content,
        siteBuilder: siteBuilder,
        data,
        setData,
      }}></SiteBuilder>
  );
}
