export default function Close({ onClick }) {
    return (
        <button
            onClick={onClick}
            style={{ position: 'absolute', right: 15, top: 15 }}
            className='Button_react_Tour_close'
        >
            <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect width="30" height="30" rx="8" fill="white" />
                <path d="M21.6464 9.76355C21.8417 9.56829 21.8417 9.25171 21.6464 9.05645L20.9436 8.35355C20.7483 8.15829 20.4317 8.15829 20.2364 8.35355L15.3536 13.2364C15.1583 13.4317 14.8417 13.4317 14.6464 13.2364L9.76355 8.35355C9.56829 8.15829 9.25171 8.15829 9.05645 8.35355L8.35355 9.05645C8.15829 9.25171 8.15829 9.56829 8.35355 9.76355L13.2364 14.6464C13.4317 14.8417 13.4317 15.1583 13.2364 15.3536L8.35355 20.2364C8.15829 20.4317 8.15829 20.7483 8.35355 20.9436L9.05645 21.6464C9.25171 21.8417 9.56829 21.8417 9.76355 21.6464L14.6464 16.7636C14.8417 16.5683 15.1583 16.5683 15.3536 16.7636L20.2364 21.6464C20.4317 21.8417 20.7483 21.8417 20.9436 21.6464L21.6464 20.9436C21.8417 20.7483 21.8417 20.4317 21.6464 20.2364L16.7636 15.3536C16.5683 15.1583 16.5683 14.8417 16.7636 14.6464L21.6464 9.76355Z" fill="black" />
            </svg>
        </button>
    )
}
