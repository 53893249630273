/** @format */

import styled from 'styled-components';
import { motion } from 'framer-motion';

type ContainerType = {
  $step?: number;
};
export const Container = styled(motion.div)<ContainerType>`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  &.domain_instruction {
    height: ${(props) => (props.$step >= 1 ? 'fit-content' : '100%')};
    display: ${(props) => (props.$step >= 2 ? 'none' : 'flex')};
  }
  .Instructions {
    .buttons_container {
      display: flex;
      gap: 1rem;
      .button_redirect {
        border: none;
        outline: none;
        padding: 0.9375rem 1.25rem 0.9375rem 1.25rem;
        gap: 0.625rem;
        border-radius: 0.75rem;
        color: white;
        background-color: #1e1e1e;
        display: flex;
        align-items: center;
        gap: 1rem;
        &.active {
          outline: 1px solid #fafafc;
        }
        svg {
          path {
            stroke: white;
          }
        }
        &:hover {
          background: linear-gradient(94.98deg, #d9d9d9 0%, #ffffff 100%);
          color: #1e1e1e;
          svg {
            path {
              stroke: #1e1e1e;
            }
          }
        }
      }
    }
    .heading {
      font-family: Montserrat;
      font-size: 36px;
      font-weight: 700;
      line-height: 43.88px;
      text-align: center;
      color: #fafafc;
    }
    .instruction_heading {
      font-family: Roboto;
      font-size: 1.125rem;
      font-weight: 400;
      line-height: 1.3181rem;
      letter-spacing: 0.2em;
      text-align: center;
      color: #aaaaaa;
    }
  }
`;
