/** @format */

import React from 'react';
import { ImageHighLighter, SpinnerPlace } from './liveEdithighlighter.style';
import { useLiveEditorHighLighter } from '../context/liveEditorHighighlighter.context';

import Spinner from '../utils/spinner/spinner.component';

type LiveEditImageProps = {
    children: React.ReactNode;
    section: string;
    type: string;
};

export default function LiveEditImageHighLighter({
    children,
    section,
    type,
}: LiveEditImageProps) {
    const { state } = useLiveEditorHighLighter();
    return (
        <ImageHighLighter
            outlinecheck={state.type === type && state.section === section}
            active={state.liveEditActive}>
            {children}
            {state.loader && <SpinnerPlace><Spinner /></SpinnerPlace>}
        </ImageHighLighter>
    );
}
