import React from 'react'

import { Container, SchoolContainer, School } from './NearyBySchool.style'

export default function NearBySchool({ Data }) {
    return (
        <Container>
            <h1>Nearby Schools</h1>
            <SchoolContainer>
                {Data?.slice(0,6).map((data) => (
                    <School key={data.school_name}>
                        <h1>{data.school_name}</h1>
                        <div className="Schoolcontent">
                            <p>{data.school_address}</p>
                            {/* <p>{data.school_category}</p> */}
                        </div>
                    </School>
                ))}
            </SchoolContainer>
        </Container>
    )
}
