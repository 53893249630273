import React from 'react'

import { TextHighLighter } from './liveEdithighlighter.style'

import { useLiveEditorHighLighter } from '../context/liveEditorHighighlighter.context'

import { useParams } from 'react-router-dom'

type LiveEditProps = {
    children: React.ReactNode;
    section: string;
    type: string;
}

export default function LiveEdithTextHighLighter({ children, section, type }: LiveEditProps) {
    const { state } = useLiveEditorHighLighter()
    const { preview } = useParams()
    return (
        <TextHighLighter outlinecheck={state.type === type && state.section === section} active={state.liveEditActive}  >{children}</TextHighLighter>
    )
}

