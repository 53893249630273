import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import { useMessageContext } from "../../context/Message.context";
type formStateProps = {
  name: string;
  domain: string;
  email: string;
  phone: string;
};
export default function usePermissionSoldProperty() {
  const [user, setUser] = useState(false);
  const [open, setOpen] = useState(false);
  const { setErrorShow, setMessage, setSuccessShow } = useMessageContext();
  const [formState, setFormState] = useState<formStateProps>({
    name: "",
    domain: "",
    email: "",
    phone: "",
  });
  const hrefpath = window.location.href;

  const navigate = useNavigate();

  useEffect(() => {
    const agentUrl = hrefpath.split("/")[2];
    //const agentUrl = "pranavjadhav.agentshades.com";
    handleState("domain", agentUrl);
    const IsUserFilledTheForm = Cookies.get("user");
    if (IsUserFilledTheForm) {
      setUser(true);
    }
  }, []);

  async function handleSubmitForm(
    e: React.FormEvent<HTMLFormElement>
  ): Promise<any> {
    e.preventDefault();
    try {
      await axios.post("/api/add-agent-user", formState);
      Cookies.set("user", true, { expires: 7 });
      navigate("../soldlisting");
    } catch (err) {
      setErrorShow(true);
      setMessage(`could't add user`);
      throw new Error(err.message);
    }
  }
  const handleOpenClose = () => {
    setOpen((prev) => !prev);
  };

  function handleState(state: string, value: string): void {
    setFormState((prev) => ({ ...prev, [state]: value }));
  }

  function handleNavigation(): void {
    const IsUserFilledTheForm = Cookies.get("user");
    if (IsUserFilledTheForm !== "true") {
      setOpen(true);
    }
  }

  return {
    user,
    open,
    handleOpenClose,
    handleNavigation,
    handleSubmitForm,
    handleState,
  };
}
