/** @format */

import styled from 'styled-components';
import { motion } from 'framer-motion';



export const SearchContainer = styled(motion.div)`
  display: flex;
  justify-content: center;
  align-items: center;
  width: Fixed (244px) px;
  height: Fixed (41px) px;
  top: 1331px;
  left: 4535px;
  padding: 8px 10px 8px 10px;
  gap: 10px;
  border-radius: 0.5rem;
  opacity: 0px;
  .search_box {
    background-color: transparent;
    border: none;
    outline: none;
    color: #999999;
    font-family: Roboto;
    font-size: 0.75rem;
    font-weight: 500;
    line-height: 0.8788rem;
    letter-spacing: 0.2em;
    &:focus {
      border: none;
      outline: none;
    }
  }
  &:hover {
    background: #232325;
    /* & > button {
      background: #999999;
    } */
  }
`;
export const Button = styled.div`
  /* background: #18181a; */
  border: none;
  outline: none;
  display: flex;
  justify-content: center;
  align-items: center;
  .search {
    color: #fafafc;
    font-family: Roboto;
    font-size: 0.75rem;
    font-weight: 500;
    line-height: 0.8788rem;
    letter-spacing: 0.2em;
    margin: 0;
    background-color: transparent;
  }
  text-align: left;
  cursor: pointer;
  padding: 0.5rem 0.625rem 0.5rem 0.625rem;
  gap: 0.625rem;
  border-radius: 0.5rem;
`;
