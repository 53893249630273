/** @format */

import ImageNotFound from '../../../../../images/image-not-found-icon.svg';
import LiveEditImageHighLighter from '../../../../../liveEditHighlighterstyles/liveEditImageHighLighter.component';
import LiveEdithTextHighLighter from '../../../../../liveEditHighlighterstyles/liveEdithTextHighLighter.component';
import { OurTeamCardContainer } from './our_team_card.style';
export default function OurTeamCard({ data, index }) {
  return (
    <OurTeamCardContainer>
      <div className="card_image">
        <LiveEditImageHighLighter
          section="sec4"
          type={`image-${index}`}>
          {data.image.length > 0 ? (
            <img
              src={`/public/${data.image}`}
              alt="presentation"
            />
          ) : (
            <img
              src={ImageNotFound}
              alt="ImageNotFound"
            />
          )}
        </LiveEditImageHighLighter>
      </div>
      <p className="team_member_name">
        <LiveEdithTextHighLighter
          section="sec4"
          type={`text-${index}`}>
          {data.text}
        </LiveEdithTextHighLighter>
      </p>
      <p className="profession">
        <LiveEdithTextHighLighter
          section="sec4"
          type={`description-${index}`}>
          {data.description}
        </LiveEdithTextHighLighter>
      </p>
    </OurTeamCardContainer>
  );
}
