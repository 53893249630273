/** @format */

import { useEffect, useRef, useState } from 'react';

import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';

import axios from 'axios';
import Slider from 'react-slick';
import { Container, Nevigation, ViewAll } from './FeaturedProperties.style';

//images or svg
import ConvertToCanadianNumber from '../../../../utils/New_construction_details/ConvertNumberToDollar.util';
import PropertyCard, {
  PROPERTY_TYPE_CLASSES,
} from '../../../utilComponent/PropertyCard/PropertyCard.utilComponent';

type propertyProps = {
  unique_id: any;
  photos: any[];
  list_price: number;
  address: string;
  county: string;
  zip_code: string;
  city: string;
  realtor: string;
  bedrooms: string;
  bathrooms: string;
  sale_or_lease: string;
  area: string;
  redirect: string;
  garage_spaces: string;
};
export default function FeaturedProperties() {
  const slider = useRef<Slider>(null);
  const [property, setProperty] = useState([]);
  const Featuresettings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
    arrows: false,
    responsive: [
      {
        breakpoint: 1172,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 970,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  const formatDollars = (amount: number) => {
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
    }).format(amount);
  };
  useEffect(() => {
    const FetchData = async () => {
      try {
        const fetch = await axios.get(
          'https://valuation.santhoshmathew.com/api/v1/properties/residential?page=1&perPage=12'
        );
        const Data = await fetch.data.data;
        setProperty(
          Data.map((item: propertyProps) => ({
            id: item.unique_id,
            img: item.photos,
            price: ConvertToCanadianNumber(item.list_price),
            title: `${item.address} ${item.city} ${item.area} ${item.zip_code} ${item.county}`,
            bedrooms: item.bedrooms,
            bathrooms: item.bathrooms,
            garage: item.garage_spaces,
            sale_or_lease: item.sale_or_lease,
            redirect: '../idxlisting/idxView',
          }))
        );
      } catch (e) {
        throw new Error(e);
      }
    };
    FetchData();
  }, []);
  const handleSliderNext = () => {
    slider.current.slickNext();
  };
  const handleSliderPrev = () => {
    slider.current.slickPrev();
  };
  return (
    <Container id="Property_cards">
      <h1 className="heading">Featured Properties</h1>
      <Nevigation>
        <p
          className="navigation"
          onClick={handleSliderPrev}>
          previous
        </p>
        <div className="bar"></div>
        <p
          className="navigation"
          onClick={handleSliderNext}>
          next
        </p>
      </Nevigation>
      <Slider
        ref={slider}
        {...Featuresettings}>
        {property.map((card) => (
          <div
            className="wrapper"
            key={card.id}>
            {/* <FeatureCard>
              <div className="for_sale">
                <p>For {card.sale_or_lease}</p>
              </div>
              <div className="view_button">
                <Link to={`../idxlisting/idxView/${card.id}`}>View property</Link>
              </div>
              <div className="image_wrapper">
                {card.img ? (
                  <img
                    className="image_box"
                    src={card.img}
                    alt="PropertyImage"
                  />
                ) : (
                  <img
                    className="svg_box"
                    src={ImageNotFound}
                    alt="image_not_found"
                  />
                )}
              </div>
              <p className='property_info'>{card.title}</p>
              <p className='property_info'>{card.text}</p>
              <p className='property_info'>{`${card.bedrooms} BD | ${card.bathrooms} BA`}</p>
              <p className='property_info'>{card.price}</p>
            </FeatureCard> */}
            <PropertyCard
              propertyData={card}
              propertyType={'idx'}
              propertyCardType={PROPERTY_TYPE_CLASSES.propertycard_temp3}
              key={card.id}
            />
          </div>
        ))}
      </Slider>
      <ViewAll to={'../idxlisting'}>Browse homes</ViewAll>
    </Container>
  );
}
