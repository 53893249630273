
import { saveAs } from 'file-saver';
import * as XLSX from 'xlsx';
export default function useConvertIntoExcel(COlumnsLength) {
  const exportToExcel = (data, filename)=>{
   const worksheet = XLSX.utils.json_to_sheet(data);
    worksheet['!cols'] = COlumnsLength
   const workbook = XLSX.utils.book_new();
   XLSX.utils.book_append_sheet(workbook,worksheet,"sheet1");
   const excelBuffer = XLSX.write(workbook,{bookType:'xlsx',type:'array'});
   const blob = new Blob([excelBuffer],{type:'application/octet-stream'});
   saveAs(blob,`${filename}.xlsx`)
 }

 return{
  exportToExcel
 }
}
