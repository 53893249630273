/** @format */

import React from 'react';
import { BuyingContainer } from './BuyingDomain.style';

import Godaddy from './Images/GoDaddy.png';
import nameCheap from './Images/namecheap.png';
import hostinger from './Images/hostinger.png';

import { motion } from 'framer-motion';

export default function BuyingDomain({ step, setStep, setDomain, domain }) {
  const DomainNames = ['Godaddy', 'NameCheap', 'hostinger'];
  return (
    <BuyingContainer
      initial={{ opacity: 0, y: 100 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: -1000 }}
      transition={{ easeIn: 'easeOut', duration: 0.5 }}>
      <div className="Instructions">
        <p className="instruction_heading">Buying a Domain</p>
        <p className="heading">
          {domain === null
            ? 'Check out our Recommendations'
            : `Instructions for ${DomainNames[domain]}`}
        </p>
        <motion.div
          className="domains"
          animate={{ scale: step === 2 && 0.5, y: step === 2 && -40 }}
          transition={{ duration: 0.5 }}>
          <div
            className={`wrapper ${domain !== 0 && step === 2 && 'inactive'}`}
            onClick={() => {
              setStep(2);
              setDomain(0);
            }}>
            <img
              src={Godaddy}
              alt="godaddy"
            />
          </div>
          <div
            className={`wrapper ${domain !== 1 && step === 2 && 'inactive'}`}
            onClick={() => {
              setStep(2);
              setDomain(1);
            }}>
            <img
              src={nameCheap}
              alt="nameCheap"
            />
          </div>
          <div
            className={`wrapper ${domain !== 2 && step === 2 && 'inactive'}`}
            onClick={() => {
              setStep(2);
              setDomain(2);
            }}>
            <img
              src={hostinger}
              alt="hostinger"
            />
          </div>
        </motion.div>
      </div>
    </BuyingContainer>
  );
}
