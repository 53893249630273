/** @format */

import { useState } from 'react';
import Active from './Images/active.svg';
import './SeoListting.css';
const Activestate = () => (
  <div className="d-flex align-items-center justify-content-end">
    <img
      src={Active}
      alt="Active"
    />
    <p>Active (400)</p>
  </div>
);
const Down = () => (
  <div className="d-flex  align-items-center justify-content-end">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="19"
      viewBox="0 0 18 19"
      fill="none">
      <path
        d="M14.3242 4.17495C11.3992 1.24995 6.59922 1.24995 3.67422 4.17495C0.749219 7.09995 0.749219 11.9 3.67422 14.825C6.59922 17.75 11.3242 17.75 14.2492 14.825C17.1742 11.9 17.2492 7.09995 14.3242 4.17495ZM11.4528 12.2964C11.2575 12.4917 10.9409 12.4917 10.7457 12.2964L9.35277 10.9035C9.15751 10.7082 8.84093 10.7082 8.64567 10.9035L7.25277 12.2964C7.05751 12.4917 6.74093 12.4917 6.54567 12.2964L6.20277 11.9535C6.00751 11.7582 6.00751 11.4417 6.20277 11.2464L7.59567 9.8535C7.79093 9.65824 7.79093 9.34166 7.59567 9.1464L6.20277 7.7535C6.00751 7.55824 6.00751 7.24166 6.20277 7.0464L6.54567 6.7035C6.74093 6.50824 7.05751 6.50824 7.25277 6.7035L8.64567 8.0964C8.84093 8.29166 9.15751 8.29166 9.35277 8.0964L10.7457 6.7035C10.9409 6.50824 11.2575 6.50824 11.4528 6.7035L11.7957 7.0464C11.9909 7.24166 11.9909 7.55824 11.7957 7.7535L10.4028 9.1464C10.2075 9.34166 10.2075 9.65824 10.4028 9.8535L11.7957 11.2464C11.9909 11.4417 11.9909 11.7582 11.7957 11.9535L11.4528 12.2964Z"
        fill="#FF5B5B"
      />
    </svg>
    <p>Down (404)</p>
  </div>
);

type Activetype = {
  title: boolean;
  des: boolean;
  keyword: boolean;
};

type ValueType = {
  title: string;
  des: string;
  keyword: string;
};

export default function SeoListting({ active }) {
  const [Active, setActive] = useState<Activetype>({
    title: false,
    des: false,
    keyword: false,
  });
  const [Value, setValue] = useState<ValueType>({
    title:
      'Lorem ipsum dolor sit amet consectetur. Volutpat imperdiet vitae nullam massa. Mauris diam orci nibh faucibus est volutpat. Lorem ipsum dolor sit amet consectetur. Volutpat imperdiet vitae nullam massa. Mauris diam orci nibh',
    des: 'Lorem ipsum dolor sit amet consectetur. Volutpat imperdiet vitae nullam massa. Mauris diam orci nibh faucibus est volutpat. Lorem ipsum dolor sit amet consectetur. Volutpat imperdiet vitae nullam massa. Mauris diam orci nibh',
    keyword: `Lorem ipsum dolor sit amet consectetur. Volutpat imperdiet vitae
          nullam massa. Mauris diam orci nibh faucibus est volutpat. Lorem ipsum
          dolor sit amet consectetur. Volutpat imperdiet vitae nullam massa.
          Mauris diam orci nibh`,
  });
  return (
    <div className="seo_listting_info">
      <div className="seo_url justify-content-evenly align-items-start">
        <p>https://www.agentshades.com/homepage</p>
      </div>
      <div className="seo_title d-flex justify-content-evenly align-items-start">
        <p style={{ paddingLeft: '0px' }}>
          find your dream home from Austin Adams{' '}
        </p>
        <div
          className="wrapper"
          style={{ marginTop: '6px' }}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="18"
            height="19"
            viewBox="0 0 18 19"
            fill="none">
            <path
              d="M2.25 13.4374V16.2499H5.0625L13.3575 7.95488L10.545 5.14238L2.25 13.4374ZM15.5325 5.77988C15.602 5.71049 15.6572 5.62808 15.6948 5.53735C15.7325 5.44662 15.7518 5.34936 15.7518 5.25113C15.7518 5.1529 15.7325 5.05564 15.6948 4.96491C15.6572 4.87418 15.602 4.79177 15.5325 4.72238L13.7775 2.96738C13.7081 2.89785 13.6257 2.84269 13.535 2.80506C13.4442 2.76742 13.347 2.74805 13.2488 2.74805C13.1505 2.74805 13.0533 2.76742 12.9625 2.80506C12.8718 2.84269 12.7894 2.89785 12.72 2.96738L11.3475 4.33988L14.16 7.15238L15.5325 5.77988Z"
              fill="#474747"
            />
          </svg>
        </div>
      </div>
      <div className="seo_description  d-flex justify-content-evenly align-items-center gap-1">
        {Active.des ? (
          <textarea
            value={Value.des}
            className="input_box_seo"
            rows={4}
          />
        ) : (
          <p>
            Lorem ipsum dolor sit amet consectetur. Volutpat imperdiet vitae
            nullam massa. Mauris diam orci nibh faucibus est volutpat. Lorem
            ipsum dolor sit amet consectetur. Volutpat imperdiet vitae nullam
            massa. Mauris diam orci nibh
          </p>
        )}

        <div className="wrapper">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="18"
            height="19"
            viewBox="0 0 18 19"
            fill="none">
            <path
              d="M2.25 13.4374V16.2499H5.0625L13.3575 7.95488L10.545 5.14238L2.25 13.4374ZM15.5325 5.77988C15.602 5.71049 15.6572 5.62808 15.6948 5.53735C15.7325 5.44662 15.7518 5.34936 15.7518 5.25113C15.7518 5.1529 15.7325 5.05564 15.6948 4.96491C15.6572 4.87418 15.602 4.79177 15.5325 4.72238L13.7775 2.96738C13.7081 2.89785 13.6257 2.84269 13.535 2.80506C13.4442 2.76742 13.347 2.74805 13.2488 2.74805C13.1505 2.74805 13.0533 2.76742 12.9625 2.80506C12.8718 2.84269 12.7894 2.89785 12.72 2.96738L11.3475 4.33988L14.16 7.15238L15.5325 5.77988Z"
              fill="#474747"
            />
          </svg>
        </div>
      </div>
      <div className="seo_keywords d-flex justify-content-evenly align-items-center">
        <p>
          Lorem, ipsum, dolor, sit, amet, consectetur. Volutpat imperdiet vitae
          nullam massa. Mauris diam orci nibh faucibus est volutpat.{' '}
        </p>
        <div className="wrapper">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="18"
            height="19"
            viewBox="0 0 18 19"
            fill="none">
            <path
              d="M2.25 13.4374V16.2499H5.0625L13.3575 7.95488L10.545 5.14238L2.25 13.4374ZM15.5325 5.77988C15.602 5.71049 15.6572 5.62808 15.6948 5.53735C15.7325 5.44662 15.7518 5.34936 15.7518 5.25113C15.7518 5.1529 15.7325 5.05564 15.6948 4.96491C15.6572 4.87418 15.602 4.79177 15.5325 4.72238L13.7775 2.96738C13.7081 2.89785 13.6257 2.84269 13.535 2.80506C13.4442 2.76742 13.347 2.74805 13.2488 2.74805C13.1505 2.74805 13.0533 2.76742 12.9625 2.80506C12.8718 2.84269 12.7894 2.89785 12.72 2.96738L11.3475 4.33988L14.16 7.15238L15.5325 5.77988Z"
              fill="#474747"
            />
          </svg>
        </div>
      </div>

      {active === true ? <Activestate /> : <Down />}
    </div>
  );
}
