import styled from "styled-components";

export const PopupFormContainer = styled.div`
  position: fixed;
  z-index: 20;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0.4),
    rgba(0, 0, 0, 0.4)
  );
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
`;
type PopupFormsProps = {
    $disabledCheck: boolean;
};

export const PopupForm = styled.form<PopupFormsProps>`
  width: min(25rem, 95%);
  background-color: white;
  padding: 1rem;
  border-radius: 1rem;
  h3 {
    text-align: center;
  }
  .warning {
    text-align: center;
    color: red;
  }
  .Input {
    display: block;
    width: 100%;
    padding: 0.3rem 0.8rem;
    margin-bottom: 1rem;
    border-radius: 0.25rem;
    border: 1px solid black;
    resize: none;
  }
  .TextArea {
    height: 9rem;
  }
  .CheckBox {
    display: flex;
    align-items: baseline;
    gap: 0.8rem;
    margin-bottom: 0.8rem;
    p {
      color: ${(props) =>
        props.theme?.colors?.primary
            ? props.theme?.colors?.primary
            : 'var(--primary)'};
    }
  }
  .buttons {
    display: flex;
    gap: 1rem;
    .button {
      border: none;
      width: 100%;
      background-color: ${(props) =>
        props.theme?.colors?.primary
            ? props.theme?.colors?.primary
            : 'var(--primary)'};
      color: white;
      border-radius: 0.4rem;
      padding: 0.45rem;
      &.submit_button{
      cursor:${(props) => props.$disabledCheck ? 'not-allowed' : 'pointer'};
      }
    }
  }
`;
