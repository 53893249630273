/** @format */

import styled from 'styled-components';
import { transparentize } from 'polished';
export const Container = styled.div`
  padding: 24px;
  gap: 24px;
  border-radius: 16px;
  background: #f7f7f7;
  margin-top: 3rem;
  .heading_container {
    .heading {
      color: #13131a;
      font-family: Inter;
      font-size: 1rem;
      font-weight: 500;
      line-height: 1.21rem;
      text-align: center;
    }
    .sub_heading {
      font-family: Inter;
      font-size: 1rem;
      font-weight: 700;
      line-height: 1.21rem;
      text-align: center;
      color: #13131a;
    }
  }
  .form_container {
    .input_box {
      width: 100%;
      padding: 16px;
      gap: 10px;
      border-radius: 8px;
      border: none;
      margin-bottom: 0.85rem;
      &:hover {
        outline: 1px solid ${({ theme: { colors } }) => colors.primary};
      }
      &:focus {
        outline: 1px solid ${({ theme: { colors } }) => colors.primary};
      }
    }
    .submit_button {
      padding: 1rem 2rem 1rem 2rem;
      display: flex;
      align-items: center;
      gap: 0.625rem;
      border-radius: 0.9375rem;
      font-family: Inter;
      font-size: 1.125rem;
      font-weight: 600;
      line-height: 1.3613rem;
      text-align: center;
      border: none;
      outline: none;
      color: white;
      box-shadow: 2px 2px 4px 0px
        ${({ theme: { colors } }) => colors?.primary && transparentize(0.7, colors?.primary)};
      background-color: ${({ theme: { colors } }) => colors.primary};
      margin: 0 auto;
      margin-bottom: 1rem;
    }
  }
  .agreement {
    font-family: Inter;
    font-size: 12px;
    font-weight: 400;
    line-height: 14.52px;
    text-align: center;
    color: #6e6e73;
    width:min(19rem,95%);
    margin: 0 auto;
  }
`;
