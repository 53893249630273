/** @format */

import { HeroContainer } from './HeroSection.style';

import LiveEdithTextHighLighter from '../../../../liveEditHighlighterstyles/liveEdithTextHighLighter.component';
export default function HeroSection({ data }) {
  return (
    <HeroContainer id="sec1">
      <div className="HeroImage">
        {/* <LiveEditImageHighLighter
          section="sec1"
          type="image"> */}
        <img
          src={`/public/${data.image}`}
          alt="presentation"
        />
        {/* </LiveEditImageHighLighter> */}
      </div>
      <div className="hero_information">
        <LiveEdithTextHighLighter
          section="sec1"
          type="text1">
          <div
            dangerouslySetInnerHTML={{ __html: data.text1 }}
            className="sub_header"
            style={{
              fontFamily: 'Syncopate',
              fontSize: '20px',
              fontWeight: 350,
              lineHeight: '1.3181rem',
              textAlign: 'center',
              color: 'black',
              padding: '0 0 15px',
            }}></div>
        </LiveEdithTextHighLighter>

        <LiveEdithTextHighLighter
          section="sec1"
          type="text2">
          <div
            className="header"
            dangerouslySetInnerHTML={{ __html: data.text2 }}></div>
        </LiveEdithTextHighLighter>

        <LiveEdithTextHighLighter
          section="sec1"
          type="description">
          <div
            className="description"
            dangerouslySetInnerHTML={{ __html: data.description }}></div>
        </LiveEdithTextHighLighter>
      </div>
    </HeroContainer>
  );
}
