/** @format */

import LiveEdithTextHighLighter from '../../../../liveEditHighlighterstyles/liveEdithTextHighLighter.component';
import useAddUserForNewseletter from '../../../../utils/apis/AddUserForNewseLetter';
import { NewsletterContainer } from './newsletter.styl';
export default function NewsLetter({ data }) {
  const { handleSubscriptionForNewsletter, handleState, stateData } =
    useAddUserForNewseletter();

  return (
    <NewsletterContainer id="sec4">
      <div className="wrapper_container">
        <h1 className="heading">
          <LiveEdithTextHighLighter
            section="sec10"
            type="text">
            {data.text}
          </LiveEdithTextHighLighter>
        </h1>
        <h3 className="subHeading">
          <LiveEdithTextHighLighter
            section="sec10"
            type="description">
            {data.description}
          </LiveEdithTextHighLighter>
        </h3>
        <form
          className="email_form"
          onSubmit={(e) => handleSubscriptionForNewsletter(e)}>
          <div className="name_input_box">
            <input
              type="text"
              placeholder="name"
              className="input_box"
              value={stateData.name}
              onChange={(e) => handleState('name', e.target.value)}
              required
            />
          </div>
          <div className="email_input_box">
            <input
              type="email"
              placeholder="email"
              className="input_box"
              value={stateData.newsletteremail}
              onChange={(e) => handleState('newsletteremail', e.target.value)}
            />
            <button className="submitForm">Subscribe</button>
          </div>
        </form>
        <p className="terms_condition">
          <LiveEdithTextHighLighter
            section="sec10"
            type="description2">
            {data.description2}
          </LiveEdithTextHighLighter>
        </p>
      </div>
    </NewsletterContainer>
  );
}
